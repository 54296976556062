import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { colorsTheme } from 'resources/theme/styled/colors';
import { generateUniqId } from 'helpers/commonHelpers';
import { DEFAULT_TABLE_LIMIT } from 'constants/global';
import {
  SorterResult,
  ExpandableConfig,
  TablePaginationConfig,
  TableCurrentDataSource,
} from 'antd/lib/table/interface';
import { Text, IconSVG, Row, Col } from '@ui';
import ExpandableWrapper from 'components/WrapperComponents/ExpandableWrapper';
import { ReactComponent as DownArrowIcon } from 'resources/icons/remix-icons/arrow-down-s-line.svg';
import { TableColumnModel, TablePropsModel } from './types';
import { Empty, Pagination, Spin, Table as AntTable } from 'antd';

interface CardRowProps {
  key: string;
  title: React.ReactNode;
  value: any;
}

const CardValueRow = ({ key, title, value }: CardRowProps) => (
  <StyledRow key={key}>
    <Col span={9}>{title && <Text variant="body1">{`${title}:`}</Text>}</Col>
    <StyledValueCol span={15}>
      <Text variant="body1">{value}</Text>
    </StyledValueCol>
  </StyledRow>
);

/**
 * The `Table` component is a responsive table that displays data in both standard (desktop) and card-based (mobile) views.
 * It supports:
 * - Pagination, sorting, and filtering.
 * - An expandable mode to show additional content for each row.
 * - A mobile card mode, displaying data in a more compact, vertical layout for smaller screens.
 *
 *
 * **Key Features:**
 * 1. **Expandable Rows:** Set `expandable` prop to configure.
 * 2. **Mobile Mode:** By default, the table switches to card view on small screens. Set `disableMobileMode` to true to prevent this.
 * 3. **Custom Columns:** Define columns with various properties, including `mobileCardProps` for customizing mobile layout, `render` for custom cell rendering, and `sorter` or `filters` for sorting and filtering.
 * 4. **Actions Column:** Mark a column as `isActionsColumn` in `mobileCardProps` to display action elements (e.g., a three-dot menu) in mobile mode.
 * 5. **Title Column:** Mark a column as `isCardTitle` in `mobileCardProps` to use that column’s data as the card’s title in mobile mode.
 *
 * @param {TablePropsModel} props - The properties defined in `TablePropsModel`.
 */
const Table = ({
  data,
  expandable,
  total = data.length,
  current = 1,
  columns,
  loading,
  onSortChange,
  onFilterChange,
  onPaginationChange,
  disableMobileMode,
  showSizeChanger = false,
  hidePagination = false,
  disableHorizontalScroll,
  ...rest
}: TablePropsModel) => {
  const tableId = useMemo(() => generateUniqId(), []);
  const breakpoint = useBreakpoint();
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);

  const isMobileMode = () => !breakpoint.md && !disableMobileMode;

  const tableColumns = useMemo(() => {
    return columns.filter((e) => !e.hidden);
  }, [columns]);

  const expandableConfig: ExpandableConfig<any> | undefined = useMemo(() => {
    if (!expandable) {
      return undefined;
    }

    const result: ExpandableConfig<any> = {
      ...expandable,
      expandedRowKeys: expandedRowKeys,
      onExpandedRowsChange: (keys) => setExpandedRowKeys([...keys] as string[]),
      expandIcon: ({ expanded, onExpand, record, expandable }) =>
        expandable ? (
          <StyledExpandIconContainer>
            <ExpandIconWrapper onClick={(e) => onExpand(record, e)}>
              <StyledIconSVG
                component={DownArrowIcon}
                color={colorsTheme.colorWhite}
                isOpened={expanded}
              />
            </ExpandIconWrapper>
          </StyledExpandIconContainer>
        ) : (
          <NonExpandedWrapper />
        ),
    };

    return result;
  }, [expandable, expandedRowKeys]);

  const mapTableRows = useCallback((rows: any[], tableName?: string) => {
    return rows.map((row: any, i: number) => ({
      key: `${tableName}-row-${i}`,
      ...row,
    }));
  }, []);

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, (boolean | React.Key)[] | null>,
    sorter: SorterResult<any> | SorterResult<unknown>[],
    extra: TableCurrentDataSource<unknown>,
  ) => {
    switch (extra.action) {
      case 'paginate':
        {
          if (pagination.current !== current && onPaginationChange) {
            onPaginationChange(pagination.current || 1);
          }
        }
        break;

      case 'sort':
        {
          if (onSortChange) {
            onSortChange(sorter);
            if (current > 1) {
              onPaginationChange && onPaginationChange(1);
            }
          }
        }
        break;

      case 'filter': {
        if (onFilterChange) {
          onFilterChange(filters);
          if (current > 1) {
            onPaginationChange && onPaginationChange(1);
          }
        }
      }
    }
  };

  const renderTable = () => (
    <AntTable
      {...rest}
      loading={loading}
      expandable={expandableConfig}
      columns={tableColumns}
      dataSource={mapTableRows(data, tableId)}
      size="large"
      scroll={!disableHorizontalScroll ? { x: 'max-content' } : undefined}
      onChange={handleTableChange}
      pagination={
        !hidePagination && {
          total,
          current,
          pageSize: DEFAULT_TABLE_LIMIT,
          hideOnSinglePage: true,
          showSizeChanger,
        }
      }
    />
  );

  // render mobile table (cards)
  const renderCards = () => {
    const { mainValues, additionalValues, titleColumn, actionsColumn } =
      getCardValues(tableColumns);

    const shouldRenderShowMore = !!additionalValues.length;
    const shouldRenderEmpty = !loading && !data.length;

    return (
      <StyledSpin spinning={loading} size="large">
        {data.map((dataEl: any, index) => (
          <Card key={index}>
            {titleColumn && (
              <CardHeader>
                <Text variant="body1">{dataEl[titleColumn]}</Text>
              </CardHeader>
            )}

            <CardContent>
              {actionsColumn ? (
                <CardValueRow
                  key="actions"
                  title=""
                  value={actionsColumn.render(dataEl)}
                />
              ) : null}

              {renderCardValue(mainValues, dataEl)}

              {shouldRenderShowMore && (
                <ExpandableWrapper showTriggerAfter>
                  {renderCardValue(additionalValues, dataEl)}
                </ExpandableWrapper>
              )}
            </CardContent>
          </Card>
        ))}
        {shouldRenderEmpty && (
          <StyledEmpty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
        {renderPaginationBar()}
      </StyledSpin>
    );
  };

  // render card values by table columns
  const renderCardValue = (columns: TableColumnModel[], dataItem: any) =>
    columns.map(({ title, render, dataIndex }, index: number) => {
      // handle render
      const rowValue = render
        ? render(dataIndex ? dataItem[dataIndex] : dataItem, dataItem, index)
        : dataIndex && dataItem[dataIndex];

      return (
        <CardValueRow
          key={`${title}-${index}`}
          title={title || ''}
          value={rowValue}
        />
      );
    });

  // generate card values
  // return:
  // - main values: values which should be shown in main container (array of columns)
  // - additional values: values which should be shown in show more container (array of columns)
  // - title column: card title value (string)
  // - actions column: three dots column (1 column)
  const getCardValues = (columnsArray: TableColumnModel[]) => {
    const mainValues: TableColumnModel[] = [];
    const additionalValues: TableColumnModel[] = [];

    let titleColumn: string | null = null;
    let actionsColumn: TableColumnModel | null = null;

    columnsArray.forEach((e) => {
      if (e.mobileCardProps?.isCardTitle) {
        titleColumn = e.dataIndex || '';
      }

      if (e.mobileCardProps?.isActionsColumn) {
        actionsColumn = { ...e };
        return;
      }

      if (e.mobileCardProps?.hide) return;

      if (e.mobileCardProps?.hideFromMainSection) {
        additionalValues.push(e);
      } else {
        mainValues.push(e);
      }
    });

    return { mainValues, additionalValues, titleColumn, actionsColumn } as {
      mainValues: TableColumnModel[];
      additionalValues: TableColumnModel[];
      titleColumn: string | null;
      actionsColumn: TableColumnModel | null;
    };
  };

  const handlePaginationChange = (newPage: number) =>
    onPaginationChange && onPaginationChange(newPage);

  const renderPaginationBar = () => (
    <EndAlignWrapper>
      <Pagination
        current={current}
        total={total}
        hideOnSinglePage
        onChange={handlePaginationChange}
      />
    </EndAlignWrapper>
  );

  return <>{isMobileMode() ? renderCards() : renderTable()}</>;
};

const Card = styled.div`
  border: ${({ theme }) => `2px solid ${theme.colorDarkL4}`};
  border-radius: 8px;
  min-height: 100px;
  height: auto;
  margin-bottom: ${({ theme }) => theme.marginSm};
  box-shadow: ${({ theme }) => theme.tableCardShadow};
  overflow: hidden;
  transition: max-height 0.5s ease-out;
`;

const CardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 5px;
  background-color: ${({ theme }) => theme.colorDarkD1};
  font-size: 15px;
`;

const CardContent = styled.div`
  position: relative;
  padding: ${({ theme }) => theme.paddingMd};
  background: ${({ theme }) => theme.tableCardBackground};

  .ant-row:last-child {
    margin-bottom: 0px;
  }
`;

const StyledRow = styled(Row)`
  margin-bottom: ${({ theme }) => theme.marginXs};
`;

const StyledExpandIconContainer = styled.div`
  display: inline-block;
`;

const StyledValueCol = styled(Col)`
  display: flex;
  justify-content: flex-end;

  overflow: hidden;

  .ant-typography {
    text-align: end;
  }
`;

const StyledSpin = styled(Spin)<any>`
  min-height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const EndAlignWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const StyledEmpty = styled(Empty)`
  color: ${({ theme }) => theme.colorWhite};
`;

const ExpandIconWrapper = styled.div`
  width: 26px;
  height: 26px;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  border-radius: 50%;
  transition: all 0.2s ease;
  background-color: ${({ theme }) => theme.backgroundColor3};

  &:hover {
    background-color: ${({ theme }) => theme.textBackgroundColorActive};
    box-shadow: ${({ theme }) => theme.colorWhite};
  }
`;

const StyledIconSVG = styled(IconSVG)<{ isOpened: boolean }>`
  cursor: pointer;
  transition: all 0.5s ease;
  transform: ${({ isOpened }) =>
    isOpened ? 'rotateX(180deg)' : 'rotateX(0deg)'};
`;

const NonExpandedWrapper = styled.div`
  width: 26px;
  height: 26px;
  display: inline-block;
  margin-right: ${({ theme }) => theme.marginXs};
`;

export default Table;
