import React, { useEffect, useMemo, useRef, useState } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { transferTemplatesAPI } from 'api/accounting/transferTemplatesAPI';
import { TransferTemplateTypes } from 'enums/accounting/transfers';
import { TransferTemplateStatuses } from 'enums/accounting/templates';
import { newTransferTemplateSchema } from 'validations/accounting/transfers';
import { INewTransferTemplateFormValues } from 'typings/accounting/transfer';

// components
import {
  Button,
  Form,
  RequiredPropsForFormModel,
  ModalDialog,
  RequiredPropsForModalDialogModel,
  Row,
  Col,
  Message,
  Modal,
} from '@ui';
import InnerForm from './InnerForm';
import WarningIconWithText from '../../../../Additional/WarningIconWithText';
import ConfirmActionModalDialog from 'components/ModalDialogs/ConfirmActionModalDialog';

interface IProps
  extends RequiredPropsForModalDialogModel,
    RequiredPropsForFormModel<INewTransferTemplateFormValues> {
  title: string;
  isEditMode?: boolean;
  disabledTemplateTypeField?: boolean;
  onDuplicateClick?: () => void;
}

type ConfirmationFormValesModel = {
  reason: string;
};

const TransferTemplateModal = ({
  isVisible,
  title,
  closeCallback,
  disabled,
  onSubmit,
  initialValues,
  isEditMode,
  disabledTemplateTypeField,
  onDuplicateClick,
  onSubmitError,
}: IProps) => {
  const formRef = useRef<any>();
  const { t } = useTranslation(['transfers', 'common']);
  const [isEditActive, setIsEditActive] = useState(false);
  const [templateId, setTemplateId] = useState<number | null>(null);

  useEffect(() => {
    if (!isVisible && isEditActive) {
      setIsEditActive(false);
    }
  }, [isVisible]);

  const handleBackToReadOnlyClick = () => {
    if (formRef.current.dirty) {
      formRef.current.resetForm(initialValues);
    }

    setIsEditActive(false);
  };

  const renderExtendedButton = () => {
    if (!isEditMode) {
      return;
    }

    return initialValues ? (
      <>
        {isEditActive ? (
          <Button
            size="large"
            type="bordered"
            onClick={handleBackToReadOnlyClick}
          >
            {t('back_to_read_only_button', { ns: 'common' })}
          </Button>
        ) : (
          <Row gutter={[16, 16]}>
            {initialValues?.status === TransferTemplateStatuses.Active &&
              initialValues.templateType === TransferTemplateTypes.Wire && (
                <Col>
                  <Button
                    size="large"
                    type="bordered"
                    onClick={() => onArchiveClick(initialValues.id as number)}
                  >
                    {t(
                      'transfer_templates.transfer_template_modal.archiving.archive_button',
                    )}
                  </Button>
                </Col>
              )}
            {initialValues?.status === TransferTemplateStatuses.Archived &&
              initialValues.templateType === TransferTemplateTypes.Wire && (
                <Col>
                  <Button
                    size="large"
                    type="bordered"
                    onClick={() => onActivateClick(initialValues.id as number)}
                  >
                    {t(
                      'transfer_templates.transfer_template_modal.activation.activate_button',
                    )}
                  </Button>
                </Col>
              )}
            {onDuplicateClick && (
              <Col>
                <Button size="large" type="bordered" onClick={onDuplicateClick}>
                  {t(
                    'transfer_templates.transfer_template_modal.duplicate.duplicate_button',
                  )}
                </Button>
              </Col>
            )}
            <Col>
              <Button
                size="large"
                disabled={disabled}
                onClick={() => setIsEditActive(true)}
              >
                {t('edit_button', { ns: 'common' })}
              </Button>
            </Col>
          </Row>
        )}
      </>
    ) : null;
  };

  const modalTitle = useMemo(() => {
    if (disabled) {
      return (
        <TitleContainer>
          <StyledTitle>{title}</StyledTitle>
          <StyledWarningInfoNot2FA
            text={t(
              'transfer_templates.transfer_template_modal.warning_not_2fa',
            )}
          />
        </TitleContainer>
      );
    }

    return title;
  }, [disabled, title]);

  const confirmationInitialValues = useMemo(() => {
    return {
      reason: '',
    };
  }, []);

  const onArchiveClick = (templateId: number) => {
    setTemplateId(templateId);
  };

  const onActivateClick = async (templateId: number) => {
    Modal.confirm({
      title: t(
        'transfer_templates.transfer_template_modal.activation.modal_title',
      ),
      width: 600,
      content: t(
        'transfer_templates.transfer_template_modal.activation.modal_description',
      ),
      okText: t('confirm', { ns: 'common' }),
      cancelText: t('cancel', { ns: 'common' }),
      onOk: async () => {
        await transferTemplatesAPI.activateTransferTemplate(templateId);
        Message.success(
          t(
            'transfer_templates.transfer_template_modal.activation.submit_success_message',
          ),
        );
        closeCallback(true);
      },
    });
  };

  const handleSubmit = async (values: ConfirmationFormValesModel) => {
    await transferTemplatesAPI.archiveTransferTemplate(templateId as number, {
      invalidityReason: values.reason,
    });
    Message.success(
      t(
        'transfer_templates.transfer_template_modal.archiving.submit_success_message',
      ),
    );
    setTemplateId(null);
    closeCallback(true);
  };

  return (
    <Form
      enableReinitialize
      innerRef={formRef}
      onSubmit={onSubmit}
      disabled={disabled}
      initialValues={isVisible ? initialValues : null}
      validationSchema={newTransferTemplateSchema}
      onSubmitError={onSubmitError}
      renderForm={
        <ModalDialog
          title={modalTitle}
          isVisible={isVisible}
          closeCallback={closeCallback}
          extendedFooterButtonsPosition="after"
          extendedFooterButtons={renderExtendedButton()}
          submitButtonProps={{ hidden: isEditMode && !isEditActive }}
          closeModalAfterSubmit={!onSubmitError}
        >
          <InnerForm
            disabledTemplateTypeField={disabledTemplateTypeField}
            isEditMode={isEditMode}
            isViewOnly={isEditMode && !isEditActive}
          />
          <ConfirmActionModalDialog
            title={t(
              'transfer_templates.transfer_template_modal.archiving.reason_modal_title',
            )}
            description={t(
              'transfer_templates.transfer_template_modal.archiving.reason_modal_description',
            )}
            submitButtonText={t('confirm', { ns: 'common' })}
            onSubmit={handleSubmit}
            isVisible={!!templateId}
            initialValues={confirmationInitialValues}
            closeCallback={() => setTemplateId(null)}
          />
        </ModalDialog>
      }
    />
  );
};

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledTitle = styled.div`
  margin-right: ${({ theme }) => theme.marginXs};
`;

const StyledWarningInfoNot2FA = styled(WarningIconWithText)`
  margin-left: ${({ theme }) => theme.marginXs};
`;

export default TransferTemplateModal;
