import React, { useCallback, useRef, useState, useEffect } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { StateModel } from 'redux/reducers';
import { UploadStatus } from 'constants/documents';
import { generateUniqId } from 'helpers/commonHelpers';
import { getFileFromDataURL } from 'helpers/documentsHelpers';
import { useDispatch, useSelector } from 'react-redux';
import { IDocumentAssociationInfo } from 'typings/documents/documents';
import { StateModel as UploadStateModel } from 'redux/reducers/upload';
import {
  IFileItem,
  refreshUploadState,
  uploadFiles,
} from 'redux/actions/upload';

// components
import Webcam from 'react-webcam';
import { Button, Col, Row, Spin } from '@ui';

const CameraOptions: MediaTrackConstraints = {
  width: 1280,
  height: 720,
  facingMode: 'user',
};

interface IProps {
  documentRelatedTo?: string;
  association?: IDocumentAssociationInfo;
  onUpload: (file: IFileItem) => void;
  onCameraClear: () => void;
}

enum CameraStatuses {
  Success,
  Pending,
  Error,
}

const Camera = ({
  documentRelatedTo,
  association,
  onUpload,
  onCameraClear,
}: IProps) => {
  const { t } = useTranslation(['auth', 'common']);
  const dispatch = useDispatch();
  const uploadState = useSelector<StateModel, UploadStateModel>(
    (state) => state.upload,
  );
  const [cameraStatus, setCameraStatus] = useState<CameraStatuses>(
    CameraStatuses.Pending,
  );

  const webcamRef = useRef<any>(null);
  const [isReady, setIsReady] = useState(false);
  const [tempSnapshot, setTempSnapshot] = useState<string | null>(null);

  useEffect(() => {
    const { isFinished, files } = uploadState;

    if (isFinished) {
      const uploadedDocument = Object.values(files).find(
        (file) => file.relatedToFieldName === documentRelatedTo,
      );

      if (uploadedDocument) {
        onUpload(uploadedDocument);
      }

      dispatch(refreshUploadState());
    }
  }, [uploadState.isFinished]);

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setTempSnapshot(imageSrc || null);
  }, [webcamRef]);

  const handleSaveScreenshot = (tempFile: string | null) => {
    if (!tempFile) {
      return;
    }

    const generatedFile = getFileFromDataURL(tempFile, 'photo');

    const formattedField = {
      id: generateUniqId(),
      name: generatedFile.name,
      originalName: generatedFile.name,
      file: generatedFile,
      tags: [],
    };

    dispatch(uploadFiles([formattedField], association, documentRelatedTo));
  };

  const handleRetakeClick = () => {
    setTempSnapshot(null);
    onCameraClear();
  };

  const renderActionButtons = (isUploading: boolean) => {
    let result = null;

    if (!isReady) {
      result = (
        <StyledButton onClick={() => setIsReady(true)}>
          {t('account_recovery.id_verification_step.ready_button')}
        </StyledButton>
      );
    } else if (!tempSnapshot) {
      result = (
        <StyledButton type="primary" onClick={capture}>
          {t('account_recovery.id_verification_step.take_photo_button')}
        </StyledButton>
      );
    } else {
      result = (
        <Row justify="center" gutter={[20, 0]}>
          <Col>
            <StyledButton
              disabled={isUploading}
              type="primary"
              onClick={handleRetakeClick}
            >
              {t('account_recovery.id_verification_step.retake_photo_button')}
            </StyledButton>
          </Col>

          <Col>
            <StyledButton
              type="primary"
              loading={isUploading}
              onClick={() => handleSaveScreenshot(tempSnapshot)}
            >
              {t('save', { ns: 'common' })}
            </StyledButton>
          </Col>
        </Row>
      );
    }

    return result;
  };

  const handleUserMedia = (event: MediaStream) => {
    if (event) {
      setCameraStatus(CameraStatuses.Success);
    }
  };

  const handleUserMediaError = () => {
    setCameraStatus(CameraStatuses.Error);
  };

  return (
    <Spin
      spinning={
        cameraStatus === CameraStatuses.Pending ||
        cameraStatus === CameraStatuses.Error
      }
    >
      <RootWrapper>
        <Webcam
          onUserMedia={handleUserMedia}
          onUserMediaError={handleUserMediaError}
          audio={false}
          width="100%"
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          videoConstraints={CameraOptions}
        />

        <AdditionalOverlay
          blur={!isReady}
          backgroundImage={tempSnapshot || undefined}
        >
          <div>
            {renderActionButtons(uploadState.status === UploadStatus.ACTIVE)}
          </div>
        </AdditionalOverlay>
      </RootWrapper>
    </Spin>
  );
};

const RootWrapper = styled.div`
  position: relative;
  height: 100%;
  min-height: 440px;
  padding: 60px 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  overflow: hidden;
  background-color: ${({ theme }) => theme.colorBlack};
`;

const AdditionalOverlay = styled.div<{ blur: boolean; backgroundImage?: any }>`
  position: absolute;
  top: 0px;
  left: 0px;

  padding: ${({ theme }) => theme.paddingMd};

  ${({ backgroundImage }) =>
    backgroundImage &&
    `
      background: url(${backgroundImage});
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      backdrop-filter: brightness(0.001);
  `}

  backdrop-filter: ${({ blur }) => blur && 'blur(8px)'};
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
`;

const StyledButton = styled(Button)`
  font-size: ${({ theme }) => theme.fontSizeXs};
  text-transform: none;
`;

export default Camera;
