import { CANADA_DISTRICTS, UNITED_STATES_DISTRICTS } from 'constants/districts';

export function convertAmountFromBigIntToInt(amount: string | number): number {
  return +amount / 10000;
}

export function convertAmountFromIntToBigInt(amount: number): number {
  return Math.round(amount * 10000);
}

export function formatAmountToLocaleString(
  amount: number,
  minDigits = 2,
  maxDigits = 2,
) {
  if (typeof amount === 'undefined') {
    return '';
  }

  return amount.toLocaleString(undefined, {
    minimumFractionDigits: minDigits,
    maximumFractionDigits: maxDigits,
  });
}

export function convertAmountBigIntToLocaleString(
  amount: string | number,
  minDigits = 2,
  maxDigits = 2,
): string {
  const amountInt = convertAmountFromBigIntToInt(amount);
  return formatAmountToLocaleString(amountInt, minDigits, maxDigits);
}

export function generateAccountFullName(
  accountName: string,
  accountNumber: string,
  currencyName: string,
  balanceBigInt?: number,
) {
  const formattedBalance = balanceBigInt
    ? convertAmountBigIntToLocaleString(balanceBigInt)
    : '';

  return `${accountNumber} - ${accountName} ${currencyName} ${formattedBalance}`;
}

export function roundNumber(
  number: number,
  precision: number,
  isDown?: boolean,
) {
  const factor = Math.pow(10, precision);

  const tempNumber = number * factor;
  let roundedTempNumber = 0;

  if (isDown) {
    roundedTempNumber = Math.round(-tempNumber) * -1;
  } else {
    roundedTempNumber = Math.round(tempNumber);
  }

  return roundedTempNumber / factor;
}

export function getTransferTemplateName(
  templateId: string,
  templateName: string,
) {
  return `${templateId} - ${templateName}`;
}

export function getDistrict(country: string, district: string) {
  switch (country) {
    case 'US':
      return UNITED_STATES_DISTRICTS[district];
    case 'CA':
      return CANADA_DISTRICTS[district];
  }

  return district;
}
