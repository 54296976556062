import { ActionType } from '../actions/auth';
import { IReduxAction } from 'typings/common';
import { getAccessToken } from 'helpers/localStorageHelpers';
import { UserProfileModel } from 'typings/profile/profile';
import { ContactShortModel } from 'typings/application/contact';
import { TwoFactorVerificationTypes } from 'enums/profile/auth';

export type ExternalServiceStatuses =
  | 'connected'
  | 'disconnected'
  | 'pending_synchronization';

export interface TwoFactorVerificationDataModel {
  type: TwoFactorVerificationTypes;
  options: TwoFactorVerificationOptionModel[];
}

export interface TwoFactorVerificationOptionModel {
  code: TwoFactorVerificationTypes;
  default: boolean;
  enabled: boolean;
}

export interface VerificationStatusesModel {
  isPhoneVerified: boolean;
  isEmailVerified: boolean;
}
export interface StateModel {
  isLoading: boolean;
  isAuthorized: boolean;
  temporaryToken: string | null;

  isTwoFactorVerificationEnabled: boolean;
  verificationStatuses: VerificationStatusesModel;

  externalServices: {
    circle: ExternalServiceStatuses;
  };

  profileData: UserProfileModel | null;
  contactData: ContactShortModel | null;
  invitationCode: string | null;
  twoFactorVerificationData: TwoFactorVerificationDataModel | null;
}

const initialState: StateModel = {
  isLoading: !!getAccessToken(),
  isAuthorized: false,
  temporaryToken: '',
  verificationStatuses: {
    isPhoneVerified: false,
    isEmailVerified: false,
  },
  externalServices: {
    circle: 'disconnected',
  },
  isTwoFactorVerificationEnabled: false,
  profileData: null,
  contactData: null,
  invitationCode: null,
  twoFactorVerificationData: null,
};

const auth = (state = initialState, action: IReduxAction) => {
  const { type, payload, key } = action;

  switch (type) {
    case ActionType.SET_AUTH_STATUS:
      return {
        ...state,
        [key]: payload,
      };

    case ActionType.PROFILE_GET_START:
      return {
        ...state,
        isLoading: true,
      };

    case ActionType.PROFILE_GET_SUCCESS:
      return {
        ...state,
        isAuthorized: true,
        profileData: payload.user,
        contactData: payload.contact,
        isLoading: false,
      };

    case ActionType.PROFILE_GET_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case ActionType.SET_AUTHENTICATOR_ENABLED_STATUS: {
      return {
        ...state,
        profileData: {
          ...state.profileData,
          isTwoFactorAuthenticationEnabled: payload.isEnabled,
        },
        isTwoFactorVerificationEnabled:
          !payload.isEnabled && !state.profileData?.isTwoFactorFidoEnabled
            ? false
            : true,
      };
    }

    case ActionType.SET_FIDO_AUTHENTICATOR_ENABLED_STATUS: {
      return {
        ...state,
        profileData: {
          ...state.profileData,
          isTwoFactorFidoEnabled: payload.isEnabled,
        },
        isTwoFactorVerificationEnabled:
          !payload.isEnabled &&
          !state.profileData?.isTwoFactorAuthenticationEnabled
            ? false
            : true,
      };
    }

    case ActionType.SET_DEFAULT_AUTHENTICATOR: {
      return {
        ...state,
        profileData: {
          ...state.profileData,
          default2FAType: payload.authenticatorType,
        },
      };
    }

    case ActionType.SET_SESSION_DURATION:
      return {
        ...state,
        profileData: {
          ...state.profileData,
          sessionDuration: payload.seconds,
        },
      };

    case ActionType.SET_TWO_FACTOR_AUTHENTICATION_DATA:
      return {
        ...state,
        twoFactorVerificationData: payload.data,
      };

    case ActionType.SET_FIRST_TIME_LOGGED_IN_STATUS:
      return {
        ...state,
        profileData: {
          ...state.profileData,
          isUserLoggedInFirstTime: payload.status,
        },
      };

    case ActionType.SET_CIRCLE_STATUS:
      return {
        ...state,
        externalServices: {
          ...state.externalServices,
          circle: payload.status,
        },
      };

    case ActionType.SET_EMAIL_CONFIRMATION:
      return {
        ...state,
        profileData: {
          ...state.profileData,
          emailConfirmed: payload.isEmailConfirmed,
        },
      };

    case ActionType.SET_IS_EMAIL_VERIFIED_STATUS:
      return {
        ...state,
        verificationStatuses: {
          ...state.verificationStatuses,
          isEmailVerified: payload.status,
        },
      };

    case ActionType.SET_IS_PHONE_VERIFIED_STATUS:
      return {
        ...state,
        verificationStatuses: {
          ...state.verificationStatuses,
          isPhoneVerified: payload.status,
        },
      };

    case ActionType.SET_FIRST_AND_LAST_NAMES:
      return {
        ...state,
        contactData: {
          ...state.contactData,
          firstName: payload.firstName || state.contactData?.firstName,
          lastName: payload.lastName || state.contactData?.lastName,
        },
      };

    case ActionType.SET_PRIMARY_PHONE_NUMBER:
      return {
        ...state,
        contactData: {
          ...state.contactData,
          phoneNumber: {
            ...state.contactData?.phoneNumber,
            number: payload.phoneNumber,
            verification:
              payload.verification ||
              state.contactData?.phoneNumber?.verification,
          },
        },
      };

    case ActionType.SET_PHONE_NUMBER_START_VERIFICATION_DATE:
      return {
        ...state,
        contactData: {
          ...state.contactData,
          phoneNumber: {
            ...state.contactData?.phoneNumber,
            verification: {
              ...state.contactData?.phoneNumber.verification,
              verificationStartOn: payload.verificationStartOn,
            },
          },
        },
      };

    case ActionType.SET_PRIMARY_EMAIL:
      return {
        ...state,
        contactData: {
          ...state.contactData,
          email: {
            ...state.contactData?.email,
            address: payload.email,
          },
        },
        profileData: {
          ...state.profileData,
          email: payload.email,
          emailConfirmed: true,
        },
      };

    case ActionType.SET_TWO_FACTOR_VERIFICATION_ENABLED: {
      return {
        ...state,
        isTwoFactorVerificationEnabled: payload.isTwoFactorVerificationEnabled,
      };
    }

    case ActionType.SET_INVITATION_CODE: {
      return {
        ...state,
        invitationCode: payload.invitationCode,
      };
    }

    case ActionType.SET_TEMPORARY_TOKEN: {
      return {
        ...state,
        temporaryToken: payload.temporaryToken,
      };
    }

    case ActionType.SET_USER_TAGS: {
      return {
        ...state,
        profileData: { ...state.profileData, tags: payload.tags },
      };
    }

    case ActionType.CLEAN_UP:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default auth;
