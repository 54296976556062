import React, { useEffect, useState } from 'react';

// helpers
import { userAPI } from 'api/profile/userAPI';
import { isSafari } from 'helpers/browserHelpers';
import { StateModel } from 'redux/reducers';
import { initializeApp } from 'redux/actions/app';
import { setTemporaryToken } from 'redux/actions/auth';
import {
  setTimeOffset,
  setAuthenticationTokens,
} from 'helpers/localStorageHelpers';
import { useDispatch, useSelector } from 'react-redux';
import { StateModel as AuthStateModel } from 'redux/reducers/auth';
import {
  AssertionForLoginModel,
  ChallengeForLoginModel,
} from 'typings/profile/fido';
import {
  preformatChallengeForLogin,
  publicKeyCredentialToJSON,
} from 'helpers/fidoHelpers';

// components
import LoadingWrapper from 'components/WrapperComponents/LoadingWrapper';
import ErrorWithRetryButton from './ErrorWithRetryButton';
import ContentWithUseKeyButton from './ContentWithUseKeyButton';

interface IProps {
  loadingChallenge: boolean;
  challenge?: ChallengeForLoginModel;
}

const Content = ({ challenge, loadingChallenge }: IProps) => {
  const dispatch = useDispatch();
  const isSafariBrowser = isSafari();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const { temporaryToken } = useSelector<StateModel, AuthStateModel>(
    (state) => state.auth,
  );

  useEffect(() => {
    // Safari browser is not allowing WebAuthn usage without user interaction
    // To use WebAuthn user will have to click on special button (by that trigger onClick event where we can run ceremony)
    if (challenge && temporaryToken && !isSafariBrowser) {
      runFidoCeremony();
    }
  }, [challenge, temporaryToken, isSafariBrowser]);

  async function runFidoCeremony() {
    if (challenge) {
      setIsError(false);
      setIsLoading(true);
      const publicKey = preformatChallengeForLogin(challenge);

      await navigator.credentials
        .get({ publicKey })
        .then(async (assertion) => {
          if (assertion) {
            const formattedAssertion = publicKeyCredentialToJSON(
              assertion,
            ) as AssertionForLoginModel;
            const { jwt, refreshToken, serverTime } =
              await userAPI.loginWithFido(
                formattedAssertion,
                temporaryToken as string,
              );
            if (serverTime) {
              setTimeOffset(serverTime);
            }
            setAuthenticationTokens(jwt, refreshToken);
            dispatch(setTemporaryToken(null));
            dispatch(initializeApp());
          }
        })
        .catch(() => {
          setIsError(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }

  return (
    <LoadingWrapper loading={isLoading || loadingChallenge}>
      {isSafariBrowser && !isError ? (
        <ContentWithUseKeyButton onTryClick={runFidoCeremony} />
      ) : (
        <ErrorWithRetryButton onRetryClick={runFidoCeremony} />
      )}
    </LoadingWrapper>
  );
};

export default Content;
