import { LayoutVariants } from 'redux/reducers/settings';
import { PERMISSION_KEYS } from 'config/permissions';
import { Route, RoutePaths } from 'routes/routes';
import { UserPermissionModel } from 'redux/reducers/userAccess';

export function splitPathIntoIncrementalPathArray(pathName: string): string[] {
  const pathArray = pathName.split('/').filter(Boolean);
  let path = '';
  const result: string[] = [];

  pathArray.forEach((segment, i) => {
    if (i === 0) {
      path = `/${segment}`;
    } else {
      path = `${path}/${segment}`;
    }
    result.push(path);
  });

  return result;
}

export function getMainRoutes(routes: Route[]): Route[] {
  return routes.find((r) => r.path === RoutePaths.Root)?.children || [];
}

export function filterRoutes(
  routes: Route[],
  isAllowed: (route: Route) => boolean,
): Route[] {
  function recurse(routeList: Route[]): Route[] {
    return routeList.reduce<Route[]>((acc, next) => {
      if (isAllowed(next)) {
        // create shallow copy
        const routeCopy: Route = { ...next, children: [] };
        if (next.children) {
          const filteredChildren = recurse(next.children);
          if (filteredChildren.length) {
            routeCopy.children = filteredChildren;
          }
        }
        acc.push(routeCopy);
      } else if (next.children) {
        // maybe some children are allowed
        const filteredChildren = recurse(next.children);
        if (filteredChildren.length) {
          acc.push(...filteredChildren);
        }
      }
      return acc;
    }, []);
  }

  return recurse(routes);
}

export function findRouteByPath(
  routes: Route[],
  path: string,
): Route | undefined {
  function findRouteByPathRecursion(
    pathArray: string[],
    routeList: Route[],
  ): Route | undefined {
    const route = routeList.find((r) => r.path === pathArray[0]);
    if (!route) return undefined;

    if (pathArray.length === 1) {
      return route;
    }

    return route.children
      ? findRouteByPathRecursion(pathArray.slice(1), route.children)
      : undefined;
  }

  // special case: dashboard route
  if (path === RoutePaths.Root) {
    const rootRoute = routes.find((r) => r.path === RoutePaths.Root);

    if (rootRoute?.children?.length) {
      const indexRoute = rootRoute.children.find(
        (child) => child.path === RoutePaths.Root && child.index,
      );
      if (indexRoute) {
        return indexRoute;
      }
    }
    return rootRoute;
  }

  const pathArray = splitPathIntoIncrementalPathArray(path);
  const parentRoute = routes.find((r) => r.path === pathArray[0]);

  if (!parentRoute) {
    // fallback to main routes
    const mainRoutes = getMainRoutes(routes);
    return findRouteByPathRecursion(pathArray, mainRoutes);
  } else {
    return findRouteByPathRecursion(pathArray, routes);
  }
}

export function isAuthorized(
  path: string,
  layout: LayoutVariants | null,
  permissions: Record<PERMISSION_KEYS, UserPermissionModel>,
  routes: Route[],
): { status: boolean; redirectPath: string } {
  const route = findRouteByPath(routes, path);

  if (!route) {
    return { status: false, redirectPath: RoutePaths.Root };
  }

  let redirectPath = '';

  switch (layout) {
    case 'ApprovedClientGroup':
      redirectPath = RoutePaths.Root;
      break;
    case 'OnboardingClientGroup':
      redirectPath = RoutePaths.OnboardingPage;
      break;
    case 'NoClientGroup':
      redirectPath = RoutePaths.UserProfile_Root;
      break;
  }

  // check route.access.layout
  if (route.access?.layout && layout) {
    const hasLayoutAccess = route.access.layout === layout;
    if (!hasLayoutAccess) {
      return { status: false, redirectPath };
    }
  }

  // check route.access.permission
  if (route.access?.permission) {
    const hasPermissionAccess = permissions[route.access.permission]?.isAllowed;
    if (!hasPermissionAccess) {
      return { status: false, redirectPath };
    }
  }

  return { status: true, redirectPath: '' };
}
