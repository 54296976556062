import React, { useMemo } from 'react';

// helpers
import moment from 'moment';
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { getBadges } from 'redux/actions/app';
import { StateModel } from 'redux/reducers';
import { transfersAPI } from 'api/accounting/transfersAPI';
import { IAccountModel } from 'typings/onboarding/account';
import { transfersAdapter } from 'apiAdapters/accounting/transfersAdapter';
import { AccountProcessingTypes } from 'enums/accounting/transfers';
import { useDispatch, useSelector } from 'react-redux';
import { WireTransferPrefillDataFromQuery } from 'api/accounting/transactionsAPI';
import {
  convertAmountFromBigIntToInt,
  generateAccountFullName,
} from 'helpers/accountsHelpers';

// components
import WarningIconWithText from 'components/Additional/WarningIconWithText';
import WireTransferForm, {
  FormValuesModel,
} from 'components/Forms/TemplateForms/Transfers/WireTransferForm';
import { Message } from '@ui';

interface IProps {
  prefillData?: WireTransferPrefillDataFromQuery | null;
}

const SubmitSingleTransactionForm = ({ prefillData }: IProps) => {
  const { t } = useTranslation('transfers');
  const dispatch = useDispatch();
  const isTwoFactorVerificationEnabled = useSelector<StateModel, boolean>(
    (state) => state.auth.isTwoFactorVerificationEnabled,
  );

  const emptyTransactionInitialFormData = useMemo<FormValuesModel>(() => {
    return {
      sender: {
        accountNumber: '',
        reference: '',
        processingType: null,
        account: null,
      },
      recipient: {
        templateId: null,
        reference: '',
        template: null,
      },
      amount: null,
      transactionCurrencyId: null,
      valueDate: moment(),
      documents: [],
      transactionPurpose: '',
    };
  }, []);

  const initialFormData = useMemo<FormValuesModel>(() => {
    if (prefillData) {
      return {
        sender: {
          accountNumber: prefillData.fromAccount.accountNumber,
          accountNumberInitialValue: prefillData.fromAccount
            ? {
                id: prefillData.fromAccount.accountNumber,
                label: generateAccountFullName(
                  prefillData.fromAccount.accountName,
                  prefillData.fromAccount.accountNumber,
                  prefillData.fromAccount.currencyIsoCode,
                  prefillData.fromAccount.balance,
                ),
              }
            : undefined,
          reference: prefillData.fromAccountReference,
          processingType: AccountProcessingTypes.Native,
          account: {
            balance: prefillData.fromAccount.balance,
            availableBalance: prefillData.fromAccount.availableBalance,
            currencyId: prefillData.fromAccount.currencyId,
            currencyCode: prefillData.fromAccount.currencyIsoCode,
          } as IAccountModel,
        },
        recipient: {
          templateId: prefillData.beneficiaryTemplate.id,
          templateIdInitialValue: {
            id: prefillData.beneficiaryTemplate.id,
            label: prefillData.beneficiaryTemplate.templateName,
          },
          reference: prefillData.beneficiaryTemplate.reference,
          template: {
            templateId: prefillData.beneficiaryTemplate.id,
            templateName: prefillData.beneficiaryTemplate.templateName,
            accountName: prefillData.beneficiaryTemplate.beneficiaryAccountName,
            accountNumber:
              prefillData.beneficiaryTemplate.beneficiaryAccountNumber,
            city: prefillData.beneficiaryTemplate.beneficiaryCity,
            district: prefillData.beneficiaryTemplate.beneficiaryDistrict || '',
            address: prefillData.beneficiaryTemplate.beneficiaryAddress1,
            country: prefillData.beneficiaryTemplate.beneficiaryCountry,
            postCode: prefillData.beneficiaryTemplate.beneficiaryPostCode,
            useIntermediary:
              !!prefillData.beneficiaryTemplate.intermediaryBankCodeId,
            beneficiaryBank: {
              bankCodeType: prefillData.beneficiaryTemplate
                .beneficiaryBankCodeType as number,
              bankCodeTypeName:
                prefillData.beneficiaryTemplate.beneficiaryBankCodeTypeName ||
                '',
              bankCode: prefillData.beneficiaryTemplate.beneficiaryBankCode,
              bankName: prefillData.beneficiaryTemplate.beneficiaryBankName,
              country: prefillData.beneficiaryTemplate.beneficiaryBankCountry,
              address: prefillData.beneficiaryTemplate.beneficiaryBankAddress1,

              city: prefillData.beneficiaryTemplate.beneficiaryBankCity,
              district: prefillData.beneficiaryTemplate.beneficiaryBankDistrict,
              postCode: prefillData.beneficiaryTemplate.beneficiaryBankPostCode,
            },
            intermediaryBank: {
              bankCodeType: prefillData.beneficiaryTemplate
                .intermediaryBankCodeType as number,
              bankCodeTypeName:
                prefillData.beneficiaryTemplate.intermediaryBankCodeTypeName ||
                '',
              bankCode: prefillData.beneficiaryTemplate.intermediaryBankCode,
              bankName: prefillData.beneficiaryTemplate.intermediaryBankName,
              country: prefillData.beneficiaryTemplate.intermediaryBankCountry,
              address: prefillData.beneficiaryTemplate.intermediaryBankAddress1,

              city: prefillData.beneficiaryTemplate.intermediaryBankCity,
              district:
                prefillData.beneficiaryTemplate.intermediaryBankDistrict,
              postCode:
                prefillData.beneficiaryTemplate.intermediaryBankPostCode,
            },
          },
        },
        amount: convertAmountFromBigIntToInt(prefillData.amount),
        transactionCurrencyId: prefillData.currencyId,
        valueDate: moment(),
        documents: [],
        transactionPurpose: prefillData.purpose,
      };
    } else {
      return emptyTransactionInitialFormData;
    }
  }, [prefillData, emptyTransactionInitialFormData]);

  const handleSubmit = async (values: FormValuesModel) => {
    const formattedTransferData =
      transfersAdapter.generateBodyForWireTransfer(values);
    await transfersAPI.createWireTransfer(formattedTransferData);
    dispatch(getBadges());
    Message.success(t('international_transfer.success_submit_message'));
  };

  return (
    <>
      {!isTwoFactorVerificationEnabled ? (
        <StyledWarningInfoNot2FA
          text={t('international_transfer.warning_not_2fa')}
        />
      ) : null}
      <WireTransferForm
        initialValues={initialFormData}
        onSubmit={handleSubmit}
        disabled={!isTwoFactorVerificationEnabled}
        emptyTransactionFormValues={emptyTransactionInitialFormData}
      />
    </>
  );
};

const StyledWarningInfoNot2FA = styled(WarningIconWithText)`
  margin-bottom: ${({ theme }) => theme.marginXs};
`;

export default SubmitSingleTransactionForm;
