import i18n from 'i18n';
import StoreHelper from 'redux/StoreHelper';
import { Modal } from '@ui';
import { signOut } from 'redux/actions/auth';
import { REQ_TIMEOUT } from 'constants/global';
import { setLastActivityTimestamp } from 'redux/actions/app';
import { getUserSession, setUserSession } from 'helpers/sessionStorageHelpers';
import { getAccessToken, getRefreshToken } from 'helpers/localStorageHelpers';
import {
  generateFingerprint,
  getUserGeolocation,
} from 'helpers/sessionHelpers';
import axios, {
  AxiosError,
  AxiosResponse,
  AxiosRequestConfig,
  InternalAxiosRequestConfig,
} from 'axios';

interface AdditionalConfigValues {
  isPublic?: boolean;
  skipRefresh?: boolean;
  addFingerprintToHeaders?: boolean;
  addGeolocationDataToHeaders?: boolean;
  addRefreshTokenToHeader?: boolean;
  skipTimestampTracking?: boolean;
}

interface ConfigModel
  extends Omit<AxiosRequestConfig, 'headers'>,
    AdditionalConfigValues {
  headers?: Record<string, string>;
}

interface RequestInterceptorConfig
  extends InternalAxiosRequestConfig,
    AdditionalConfigValues {}

interface ErrorResponseModel {
  code: string;
  message: string;
  details: any;
}

export interface ApiErrorResponseModel
  extends Omit<
    AxiosError<ErrorResponseModel & { error?: ErrorResponseModel }>,
    'config'
  > {
  config?: ConfigModel;
}

export const UNAUTHORIZED_STATUS = 401;
export const TOO_MANY_REQUESTS_STATUS = 429;

export const REFRESH_HEADER = 'Refresh';
export const GEOLOCATION_HEADER = 'Geolocation';
export const FINGERPRINT_HEADER = 'Fingerprint';
export const AUTHORIZATION_HEADER = 'Authorization';

// ** Main axios instance **
const instance = axios.create({
  timeout: REQ_TIMEOUT,
});

// **  Request interceptor **
// TODO: handle empty path
instance.interceptors.request.use(async function (
  config: RequestInterceptorConfig,
) {
  if (!config.url) {
    throw new axios.Cancel('Operation canceled by the user.');
  }

  const token = getAccessToken();

  if (config.addFingerprintToHeaders || token) {
    config.headers[FINGERPRINT_HEADER] = await generateFingerprint();
  }

  if (token) {
    config.headers[AUTHORIZATION_HEADER] = `Bearer ${token}`;

    if (config.addRefreshTokenToHeader) {
      config.headers[REFRESH_HEADER] = getRefreshToken();
    }

    if (!config.skipTimestampTracking) {
      StoreHelper.dispatch(setLastActivityTimestamp(Date.now()));
    }
  }

  if (config.addGeolocationDataToHeaders) {
    const userSessionFromStorage = getUserSession();

    if (!userSessionFromStorage || !userSessionFromStorage.geolocation) {
      const geolocationData = await getUserGeolocation();
      if (geolocationData && geolocationData.data) {
        setUserSession({
          geolocation: geolocationData.data,
        });
        config.headers[GEOLOCATION_HEADER] = JSON.stringify(
          geolocationData.data,
        );
      }
    } else {
      config.headers[GEOLOCATION_HEADER] = JSON.stringify(
        userSessionFromStorage.geolocation,
      );
    }
  }

  return config;
});

instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (axios.isCancel(error)) {
      const cancelData = JSON.parse(error.message as any);
      if (cancelData.type === 'geolocation_not_enabled') {
        Modal.info({
          icon: '',
          width: 650,
          closable: true,
          content: i18n.t('geolocation_is_not_enabled', {
            ns: 'custom_errors',
          }),
          okText: i18n.t('ok', { ns: 'common' }),
        });
      } else {
        // Handle other cancel cases
      }
    } else {
      const { status } = error.response || {};

      switch (status) {
        case UNAUTHORIZED_STATUS:
          StoreHelper.dispatch(signOut(true));
          break;

        default: {
          throw error;
        }
      }
    }
  },
);

interface APIModel {
  get<T = any, R = AxiosResponse<T>>(
    endpointKey: string,
    config?: ConfigModel | null,
  ): Promise<R>;

  post<T = any, R = AxiosResponse<T>>(
    endpointKey: string,
    data?: any,
    config?: ConfigModel | null,
  ): Promise<R>;

  put<T = any, R = AxiosResponse<T>>(
    endpointKey: string,
    data?: any,
    config?: ConfigModel | null,
  ): Promise<R>;

  delete<T = any, R = AxiosResponse<T>>(
    endpointKey: string,
    config?: ConfigModel | null,
  ): Promise<R>;

  patch<T = any, R = AxiosResponse<T>>(
    endpointKey: string,
    data?: any,
    config?: ConfigModel | null,
  ): Promise<R>;
}

// ** Main API instance for all requests **
export const APIService: APIModel = {
  get(path, config) {
    return instance.get(path, config || undefined);
  },

  post(path, data, config) {
    return instance.post(path, data, config || undefined);
  },

  put(path, data, config) {
    return instance.put(path, data, config || undefined);
  },

  delete(path, config) {
    return instance.delete(path, config || undefined);
  },

  patch(path, data, config) {
    return instance.patch(path, data, config || undefined);
  },
};

export default instance;
