import React, { useMemo, useState } from 'react';

// helpers
import styled from 'styled-components';
import useFetch from 'hooks/useFetch';
import useTranslation from 'hooks/useTranslation';
import { getBadges } from 'redux/actions/app';
import { StateModel } from 'redux/reducers';
import { RoutePaths } from 'routes/routes';
import { useNavigate } from 'react-router-dom';
import { colorsTheme } from 'resources/theme/styled/colors';
import { RequestModel } from '../..';
import { connectionsAPI } from 'api/connections/connectionsAPI';
import { selectApplication } from 'redux/actions/applications';
import { formatDateToString } from 'helpers/dateHelpers';
import { useDispatch, useSelector } from 'react-redux';
import { DEFAULT_DATE_TIME_FORMAT } from 'constants/global';
import { StateModel as SettingsStateModel } from 'redux/reducers/settings';

// components
import { Text, Button, Message, Modal } from '@ui';
import ErrorHandlerService from 'services/error-handler/service';
import HandoffRequestModal, {
  HandoffRequestModalData,
} from 'components/ModalDialogs/TemplateModalDialogs/Connections/HandoffRequestModal';
import { Trans } from 'react-i18next';

interface IProps {
  data: RequestModel;
  updateRequests: () => void;
}

const ViewHandoffRequest = ({ data, updateRequests }: IProps) => {
  const { t } = useTranslation(['connections', 'server_errors']);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { layoutVariant } = useSelector<StateModel, SettingsStateModel>(
    (state) => state.settings,
  );
  const activeClientGroupName = useSelector<StateModel, string>(
    (state) =>
      state.applications.activeApplication?.clientGroup?.clientGroupName || '',
  );

  const { response } = useFetch(
    () =>
      data.id && isModalVisible
        ? connectionsAPI.fetchRequestById(data.id)
        : null,
    [data.id, isModalVisible],
  );

  const dialogData = useMemo<HandoffRequestModalData | null>(() => {
    if (!response) {
      return null;
    }

    return {
      invitedBy: response.creator.name,
      requestedAt: formatDateToString(
        response._audit.createdAt,
        DEFAULT_DATE_TIME_FORMAT,
      ),
      clientGroup: response.details.clientGroupName,
    };
  }, [response]);

  const handleAction = async (action: 'approve' | 'reject') => {
    let newApplicationId: string | null = null;

    try {
      switch (action) {
        case 'approve':
          {
            const acceptHandoffResponse =
              await connectionsAPI.acceptHandoffRequest(data.id);
            newApplicationId = acceptHandoffResponse.applicationId || null;
            Message.success(
              t(
                'requests.view_request_modal.handoff_application_request.success_approve_message',
              ),
            );
          }
          break;

        case 'reject':
          {
            await connectionsAPI.declineHandoffRequest(data.id);
            Message.success(
              t(
                'requests.view_request_modal.handoff_application_request.success_reject_message',
              ),
            );
          }
          break;
      }

      dispatch(getBadges());
      updateRequests();
      setIsModalVisible(false);

      if (action == 'approve') {
        Modal.confirm({
          title: t(
            'requests.view_request_modal.handoff_application_request.switch_to_new_client_group.title',
          ),
          width: 600,
          icon: null,
          closable: true,
          maskClosable: true,
          content: (
            <>
              <Text gutterBottom>
                <Trans
                  t={t}
                  i18nKey="requests.view_request_modal.handoff_application_request.switch_to_new_client_group.description"
                  values={{ clientGroupName: data.details.clientGroupName }}
                  components={[
                    <Text
                      key="client-group-name"
                      color={colorsTheme.colorWhite}
                      variant="span"
                      weight="semi-bold"
                    />,
                  ]}
                />
              </Text>

              <ItalicText gutterTop>
                {layoutVariant == 'NoClientGroup' ? (
                  <Trans
                    t={t}
                    i18nKey="requests.view_request_modal.handoff_application_request.switch_to_new_client_group.sub_description_for_users_without_client_groups"
                    components={[
                      <Text
                        key="highlighted-text"
                        variant="span"
                        weight="semi-bold"
                      />,
                    ]}
                    values={{
                      newClientGroupName: data.details.clientGroupName,
                    }}
                  />
                ) : (
                  <Trans
                    t={t}
                    i18nKey="requests.view_request_modal.handoff_application_request.switch_to_new_client_group.sub_description"
                    components={[
                      <Text
                        key="highlighted-text"
                        variant="span"
                        weight="semi-bold"
                      />,
                    ]}
                    values={{
                      newClientGroupName: data.details.clientGroupName,
                      activeClientGroupName,
                    }}
                  />
                )}
              </ItalicText>
            </>
          ),
          okText: t('confirm', { ns: 'common' }),
          async onOk() {
            if (newApplicationId) {
              dispatch(
                selectApplication(newApplicationId, true, true, () => {
                  navigate(RoutePaths.Root);
                  Message.success(
                    t('client_groups.switch.success_message', {
                      clientGroup: data.details.clientGroupName,
                    }),
                  );
                }),
              );
            }
          },
        });
      }
    } catch (error: any) {
      const errorCode = ErrorHandlerService.getErrorCodeFromError(error);
      switch (errorCode) {
        case '1019012': {
          ErrorHandlerService.displayErrorMessage(
            t('1019012', {
              ns: 'server_errors',
              clientGroupName: data.details.clientGroupName,
            }),
          );
          break;
        }
        default: {
          ErrorHandlerService.handleError(error);
          break;
        }
      }
    }
  };

  return (
    <>
      <Button onClick={() => setIsModalVisible(true)}>
        {t('requests.view_button')}
      </Button>
      <HandoffRequestModal
        isVisible={isModalVisible}
        closeCallback={() => setIsModalVisible(false)}
        data={dialogData}
        onAction={handleAction}
      />
    </>
  );
};

const ItalicText = styled(Text)`
  font-style: italic;
`;

export default ViewHandoffRequest;
