import React, { useEffect, useMemo, useState } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { StateModel } from 'redux/reducers';
import { FormValuesModel } from '../../..';
import { useFormikContext } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { StateModel as UploadStateModel } from 'redux/reducers/upload';
import { VerificationDocumentRequirements } from '../..';
import { refreshUploadState, uploadFiles } from 'redux/actions/upload';
import {
  RESET_AUTHENTICATION_REQUEST_TAGS,
  UploadStatus,
} from 'constants/documents';
import {
  VerificationDocumentTypes,
  VerificationDocumentPhotoTypes,
} from 'enums/profile/accountRecovery';

// components
import UploadDocumentCard from './UploadDocumentCard';
import { Text, Button, Col, Row, Layout, Content, Footer } from '@ui';
import { formatFileToLocalDocument } from 'helpers/documentsHelpers';

const UploadDocumentMethodForm = () => {
  const { t } = useTranslation(['auth', 'common']);

  const [tempFiles, setTempFiles] = useState<{
    [key: string]: File;
  }>({});

  const dispatch = useDispatch();
  const uploadState = useSelector<StateModel, UploadStateModel>(
    (state) => state.upload,
  );

  const { values, setFieldValue } = useFormikContext<FormValuesModel>();

  useEffect(() => {
    const { isFinished, files } = uploadState;

    if (isFinished) {
      const newSnapshots: { [key: string]: string } = {};
      Object.keys(files).forEach((key) => {
        const { dataBaseId = '', relatedToFieldName = '' } = files[key];
        newSnapshots[relatedToFieldName] = dataBaseId;
      });
      setFieldValue('snapshots', newSnapshots);
      setFieldValue('isUploadedIdDocumentPhotos', true);
      dispatch(refreshUploadState());
    }
  }, [uploadState.isFinished]);

  const isUploadedAllFiles = useMemo(() => {
    const { idVerificationType } = values;

    // 'Length - 1' is needed here because we shouldn't take selfie into account here
    const result =
      tempFiles &&
      idVerificationType &&
      Object.keys(tempFiles).length ===
        VerificationDocumentRequirements[idVerificationType].steps.length - 1;

    return result;
  }, [tempFiles]);

  const renderUploadDocumentCards = (
    tempFiles: { [key: string]: File },
    verificationType: VerificationDocumentTypes | null,
  ) => {
    if (!verificationType) {
      return null;
    }

    const filteredElements = VerificationDocumentRequirements[
      verificationType
    ].steps.filter(
      ({ type }) => type !== VerificationDocumentPhotoTypes.Selfie,
    );

    return filteredElements.map(({ type }) => {
      return (
        <Col key={type}>
          <UploadDocumentCard
            file={tempFiles[type] || null}
            verificationDocumentType={type}
            onSelect={handleImageSelect}
            onDeleteClick={handleImageDelete}
          />
        </Col>
      );
    });
  };

  const handleImageSelect = (
    type: VerificationDocumentPhotoTypes,
    image: File,
  ) => {
    if (!image) {
      return;
    }
    const newTempFiles = { ...tempFiles, [type]: image };
    setTempFiles(newTempFiles);
  };

  const handleImageDelete = (type: VerificationDocumentPhotoTypes) => {
    const newTempFiles: any = { ...tempFiles };
    delete newTempFiles[type];
    setTempFiles(newTempFiles);
  };

  const onNextClick = (selectedFiles: { [key: string]: File }) => {
    const mappedFiles = Object.keys(selectedFiles).map((key) =>
      formatFileToLocalDocument(
        selectedFiles[key],
        RESET_AUTHENTICATION_REQUEST_TAGS,
        key,
      ),
    );

    if (mappedFiles.length) {
      dispatch(uploadFiles([...mappedFiles], undefined, undefined));
    }
  };

  const isUploadingDocuments = () => {
    return uploadState.status === UploadStatus.ACTIVE;
  };

  return (
    <StyledLayout>
      <StyledContent>
        <Title gutterBottom>
          {t(
            'account_recovery.id_verification_step.upload_document_methods.upload.upload_images',
          )}
        </Title>
        <StyledRow gutter={[20, 20]} justify="center">
          {renderUploadDocumentCards(tempFiles, values.idVerificationType)}
        </StyledRow>
      </StyledContent>
      {isUploadedAllFiles && (
        <StyledFooter>
          <Row justify="center">
            <Col flex="200px">
              <StyledButton
                loading={isUploadingDocuments()}
                size="large"
                onClick={() => onNextClick(tempFiles)}
              >
                {t('next', { ns: 'common' })}
              </StyledButton>
            </Col>
          </Row>
        </StyledFooter>
      )}
    </StyledLayout>
  );
};
const StyledLayout = styled(Layout)`
  background: ${({ theme }) => theme.colorDarkL1};
`;

const StyledContent = styled(Content)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StyledFooter = styled(Footer)`
  padding: 0;
  background: ${({ theme }) => theme.colorDarkL1};
`;

const StyledRow = styled(Row)`
  height: 100%;
  margin-bottom: 10px;
`;

const Title = styled(Text)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledButton = styled(Button)`
  width: 100%;
`;

export default UploadDocumentMethodForm;
