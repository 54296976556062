import React, { useMemo } from 'react';

// helpers
import useUserAccess from 'hooks/useUserAccess';
import useTranslation from 'hooks/useTranslation';
import { IEntity } from 'typings/application/entity';
import { getBadges } from 'redux/actions/app';
import { StateModel } from 'redux/reducers';
import { ContactModel } from 'typings/application/contact';
import { accountsAdapter } from 'apiAdapters/accounting/accountsAdapter';
import { FormValuesModel } from 'components/ModalDialogs/TemplateModalDialogs/Accounts/AddEditAccountModalDialog';
import {
  getFormattedContactName,
  getEntityNameByNameType,
} from 'helpers/crmHelpers';
import { useDispatch, useSelector } from 'react-redux';
import { accountsAPI, NewAccountModel } from 'api/accounting/accountsAPI';

// constants
import { AccountProcessingTypes } from 'enums/accounting/transfers';
import { AccountTypes, OnboardingEntryTypes } from 'enums/onboarding/crm';

// components
import AddEditAccountModalDialog from 'components/ModalDialogs/TemplateModalDialogs/Accounts/AddEditAccountModalDialog';
import { Message } from '@ui';

interface IProps {
  isVisible: boolean;
  closeCallback: () => void;
}

const AddAccountModalDialog = ({ isVisible, closeCallback }: IProps) => {
  const { t } = useTranslation('accounts');
  const dispatch = useDispatch();

  const applicantName = useSelector<StateModel, string>((state) => {
    let result = '';

    if (state.applications.activeApplication?.onboardingProcess) {
      if (
        state.applications.activeApplication?.onboardingProcess.type ===
        OnboardingEntryTypes.Contact
      ) {
        const contact = state.applications.activeApplication
          ?.model as ContactModel;
        result = getFormattedContactName(
          contact.firstName,
          contact.lastName,
          contact.middleName,
        );
      } else {
        const entity = state.applications.activeApplication?.model as IEntity;
        result = getEntityNameByNameType(entity.names) || '';
      }
    }

    return result;
  });

  const [hasFiatAccountCreateAccess, hasBlockchainAccountCreateAccess] =
    useUserAccess([
      'Accounts_FiatAccount_Create',
      'Accounts_BlockchainAccount_Create',
    ]);

  const initialFormValues = useMemo<FormValuesModel>(() => {
    const accountType =
      hasFiatAccountCreateAccess && hasBlockchainAccountCreateAccess
        ? null
        : hasFiatAccountCreateAccess
          ? AccountProcessingTypes.Native
          : hasBlockchainAccountCreateAccess
            ? AccountProcessingTypes.Circle
            : null;

    return {
      accountType,

      circleAccount: {
        accountName: '',
        currencyId: '180',
        predefinedCurrencyOption: {
          id: '180',
          label: 'USDC',
        },
      },

      fiatAccount: {
        type: AccountTypes.current,
        accountName: applicantName,
        purpose: '',
        currencyId: null,
        initialDeposit: null,
        initialDepositOrigin: null,
        jurisdictions: [],
        incomingWiresNumber: null,
        estimatedIncomingFunds: null,
        outgoingWiresNumber: null,
        estimatedOutgoingFunds: null,
      },
    };
  }, [
    hasFiatAccountCreateAccess,
    hasBlockchainAccountCreateAccess,
    applicantName,
  ]);

  const handleSubmit = async (values: FormValuesModel) => {
    switch (values.accountType) {
      case AccountProcessingTypes.Circle:
        {
          const newAccount: NewAccountModel = {
            currencyId: Number(values.circleAccount.currencyId),
            accountName: values.circleAccount.accountName,
          };

          await accountsAPI.createAccount(newAccount);
          Message.success(
            t('account_summary.create_account.success_submit_message'),
          );
        }
        break;

      case AccountProcessingTypes.Native:
        {
          const newAccount: NewAccountModel =
            accountsAdapter.generateBodyForCreateFiatBankAccount(values);
          await accountsAPI.createAccount(newAccount);
          dispatch(getBadges());
          Message.success(
            t('account_summary.create_account.success_submit_message'),
          );
        }
        break;
    }
  };

  return (
    <AddEditAccountModalDialog
      title={t('account_summary.create_account.title')}
      isVisible={isVisible}
      onSubmit={handleSubmit}
      closeCallback={closeCallback}
      initialValues={initialFormValues}
    />
  );
};

export default AddAccountModalDialog;
