import _ from 'lodash';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { IUserDevice } from '../typings/profile/security';
import { ISessionInfoGeolocation } from '../api/profile/userAPI';

export type GeolocationGrantStatus = 'granted' | 'denied' | 'other-no-access';

export async function generateFingerprint(): Promise<string> {
  const FingerprintHelpers = await FingerprintJS.load();
  const result = await FingerprintHelpers.get();
  const components = _.omit(result.components, [
    'screenResolution',
    'screenFrame',
    'fontPreferences',
    'hdr',
    'colorDepth',
  ]);
  const hashString = FingerprintJS.hashComponents(components);
  return hashString;
}

export async function getUserGeolocation(): Promise<
  | { status: GeolocationGrantStatus; data: ISessionInfoGeolocation | null }
  | undefined
> {
  const status = await getGeolocationStatus();
  const nullGeolocation = { accuracy: 1000.0, latitude: 0.0, longitude: 0.0 };

  if (status !== 'denied') {
    return new Promise((resolve) => {
      window.navigator.geolocation.getCurrentPosition(
        (position) => {
          resolve({
            status: 'granted',
            data: {
              accuracy: position.coords.accuracy,
              altitude: position.coords.altitude || undefined,
              altitudeAccuracy: position.coords.altitudeAccuracy || undefined,
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            },
          });
        },
        () => {
          resolve({ status: 'other-no-access', data: nullGeolocation });
        },
        { timeout: 10000 },
      );
    });
  } else {
    return { status, data: nullGeolocation };
  }
}

export function getDeviceName(device: IUserDevice): string {
  const clientVersion = device.client?.version
    ? `v.${device.client?.version}`
    : '';
  return `${device.os?.name || '-'} | ${device.client?.name || '-'} ${clientVersion}`;
}

export async function getGeolocationStatus(): Promise<GeolocationGrantStatus> {
  try {
    const permissionState = await window.navigator.permissions.query({
      name: 'geolocation',
    });
    if (permissionState.state === 'granted') {
      return 'granted';
    } else if (permissionState.state === 'denied') {
      return 'denied';
    } else {
      // Geolocation permission status is "prompt" or "default".
      // Means the user has not yet made a decision about the permission, or the permission hasn't been requested yet.
      return 'other-no-access';
    }
  } catch (e) {
    return 'other-no-access';
  }
}
