import React, { memo, useState } from 'react';

// helpers
import { styled } from 'styled-components';
import { FormValuesModel } from '../..';
import { useField, useFormikContext } from 'formik';
import {
  FetchTransactionBalancesPayload,
  transfersAPI,
} from 'api/accounting/transfersAPI';
import {
  convertAmountFromBigIntToInt,
  convertAmountFromIntToBigInt,
} from 'helpers/accountsHelpers';

// components
import { FormField } from '@ui';
import { FormInputAmount } from '@ui';

interface IProps {
  rowIndex: number;
  fieldName: string;
  disabled?: boolean;
}

const AmountField = memo(({ fieldName, rowIndex, disabled }: IProps) => {
  const [prevValue, setPrevValue] = useState<number | null>(null);
  const [, , helpers] = useField(fieldName);
  const { values, setValues, setSubmitting } =
    useFormikContext<FormValuesModel>();

  async function updateAvailableBalances(newValue: number) {
    const transactionsCopy = [...values.transactions];

    transactionsCopy[rowIndex] = {
      ...transactionsCopy[rowIndex],
      amount: newValue,
    };

    const formattedTransactions =
      transactionsCopy.reduce<FetchTransactionBalancesPayload>((acc, curr) => {
        if (curr.from) {
          acc.push({
            sequenceNumber: curr.customId,
            fromAccountNumber: curr.from,
            currencyId: curr.currencyId as number,
            amount: curr.amount ? convertAmountFromIntToBigInt(curr.amount) : 0,
          });
        }

        return acc;
      }, []);

    setSubmitting(true);

    const response = await transfersAPI.fetchBalancesForWireTransfers(
      formattedTransactions,
    );

    const updatedTransactions = transactionsCopy.map((transaction) => {
      const updatedTransaction = response.find(
        (responseTransaction) =>
          responseTransaction.sequenceNumber === transaction.customId,
      );

      if (updatedTransaction) {
        return {
          ...transaction,
          balance: transaction.balance
            ? {
                ...transaction.balance,
                available: convertAmountFromBigIntToInt(
                  updatedTransaction.availableBalance,
                ),
              }
            : null,
        };
      }

      return transaction;
    });

    setValues({ transactions: updatedTransactions });
    setSubmitting(false);
  }

  const handleOnChange = (newValue: number) => {
    helpers.setValue(newValue);

    // Check if this is not the last item in array and if the value is different from the prev value
    if (rowIndex + 1 !== values.transactions.length && prevValue !== newValue) {
      setPrevValue(newValue);
      updateAvailableBalances(newValue);
    }
  };

  return (
    <FormField
      disabled={disabled}
      shouldShowErrorMessage={false}
      name={fieldName}
      component={StyledFormAmountField}
      additionalProps={{ min: 0, onChange: handleOnChange }}
    />
  );
});

const StyledFormAmountField = styled(FormInputAmount)`
  width: 100%;
`;

export default AmountField;
