import { ITransferDocument } from 'api/accounting/transfersAPI';
import { NewBlockchainTransferModel } from 'typings/accounting/transfer';
import { formatDateToTimestampWithTruncatedTime } from 'helpers/dateHelpers';
import { FormValuesModel as WireTransferFormValues } from 'components/Forms/TemplateForms/Transfers/WireTransferForm';
import { FormValuesModel as InternalTransferFormValues } from 'components/Forms/TemplateForms/Transfers/InternalTransferForm';
import { convertAmountFromIntToBigInt } from 'helpers/accountsHelpers';

export interface ICreateInternalTransferRequestBody {
  amount: number;
  currencyId: number;
  beneficiaryAccountNumber: string;
  beneficiaryReference: string;
  fromAccountNumber: string;
  purpose: string;
  reference: string;
  valueDate: number;
  templateId?: any;
  relatedDocuments: ITransferDocument[];
}

export interface NewWireTransferBodyModel {
  fromAccountNumber: string;
  purpose: string;
  currencyId: number;
  templateId: number;
  amount: number;
  valueDate: number;
  reference: string;
  relatedDocuments: ITransferDocument[];
  beneficiaryReference: string;
}

export interface NewBlockchainTransferBodyModel {
  fromAccountNumber: string;
  beneficiaryReference: string;
  reference: string;
  purpose: string;
  currencyId: number;
  templateId: number;
  amount: number;
  valueDate: number;
  relatedDocuments: ITransferDocument[];
}

const transfersAdapter = {
  generateBodyForInternalTransfer: (
    values: InternalTransferFormValues,
  ): ICreateInternalTransferRequestBody => {
    const {
      sender,
      recipient,
      transactionCurrencyId,
      transactionPurpose,
      documents,
    } = values;

    return {
      amount: values.amount
        ? convertAmountFromIntToBigInt(values.amount) || 0
        : 0,
      beneficiaryAccountNumber:
        (recipient.templateId
          ? recipient.template?.accountNumber
          : recipient.accountNumber) || '',
      beneficiaryReference: recipient.reference.trim(),
      currencyId: transactionCurrencyId || 0,
      fromAccountNumber: sender.accountNumber || '',
      purpose: transactionPurpose.trim(),
      reference: sender.reference.trim(),
      valueDate: formatDateToTimestampWithTruncatedTime(values.valueDate),
      templateId: recipient.templateId || undefined,
      relatedDocuments: documents.map((e) => ({
        dmsId: e.id,
        name: e.name,
        size: String(e.file?.size) as string,
        type: e.file?.contentType as string,
        linkToDownload: e.file?.id as string,
      })),
    };
  },

  generateBodyForWireTransfer: (
    values: WireTransferFormValues,
  ): NewWireTransferBodyModel => {
    const {
      sender,
      recipient,
      transactionCurrencyId,
      transactionPurpose,
      documents,
    } = values;

    return {
      amount: values.amount
        ? convertAmountFromIntToBigInt(values.amount) || 0
        : 0,
      beneficiaryReference: recipient.reference.trim(),
      currencyId: transactionCurrencyId || 0,
      fromAccountNumber: sender.accountNumber || '',
      purpose: transactionPurpose.trim(),
      reference: sender.reference.trim(),
      valueDate: formatDateToTimestampWithTruncatedTime(values.valueDate),
      templateId: recipient.templateId as number,
      relatedDocuments: documents.map((e) => ({
        dmsId: e.id,
        name: e.name,
        size: String(e.file?.size) as string,
        type: e.file?.contentType as string,
        linkToDownload: e.file?.id as string,
      })),
    };
  },

  generateBodyForBlockchainTransfer: (
    values: NewBlockchainTransferModel,
  ): NewBlockchainTransferBodyModel => {
    const {
      sender,
      recipient,
      transactionCurrencyId,
      transactionPurpose,
      documents,
    } = values;

    return {
      amount: values.amount
        ? convertAmountFromIntToBigInt(values.amount) || 0
        : 0,
      beneficiaryReference: recipient.reference.trim(),
      currencyId: transactionCurrencyId || 0,
      fromAccountNumber: sender.accountNumber || '',
      purpose: transactionPurpose.trim(),
      reference: sender.reference.trim(),
      valueDate: formatDateToTimestampWithTruncatedTime(values.valueDate),
      templateId: recipient.templateId as number,
      relatedDocuments: documents.map((e) => ({
        dmsId: e.id,
        name: e.name,
        size: String(e.file?.size) as string,
        type: e.file?.contentType as string,
        linkToDownload: e.file?.id as string,
      })),
    };
  },
};

export { transfersAdapter };
