import React, { useMemo } from 'react';

// helpers
import styled from 'styled-components';
import { IEntity } from 'typings/application/entity';
import { ContactModel } from 'typings/application/contact';
import { GraphData, GraphNode } from '../../../../../../Charts/GraphChart';
import {
  getEntityNameByNameType,
  getFormattedContactName,
} from 'helpers/crmHelpers';

// constants
import { colorsTheme } from 'resources/theme/styled/colors';
import { OnboardingEntryTypes, OnboardingStatuses } from 'enums/onboarding/crm';

// components
import { IconSVG, Tree, DataNode } from '@ui';
import EllipsisTooltip from '../../../../../../Tooltips/EllipsisTooltip';
import { ReactComponent as InfoIcon } from 'resources/icons/remix-icons/information-line.svg';
import { ReactComponent as CheckIcon } from 'resources/icons/remix-icons/check-line.svg';
import { ReactComponent as CloseIcon } from 'resources/icons/remix-icons/close-line.svg';

interface IProps {
  graph: GraphData;
  selectedItemId: string | null;
  onSelect: (node: GraphNode) => void;
  hideStatuses?: boolean;
}

const RelationshipsTree = ({
  graph,
  onSelect,
  selectedItemId,
  hideStatuses,
}: IProps) => {
  const getStatusIcon = (node: GraphNode) => {
    if (!node.onboardingProcess) {
      return null;
    }

    switch (node.onboardingProcess.status) {
      case OnboardingStatuses.InReview:
        return (
          <StyledIconSVG
            component={InfoIcon}
            color={colorsTheme.colorWarning}
          />
        );

      case OnboardingStatuses.Approved:
        return (
          <StyledIconSVG
            component={CheckIcon}
            color={colorsTheme.colorPrimary}
          />
        );

      case OnboardingStatuses.Rejected:
        return (
          <>
            <StyledIconSVG
              component={CheckIcon}
              color={colorsTheme.colorPrimary}
            />
            <StyledIconSVG
              component={CloseIcon}
              color={colorsTheme.colorWarning}
            />
          </>
        );

      default:
        return null;
    }
  };

  const getIsFilledIcon = (node: GraphNode) => {
    return node.allInformationFilled ? (
      <StyledIconSVG component={CheckIcon} color={colorsTheme.colorPrimary} />
    ) : (
      <StyledIconSVG component={InfoIcon} color={colorsTheme.colorWarning} />
    );
  };

  const getTitle = (node: GraphNode) => {
    const contact = node.model as ContactModel;
    const entity = node.model as IEntity;

    const itemName =
      node.entryType === OnboardingEntryTypes.Contact
        ? getFormattedContactName(
            contact.firstName,
            contact.lastName,
            contact.middleName,
          )
        : getEntityNameByNameType(entity.names);

    const statusIcon = !hideStatuses && getStatusIcon(node);
    const isFilledIcon = !hideStatuses && getIsFilledIcon(node);

    const maxTextContainerWidth = node.isApplicantOrganization
      ? '175px'
      : '150px';

    return (
      <StyledTitleWrapper>
        <EllipsisTooltip
          placement="right"
          maxTextContainerWidth={maxTextContainerWidth}
          title={itemName}
        >
          {itemName}
        </EllipsisTooltip>

        {isFilledIcon}
        {statusIcon}
      </StyledTitleWrapper>
    );
  };

  const treeData = useMemo<DataNode[]>(() => {
    const applicantOrganizationNodeIndex = graph.nodes.findIndex(
      (e) => e.isApplicantOrganization,
    );
    const applicantOrganizationChildrenNodes = graph.nodes.reduce<DataNode[]>(
      (acc: DataNode[], cur: GraphNode) => {
        if (!cur.isApplicantOrganization) {
          acc.push({
            key: cur.id,
            title: getTitle(cur),
            node: cur,
          } as DataNode);
        }
        return acc;
      },
      [],
    );

    return [
      {
        key: graph.nodes[applicantOrganizationNodeIndex].id,
        title: getTitle(graph.nodes[applicantOrganizationNodeIndex]),
        node: graph.nodes[applicantOrganizationNodeIndex],
        children: applicantOrganizationChildrenNodes,
      },
    ];
  }, [graph]);

  return (
    <Tree
      defaultExpandAll
      selectedKeys={selectedItemId ? [selectedItemId] : []}
      treeData={treeData}
      onSelect={(_, { node }: any) => onSelect(node.node as GraphNode)}
    />
  );
};

const StyledIconSVG = styled(IconSVG)`
  margin-left: ${({ theme }) => theme.marginXXs};
`;

const StyledTitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export default RelationshipsTree;
