import React, { useMemo } from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';
import { formatDateToString } from 'helpers/dateHelpers';
import { TravelNotificationModel } from 'typings/profile/travelNotifications';
import { DEFAULT_DATE_TIME_FORMAT } from 'constants/global';

// components
import { Link } from '@ui';
import CountriesTable from '../CountriesTable';
import { Table, RequiredPropsForTableModel, TableColumnModel } from '@ui';

interface IProps extends RequiredPropsForTableModel<TravelNotificationModel> {
  onActionsClick: (key: string, value: string) => void;
}

const TravelNotificationsTable = ({ onActionsClick, ...rest }: IProps) => {
  const { t } = useTranslation('profile');

  const renderNotificationDate = (record: TravelNotificationModel) => {
    const formattedDate = formatDateToString(
      record.createdAt,
      DEFAULT_DATE_TIME_FORMAT,
    );

    if (!record.canEdit) {
      return formattedDate;
    }

    return (
      <Link onClick={() => onActionsClick('edit', record.id)}>
        {formattedDate}
      </Link>
    );
  };

  const columns = useMemo<TableColumnModel[]>(() => {
    return [
      {
        width: '15%',
        title: t('travel_notifications.table.date'),
        key: 'date',
        render: renderNotificationDate,
      },

      {
        title: t('travel_notifications.table.trip'),
        key: 'countries',
        render: (record: TravelNotificationModel) => (
          <CountriesTable
            data={record.countries}
            total={1}
            current={1}
            loading={false}
          />
        ),
      },
    ];
  }, [onActionsClick]);

  return <Table columns={columns} {...rest} />;
};

export default TravelNotificationsTable;
