import i18n from 'i18n';
import moment from 'moment';
import { MomentInput } from 'moment';
import { Relationship } from 'typings/relationshipsGraph';
import { IKeyRelation } from 'typings/application/key-relationship-templates';
import { tryToParseJSON } from './commonHelpers';
import { formatDateToUTC } from './dateHelpers';
import { clientGroupsAPI } from 'api/crm/clientGroupsAPI';
import { ExpiringDocument } from 'typings/application/applications';
import { ShortDocumentModel } from 'typings/documents/documents';
import { RelationshipTemplate } from 'typings/application/relationship-template';
import { IContactEmail, IContactPhone } from 'typings/application/contact';
import { AdditionalFieldFormItemModel } from '../components/Forms/FormComponents/AdditionalField';
import { IEntityName, ISocialMediaModel } from 'typings/application/entity';
import {
  AdditionalFieldTypes,
  EntityTypes,
  OnboardingEntryTypes,
  RelationshipTrackTypes,
  SocialMediaTypes,
} from 'enums/onboarding/crm';
import {
  IDocumentForm,
  IInformationFilled,
  RangeOptionModel,
} from 'typings/onboarding/onboarding';

export function getFormattedContactName(
  firstName: string,
  lastName: string,
  middleName?: string,
) {
  return [firstName, middleName, lastName].filter(Boolean).join(' ');
}

export function getPrimaryEmail(emails: IContactEmail[]): string {
  const primaryEmail = emails.find((email) => email.isPrimary);
  return primaryEmail?.address || '';
}

export function getPrimaryPhoneNumber(phoneNumbers: IContactPhone[]): string {
  const primaryPhoneNumber = phoneNumbers.find((phone) => phone.isPrimary);
  return primaryPhoneNumber?.number || '';
}

export async function validatePhoneNumbers(phoneNumbers: string[]) {
  try {
    const response = await clientGroupsAPI.validatePhoneNumbers(phoneNumbers);

    const invalidNumbers = response.data.reduce<string[]>((acc, next) => {
      if (!next.isValid) {
        acc.push(next.phoneNumber);
      }

      return acc;
    }, []);

    const errors = {} as Record<string, { errorMessage: string }>;

    phoneNumbers.forEach((phoneNumber) => {
      if (invalidNumbers.includes(phoneNumber)) {
        errors[phoneNumber] = {
          errorMessage: i18n.t('validation_phone_number.invalid_phone_number', {
            ns: 'custom_errors',
          }),
        };
      }
    });

    return Object.keys(errors).length === 0 ? undefined : errors;
  } catch (error) {
    // If there's an error, return an undefined value
    return undefined;
  }
}

export function getEntityNameByNameType(
  names: IEntityName[],
  kind = EntityTypes.Legal,
): string {
  const nameItem = names?.find((el) => el.type === kind);
  return nameItem?.name || '';
}

export function formatFromJSON(
  fieldType: AdditionalFieldTypes,
  value: string,
): any {
  const parsedValue = tryToParseJSON(value);

  if (!parsedValue.success) {
    return parsedValue.value;
  }

  switch (fieldType) {
    case AdditionalFieldTypes.Date: {
      return parsedValue.value
        ? moment(parsedValue.value as MomentInput)
        : undefined;
    }

    case AdditionalFieldTypes.Form:
    case AdditionalFieldTypes.Document: {
      let result: ShortDocumentModel[] = [];

      if (
        parsedValue.value &&
        Array.isArray(parsedValue.value) &&
        parsedValue.value.length
      ) {
        result = parsedValue.value.map((e: any) => ({
          id: e.id,
          name: e.files[0].name,
          fileId: e.files[0].id,
          file: e.files[0],
        }));
      }

      return result;
    }

    default:
      return parsedValue.value;
  }
}

export function formatToJSON(fieldType: AdditionalFieldTypes, value: any) {
  if (
    value === undefined ||
    value === '' ||
    (typeof value == 'string' && value.trim() === '')
  ) {
    return '';
  }

  let result = '';

  switch (fieldType) {
    case AdditionalFieldTypes.Document:
    case AdditionalFieldTypes.Form:
      {
        if (value?.length) {
          const documentIds = value.map(({ id }: ShortDocumentModel) => id);
          result = JSON.stringify(documentIds);
        }
      }
      break;

    default: {
      result = JSON.stringify(value);
    }
  }

  return result;
}

export function mapAdditionalFieldFromTemplateToFormField(
  templateAdditionalField: {
    name: string;
    type: AdditionalFieldTypes;
    valueJSON: string;
    relatedTo: 'any' | 'contact' | 'organization';
    options: {
      onboardingRequired: boolean;
      isForRegulatedOnly: boolean;
      formDocument: IDocumentForm | undefined;
      documentRequiresCertification: boolean;
      selectOptions: {
        valueJSON: string;
        type: AdditionalFieldTypes;
      }[];
    };
    id: string;
  },
  isRemoved?: boolean,
): AdditionalFieldFormItemModel {
  return {
    name: templateAdditionalField.name,
    type: templateAdditionalField.type,
    value: formatFromJSON(
      templateAdditionalField.type,
      templateAdditionalField.valueJSON,
    ),
    relatedTo: templateAdditionalField.relatedTo,
    options: {
      ...templateAdditionalField.options,
      formDocument: templateAdditionalField.options.formDocument
        ? templateAdditionalField.options.formDocument
        : null,
      selectOptions: templateAdditionalField.options.selectOptions.map(
        (option) => JSON.parse(option.valueJSON),
      ),
    },
    isRemoved: !!isRemoved,
    id: templateAdditionalField.id,
  };
}

export function getNumberOfInitialOnboardingStep(
  onboardingFilledInformation: IInformationFilled,
  stepKeys: Array<keyof IInformationFilled>,
): number {
  const index = stepKeys.findIndex((key) => !onboardingFilledInformation[key]);
  return index !== -1 ? index : stepKeys.length - 1;
}

export function findSocialMediaByType(
  type: SocialMediaTypes,
  socialMedia: ISocialMediaModel[] = [],
) {
  const socialMediaItem = socialMedia.find((el) => el.type == type);
  return socialMediaItem?.path || '';
}

export function getKeyOfRangeByRangeOption(
  option: RangeOptionModel,
  options: Record<string, RangeOptionModel>,
) {
  const { min, max } = option;
  return Object.keys(options).find(
    (key) =>
      (options[key].min === min || (!options[key]?.min && !min)) &&
      (options[key]?.max === max || (!options[key]?.max && !max)),
  );
}

// ------------------ No tests ---------------
export function filterAndMapAdditionalFieldsFromTemplateToFormModel(
  relationshipTemplate: RelationshipTemplate,
  nodeType: OnboardingEntryTypes,
  relationship: Relationship,
): AdditionalFieldFormItemModel[] {
  const relationshipAdditionalFields = [...relationship.additionalFields];
  const result = relationshipTemplate.additionalFields.reduce<
    AdditionalFieldFormItemModel[]
  >((acc, next) => {
    if (next.relatedTo === 'any' || next.relatedTo === nodeType) {
      const predefinedAdditionalFieldIndex =
        relationshipAdditionalFields.findIndex((e) => e._id === next._id);
      const predefinedAdditionalField =
        relationshipAdditionalFields[predefinedAdditionalFieldIndex];
      // Map existed additional fields
      acc.push(
        mapAdditionalFieldFromTemplateToFormField({
          name: next.name,
          type: next.type,
          valueJSON: predefinedAdditionalField
            ? predefinedAdditionalField.valueJSON
            : '',
          relatedTo: next.relatedTo,
          options: {
            onboardingRequired: next.options.onboardingRequired,
            isForRegulatedOnly: next.options.isForRegulatedOnly,
            formDocument: next.options.formDocument,
            documentRequiresCertification:
              next.options.documentRequiresCertification,
            selectOptions: next.options.selectOptions,
          },
          id: next._id,
        }),
      );
      if (predefinedAdditionalFieldIndex !== -1) {
        relationshipAdditionalFields.splice(predefinedAdditionalFieldIndex, 1);
      }
    }

    return acc;
  }, []);
  // Map removed additional fields
  const removedRelationshipFields = relationshipAdditionalFields.map((e) =>
    mapAdditionalFieldFromTemplateToFormField(
      {
        name: e.name,
        type: e.type,
        valueJSON: e.valueJSON,
        relatedTo: 'any',
        options: {
          onboardingRequired: false,
          isForRegulatedOnly: false,
          formDocument: e.metadata?.formDocument,
          documentRequiresCertification:
            !!e.metadata?.documentRequiresCertification,
          selectOptions: e.metadata?.selectOptions || [],
        },
        id: e._id,
      },
      true,
    ),
  );
  result.push(...removedRelationshipFields);
  return result;
}

export function formatExpiringDocumentToPassportFormItemModel(
  document: ExpiringDocument,
) {
  return {
    _id: document._id,
    country: document.details.passportDetails.issuingCountry || '',
    number: document.details.passportDetails.number || '',
    issuedAt: document.details.passportDetails.issuedAt
      ? formatDateToUTC(document.details.passportDetails.issuedAt)
      : null,
    expirationDate: document.expirationDate
      ? formatDateToUTC(document.expirationDate)
      : null,
    review: document.review,
    document: document.details.passportDetails.documents.map((doc) => ({
      id: doc.id,
      name: doc.files[0].name,
      fileId: doc.files[0].id,
      file: null,
    })),
  };
}

export function isKeyRelationshipOwnership(keyRelationship: IKeyRelation) {
  return keyRelationship.options?.additionalFields
    ? Object.keys(keyRelationship.options?.additionalFields).some(
        (key) =>
          typeof keyRelationship.options?.additionalFields[key]?.equalTo !==
          'undefined',
      )
    : undefined;
}

export function getRelationshipName(
  relationship: { childName: string; parentName: string },
  trackType?: RelationshipTrackTypes,
) {
  let result = '';

  if (trackType) {
    result =
      trackType === RelationshipTrackTypes.Parent
        ? relationship.parentName
        : trackType === RelationshipTrackTypes.Child
          ? relationship.childName
          : '';
  } else {
    result = `${relationship.parentName} / ${relationship.childName}`;
  }

  return result;
}

export function getRelationshipLabel(
  relationship: Relationship,
  relTemplate: RelationshipTemplate,
) {
  let result = '';

  const relationshipName = getRelationshipName(
    relTemplate,
    RelationshipTrackTypes.Parent,
  );
  const percentageField = relationship.additionalFields.find(
    ({ type }) => type === AdditionalFieldTypes.Percentage,
  )?.name;

  if (percentageField) {
    const additionalField = relationship.additionalFields.find(
      ({ name }) => name === percentageField,
    );
    const relationshipAdditionalFieldValue = formatFromJSON(
      additionalField?.type || AdditionalFieldTypes.String,
      additionalField?.valueJSON || '""',
    );
    result = `${relationshipAdditionalFieldValue}% - ${relationshipName}`;
  } else {
    result = relationshipName;
  }

  return result;
}
