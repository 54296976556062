import React, { useEffect, useMemo, useState } from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';
import { IEntity } from 'typings/application/entity';
import { ActionKeys } from 'components/Forms/TemplateForms/Onboarding/Components/SubmitButtons';
import { onboardingAPI } from 'api/onboarding/onboardingAPI';
import { formatDateToUTC } from 'helpers/dateHelpers';
import { RangeOptionModel } from 'typings/onboarding/onboarding';
import { onboardingAPIAdapter } from 'apiAdapters/onboarding/onboardingAPIAdapter';
import { OnboardingStatusModel } from 'typings/onboarding/onboarding';
import {
  getEntityNameByNameType,
  findSocialMediaByType,
  getKeyOfRangeByRangeOption,
} from 'helpers/crmHelpers';
import {
  EntityTypes,
  OnboardingEntryTypes,
  SocialMediaTypes,
} from 'enums/onboarding/crm';
import {
  NumberOfEmployeesKeys,
  NumberOfEmployeesOptions,
} from 'components/Forms/FormComponents/SelectInputs/CRM/NumberOfEmployeesSelect';
import {
  NumberOfOfficesKeys,
  NumberOfOfficesOptions,
} from 'components/Forms/FormComponents/SelectInputs/CRM/NumberOfOfficesSelect';

// components
import SetupInitialRelationshipsModal from './SetupInitialRelationshipsModal';
import GeneralInformationForm, {
  FormValuesModel,
} from 'components/Forms/TemplateForms/Onboarding/GeneralInformationForm';
import { Message } from '@ui';

interface IProps {
  onAction: (key: ActionKeys | null) => void;
  onboardingData: OnboardingStatusModel;
  isViewOnly: boolean;
}

const GeneralInformation = ({
  onboardingData,
  onAction,
  isViewOnly,
}: IProps) => {
  const { t } = useTranslation(['common', 'onboarding']);
  const [isInitialRelationshipModal, setIsInitialRelationshipModal] =
    useState(false);

  useEffect(() => {
    const behaveOfSomebodyElse =
      onboardingData?.type === OnboardingEntryTypes.Organization ||
      onboardingData?.behaveOfSombodyElse;

    if (
      behaveOfSomebodyElse &&
      !onboardingData?.informationFilled.applicantRelationshipCreated
    ) {
      setIsInitialRelationshipModal(true);
    }
  }, [onboardingData]);

  const initialFormValues = useMemo<FormValuesModel>(() => {
    if (onboardingData.item) {
      const organizationData = onboardingData.item as IEntity;
      const legalName = getEntityNameByNameType(
        organizationData.names,
        EntityTypes.Legal,
      );
      const tradeName = getEntityNameByNameType(
        organizationData.names,
        EntityTypes.Trader,
      );

      return {
        submitActionType: null,
        isSave: false,
        legalName,
        tradeName,
        dateOfIncorporation: organizationData.dateOfIncorporation
          ? formatDateToUTC(organizationData.dateOfIncorporation)
          : null,
        jurisdiction: organizationData.jurisdiction || '',
        industryCodes: organizationData.industryCodes || [],
        natureOfBusiness: organizationData.natureOfBusiness || '',
        numberOfEmployees: organizationData.numberOfEmployees
          ? (getKeyOfRangeByRangeOption(
              organizationData.numberOfEmployees as RangeOptionModel,
              NumberOfEmployeesOptions,
            ) as NumberOfEmployeesKeys)
          : null,
        numberOfOffices: organizationData.numberOfOffices
          ? (getKeyOfRangeByRangeOption(
              organizationData.numberOfOffices as RangeOptionModel,
              NumberOfOfficesOptions,
            ) as NumberOfOfficesKeys)
          : null,
        registrationNumber: organizationData.registrationNumber,
        socialMedia: {
          websiteLink: organizationData.socialMedia
            ? findSocialMediaByType(
                SocialMediaTypes.WebSite,
                organizationData.socialMedia,
              )
            : '',
          twitterLink: organizationData.socialMedia
            ? findSocialMediaByType(
                SocialMediaTypes.Twitter,
                organizationData.socialMedia,
              )
            : '',
          facebookLink: organizationData.socialMedia
            ? findSocialMediaByType(
                SocialMediaTypes.Facebook,
                organizationData.socialMedia,
              )
            : '',
          linkedInLink: organizationData.socialMedia
            ? findSocialMediaByType(
                SocialMediaTypes.LinkedIn,
                organizationData.socialMedia,
              )
            : '',
        },
      };
    } else {
      return {
        submitActionType: null,
        isSave: false,
        legalName: '',
        tradeName: '',
        dateOfIncorporation: null,
        jurisdiction: '',
        industryCodes: [],
        natureOfBusiness: '',
        numberOfEmployees: null,
        numberOfOffices: null,
        registrationNumber: '',
        socialMedia: {
          websiteLink: '',
          twitterLink: '',
          facebookLink: '',
          linkedInLink: '',
        },
      };
    }
  }, []);

  const handleOnSubmit = async (values: FormValuesModel) => {
    const formattedBody =
      onboardingAPIAdapter.submitApplicantInformationOrganizationStep(values);
    await onboardingAPI.submitApplicantInformationOrganizationStep(
      formattedBody,
      onboardingData.applicationId,
    );
    Message.success(t('success_save'));
    await onAction(values.submitActionType);
  };

  const onSetupInitialRelationships = () => {
    setIsInitialRelationshipModal(false);
  };

  return (
    <>
      <GeneralInformationForm
        title={t('applicant_information.applicant.title', { ns: 'onboarding' })}
        initialFormValues={initialFormValues}
        onSubmit={handleOnSubmit}
        disabled={isViewOnly}
      />
      {isInitialRelationshipModal && !isViewOnly && (
        <SetupInitialRelationshipsModal
          onboardingData={onboardingData}
          onSetup={onSetupInitialRelationships}
        />
      )}
    </>
  );
};

export default GeneralInformation;
