import { useLocation } from 'react-router-dom';
import { findRouteByPath } from 'helpers/routesHelpers';
import { useState, useEffect } from 'react';
import { RouteTitleModel, routes } from 'routes/routes';

const useBrowserTabTitle = () => {
  const location = useLocation();
  const [browserTabTitle, setBrowserTabTitle] =
    useState<RouteTitleModel | null>(null);

  // Updated browser tab title if the current path has been changed
  useEffect(() => {
    const route = findRouteByPath(routes, location.pathname);
    if (!route) {
      setBrowserTabTitle({ key: 'not_found_page_title', ns: 'common' });
    } else {
      setBrowserTabTitle(route.title);
    }
  }, [location.pathname]);

  return browserTabTitle;
};

export default useBrowserTabTitle;
