import { ISessionInfoGeolocation } from 'api/profile/userAPI';

// constants
export const USER_SESSION_DATA = 'USER_SESSION_DATA';

export type SessionFromSessionStorage = {
  geolocation?: ISessionInfoGeolocation;
};

// 1. User Session
export function getUserSession(): SessionFromSessionStorage | null {
  let result: SessionFromSessionStorage | null = null;
  const session = sessionStorage.getItem(USER_SESSION_DATA);
  if (session) {
    const parsedSession = JSON.parse(session);
    result =
      parsedSession && typeof parsedSession === 'object'
        ? (parsedSession as SessionFromSessionStorage)
        : null;
  }
  return result;
}

export function setUserSession(session: SessionFromSessionStorage) {
  sessionStorage.setItem(USER_SESSION_DATA, JSON.stringify(session));
}

export function clearUserSession() {
  sessionStorage.removeItem(USER_SESSION_DATA);
}
