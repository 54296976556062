import React, { useMemo } from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';
import { StateModel } from 'redux/reducers';
import { ActionKeys } from 'components/Forms/TemplateForms/Onboarding/Components/SubmitButtons';
import { useSelector } from 'react-redux';
import { ContactModel } from 'typings/application/contact';
import { FormikHelpers } from 'formik';
import { onboardingAPI } from 'api/onboarding/onboardingAPI';
import { formatDateToUTC } from 'helpers/dateHelpers';
import {
  validatePhoneNumbers,
  findSocialMediaByType,
} from 'helpers/crmHelpers';
import { onboardingAPIAdapter } from 'apiAdapters/onboarding/onboardingAPIAdapter';
import { OnboardingStatusModel } from 'typings/onboarding/onboarding';
import { useVerificationContext } from 'modules/Onboarding/Organization/Steps/ApplicationDocumentation/VerificationContext';
import { FormValuesModel as PhoneNumberFormValuesModel } from '../../../../../../../CRM/PhoneNumberForm';
import { FormValuesModel as EmailAddressFormValuesModel } from '../../../../../../../CRM/EmailAddressForm';

// constants
import { SocialMediaTypes } from 'enums/onboarding/crm';

// components
import { Message } from '@ui';
import ApplicantInformationForm, {
  FormValuesModel,
} from '../../../../../../ApplicantInformationForm';

interface IProps {
  applicationId: string;
  onboardingStatus: OnboardingStatusModel;
  isViewOnly?: boolean;
  onAction?: (isCompleted: boolean, actionType: ActionKeys | null) => void;
}

const ApplicantInformation = ({
  isViewOnly,
  onboardingStatus,
  applicationId,
  onAction,
}: IProps) => {
  const { t } = useTranslation(['common', 'onboarding']);
  const verificationContext = useVerificationContext();
  const currentContactId = useSelector<StateModel, string>(
    (state) => state.auth.contactData?._id as string,
  );

  const initialFormValues = useMemo<FormValuesModel>(() => {
    const item = onboardingStatus.item as ContactModel;

    let emails: EmailAddressFormValuesModel[] = [];
    if (item.emails.length) {
      emails = item.emails.map((e) => {
        return {
          type: e.type,
          value: e.address,
          primary: {
            status: e.isPrimary,
            canEdit: !e.isPrimary,
          },
        };
      });

      const hasPrimary = emails.some((e) => e.primary.status);
      if (!hasPrimary) {
        emails[0].primary.status = true;
      }
    } else {
      emails = [
        {
          type: null,
          value: '',
          primary: {
            status: true,
            canEdit: true,
          },
        },
      ];
    }

    let phoneNumbers: PhoneNumberFormValuesModel[] = [];
    if (item.phoneNumbers.length) {
      phoneNumbers = item.phoneNumbers.map((e) => ({
        type: e.type,
        value: e.number,
        primary: {
          status: e.isPrimary,
          canEdit: !e.isPrimary,
        },
      }));

      const hasPrimary = phoneNumbers.some((e) => e.primary.status);
      if (!hasPrimary) {
        phoneNumbers[0].primary.status = true;
      }
    } else {
      phoneNumbers = [
        {
          type: null,
          value: '',
          primary: {
            status: true,
            canEdit: true,
          },
        },
      ];
    }

    return {
      isSave: false,
      isContactBaseInfoFormDisabled: false,
      displayTooltipsForPhoneAndEmailSections: true,
      submitActionType: null,
      firstName: item.firstName || '',
      middleName: item.middleName || '',
      lastName: item.lastName || '',
      dateOfBirth: item.dateOfBirth ? formatDateToUTC(item.dateOfBirth) : null,
      countryOfBirth: item.countryOfBirth || '',
      gender: item.gender || null,
      emails,
      phoneNumbers,
      socialMedia: {
        twitterLink: item.socialMedia
          ? findSocialMediaByType(SocialMediaTypes.Twitter, item.socialMedia)
          : '',
        facebookLink: item.socialMedia
          ? findSocialMediaByType(SocialMediaTypes.Facebook, item.socialMedia)
          : '',
        linkedInLink: item.socialMedia
          ? findSocialMediaByType(SocialMediaTypes.LinkedIn, item.socialMedia)
          : '',
      },
    };
  }, [onboardingStatus, currentContactId]);

  const handleOnSubmit = async (
    values: FormValuesModel,
    helpers: FormikHelpers<FormValuesModel>,
  ) => {
    if (!isViewOnly) {
      await verificationContext?.handleVerificationTabSubmitWithLoader(
        async () => {
          const errors = await validatePhoneNumbers(
            values.phoneNumbers.map((e) => e.value),
          );
          if (errors) {
            values.phoneNumbers.forEach((phone, index) => {
              if (errors[phone.value]) {
                helpers.setFieldError(
                  `phoneNumbers.${index}.value`,
                  errors[phone.value].errorMessage,
                );
              }
            });
          } else {
            const formattedBody =
              onboardingAPIAdapter.submitApplicantInformationIndividualStep(
                values,
              );
            await onboardingAPI.submitApplicantInformationIndividualStep(
              formattedBody,
              applicationId,
              onboardingStatus.item._id,
            );
            Message.success(t('success_save'));
          }
        },
      );
    }

    onAction && onAction(true, values.submitActionType);
  };

  return (
    <ApplicantInformationForm
      formRef={verificationContext?.refState}
      title={t('applicant_information.general.title', { ns: 'onboarding' })}
      showOnlySaveButton
      disabled={isViewOnly}
      initialFormValues={initialFormValues}
      onSubmit={handleOnSubmit}
    />
  );
};

export default ApplicantInformation;
