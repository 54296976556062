import React, { useMemo } from 'react';

//  helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { StateModel } from 'redux/reducers';
import { useSelector } from 'react-redux';
import { isMessageNew } from 'helpers/messagesHelpers';
import { formatDateToString } from 'helpers/dateHelpers';
import { ThreadWithLastMessageModel } from 'typings/messaging/messages';

// components
import TableActions from '../../../TableActions';
import EllipsisTooltip from '../../../../Tooltips/EllipsisTooltip';
import NewMessageMarker from '../../../../Additional/Messaging/NewMessageMarker';
import { Table, RequiredPropsForTableModel, TableColumnModel } from '@ui';

export type ActionKeys = 'view_thread';

interface IProps
  extends RequiredPropsForTableModel<ThreadWithLastMessageModel> {
  onActionsClick?: (
    key: ActionKeys,
    record: ThreadWithLastMessageModel,
  ) => void;
}

const MessagesTable = ({ onActionsClick, ...rest }: IProps) => {
  const { t } = useTranslation('profile');
  const currentUserId = useSelector<StateModel, string>(
    (state) => state.auth.profileData?._id as string,
  );

  const renderDate = ({ message }: ThreadWithLastMessageModel) => {
    const isNew = isMessageNew(message, currentUserId);

    return (
      <>
        {isNew && <StyledNewMessageMarker />}
        {formatDateToString(message.createdAt)}
      </>
    );
  };

  const renderActions = (record: ThreadWithLastMessageModel) => {
    if (!onActionsClick) {
      return;
    }

    const menuOptions: { key: ActionKeys; name: string }[] = [
      { key: 'view_thread', name: t('messages.view_thread') },
    ];
    return (
      <TableActions
        onSelect={(key: string) => onActionsClick(key as ActionKeys, record)}
        menu={menuOptions}
      />
    );
  };

  const columns: TableColumnModel[] = useMemo(
    () => [
      {
        width: '160px',
        title: t('messages.table.message_date'),
        key: 'createdAt',
        render: renderDate,
      },

      {
        width: '160px',
        title: t('messages.table.user'),
        key: 'user',
        render: (record: ThreadWithLastMessageModel) =>
          record.message.from.name,
      },

      {
        width: '160px',
        title: t('messages.table.category'),
        key: 'category',
        render: (record: ThreadWithLastMessageModel) =>
          t(`messages.message_categories.${record.category}`),
      },
      {
        width: '230px',
        title: t('messages.table.subject'),
        key: 'subject',
        render: (record: ThreadWithLastMessageModel) => (
          <EllipsisTooltip
            title={record.subject}
            overlayStyle={{ maxWidth: '230px' }}
            maxTextContainerWidth="230px"
          >
            {record.subject}
          </EllipsisTooltip>
        ),
      },
      {
        width: '340px',
        title: t('messages.table.message'),
        key: 'message',
        render: (record: ThreadWithLastMessageModel) => (
          <EllipsisTooltip
            title={record.message.body.content}
            overlayStyle={{ maxWidth: '340px' }}
            maxTextContainerWidth="340px"
          >
            {record.message.body.content}
          </EllipsisTooltip>
        ),
      },

      {
        width: '70px',
        key: 'actions',
        render: renderActions,
      },
    ],
    [currentUserId, onActionsClick],
  );

  return <Table {...rest} columns={columns} />;
};

const StyledNewMessageMarker = styled(NewMessageMarker)`
  position: absolute;
  margin-left: -${({ theme }) => theme.marginSm};
  margin-top: 6px;
`;

export default MessagesTable;
