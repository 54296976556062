import { ActionType } from '../actions/userAccess';
import { IReduxAction } from '../../typings/common';
import { PermissionCheckModel } from 'typings/profile/security';
import { ProfilePermissionModel } from '../../typings/profile/profile';
import { StateModel as GlobalStateModel } from './';
import {
  PERMISSION_KEYS,
  PermissionedAccessModel,
  permissionsConfig,
  ALLOWED_FLAGS,
} from '../../config/permissions';

export interface StateModel {
  isInitialized: boolean;
  permissions: Record<PERMISSION_KEYS, UserPermissionModel>;
}

export interface UserPermissionModel extends PermissionedAccessModel {
  isAllowed: boolean;
}

const getInitialUserPermissions = (
  permissionsConfig: Record<PERMISSION_KEYS, PermissionedAccessModel>,
) => {
  const result = {} as Record<PERMISSION_KEYS, UserPermissionModel>;

  Object.keys(permissionsConfig).forEach((key) => {
    // should specify type for key value, since by default forEach set string type
    result[key as PERMISSION_KEYS] = {
      isAllowed: false,
      ...permissionsConfig[key as PERMISSION_KEYS],
    };
  });

  return result;
};

const checkFlagStatus = (flag: ALLOWED_FLAGS, state: GlobalStateModel) => {
  switch (flag) {
    case 'IS_CIRCLE_CONNECTED':
      return state.auth.externalServices.circle !== 'disconnected';

    case 'USER_HAS_CLIENT_GROUPS':
      return !!state.applications.applications?.length;

    case 'USER_WITHOUT_CLIENT_GROUPS':
      return !state.applications.applications?.length;
  }
};

export const initialState: StateModel = {
  isInitialized: false,
  permissions: getInitialUserPermissions(permissionsConfig),
};

// Helpers
const getUserPermissionsHash = (
  userPermissionsList: ProfilePermissionModel[],
): Map<string, ProfilePermissionModel> => {
  return new Map(userPermissionsList.map((e) => [e.name, e]));
};

function hasAllPermissions(
  userPermissions: Map<string, ProfilePermissionModel>,
  expectedPermissions: PermissionCheckModel[] = [],
) {
  return expectedPermissions.every(({ permissionKey }) => {
    return userPermissions.has(permissionKey);
  });
}

function hasSomePermission(
  userPermissions: Map<string, ProfilePermissionModel>,
  expectedPermissions: PermissionCheckModel[] = [],
) {
  return expectedPermissions.some(({ permissionKey }) => {
    return userPermissions.has(permissionKey);
  });
}

const userAccess = (state = initialState, action: IReduxAction): StateModel => {
  const { type, payload } = action;

  switch (type) {
    case ActionType.INIT_USER_PERMISSIONS: {
      const updatedPermissions = {} as Record<
        PERMISSION_KEYS,
        UserPermissionModel
      >;

      // To increase performance of searching permission
      const userPermissionsMap = getUserPermissionsHash(
        payload.userPermissionsList,
      );

      Object.keys(state.permissions).forEach((key) => {
        const { permissions, requiredAllPermissions, flag } =
          state.permissions[key as PERMISSION_KEYS];

        const hasFlagAccess = flag
          ? checkFlagStatus(flag, payload.state)
          : true;
        const hasPermissionAccess = requiredAllPermissions
          ? hasAllPermissions(userPermissionsMap, permissions)
          : hasSomePermission(userPermissionsMap, permissions);

        // should specify type for key value, since by default forEach set string type
        updatedPermissions[key as PERMISSION_KEYS] = {
          ...permissionsConfig[key as PERMISSION_KEYS],
          isAllowed: hasPermissionAccess && hasFlagAccess,
        };
      });

      return { ...state, isInitialized: true, permissions: updatedPermissions };
    }

    case ActionType.CLEAN_UP:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default userAccess;
