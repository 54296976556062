import React, { useMemo } from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';
import ErrorHandlerService from 'services/error-handler/service';
import { Message } from '@ui';
import { userAPI } from 'api/profile/userAPI';
import { RoutePaths } from 'routes/routes';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { userAPIAdapter } from 'apiAdapters/profile/userAPIAdapter';
import {
  setAuthenticationTokens,
  setTimeOffset,
} from 'helpers/localStorageHelpers';
import { formatMinutesAsHumanReadable } from 'helpers/dateHelpers';
import { initializeApp, setInitializedStatus } from 'redux/actions/app';
import {
  setTemporaryToken,
  setTwoFactorVerificationData,
} from 'redux/actions/auth';

// components
import SignInPasswordForm, {
  FormValuesModel,
} from 'components/Forms/TemplateForms/Auth/SignIn/SignInPasswordForm';

interface IProps {
  username: string;
}

const SignIn = ({ username }: IProps) => {
  const { t } = useTranslation(['server_errors', 'common']);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const initialFormValues = useMemo<FormValuesModel>(
    () => ({
      password: '',
    }),
    [],
  );

  const handleSubmit = async (values: FormValuesModel) => {
    const loginData = await userAPIAdapter.login({ ...values, username });

    try {
      const response = await userAPI.login(loginData);

      if (response.serverTime) {
        setTimeOffset(response.serverTime);
      }

      if (response.code === '2FA_required' && response.options) {
        const default2faOption = response.options.find(
          (e) => e.default && e.enabled,
        );
        if (default2faOption) {
          dispatch(
            setTwoFactorVerificationData({
              type: default2faOption.code,
              options: response.options,
            }),
          );
          dispatch(setTemporaryToken(response.jwt));
          navigate(RoutePaths.Auth_Root_StepTwo);
        }
      } else if (response.jwt && response.refreshToken) {
        dispatch(setInitializedStatus(false));
        setAuthenticationTokens(response.jwt, response.refreshToken);
        dispatch(initializeApp());
      }
    } catch (error: any) {
      const errorCode = ErrorHandlerService.getErrorCodeFromError(error);
      const details = ErrorHandlerService.getDetailsObject(error);

      switch (errorCode) {
        case '1204008': {
          Message.error(
            t(errorCode, {
              time: formatMinutesAsHumanReadable(details?.object?.lockedUntil, {
                minute: t('time_units.minute'),
                minutes: t('time_units.minutes'),
                hour: t('time_units.hour'),
                hours: t('time_units.hours'),
              }),
              ns: 'common',
            }),
          );
          break;
        }
        default: {
          ErrorHandlerService.handleError(error);
          return;
        }
      }
    }
  };

  return (
    <SignInPasswordForm
      handleSubmit={handleSubmit}
      initialFormValues={initialFormValues}
    />
  );
};

export default SignIn;
