import React, { useMemo } from 'react';

// helpers
import { OnboardingEntryTypes } from 'enums/onboarding/crm';
import { getFormattedContactName } from 'helpers/crmHelpers';
import { AutocompleteEventOption, AutocompleteProps } from '@ui';
import {
  ClientGroupMemberModel,
  userManagementAPINew,
} from 'api/accountManagement/userManagementAPINew';

// components
import { FormAutocomplete } from '@ui';

export interface RecipientAutocompleteProps
  extends AutocompleteProps<ClientGroupMemberModel> {
  currentUserId: string;
  value?: any[];
}

const RecipientAutocomplete = ({
  currentUserId,
  value,
  onChange,
  ...rest
}: RecipientAutocompleteProps) => {
  const formattedFieldValue = useMemo(() => {
    let result: string[] = [];

    if (value && value.length) {
      result = value.map((e) => e.id);
    }

    return result;
  }, [value]);

  const fetchOptions = async (search: string, page: number, limit: number) => {
    const { data, total } = await userManagementAPINew.fetchClientGroupMembers({
      page,
      limit,
      search,
      type: OnboardingEntryTypes.Contact,
      has_online_access: true,
    });

    const mappedData = data.filter(
      (user) => user.contact.associations?.auth !== currentUserId,
    );

    return {
      total: data.length !== mappedData.length ? total - 1 : total,
      options: mappedData.map((member) => ({
        id: member.contact.associations?.auth,
        label: getFormattedContactName(
          member.contact.firstName,
          member.contact.lastName,
          member.contact.middleName,
        ),
        model: member,
      })),
    };
  };

  const handleChangeRecipients = (
    newOptions: AutocompleteEventOption<ClientGroupMemberModel>[],
  ) => {
    const newValueFormatted = newOptions.reduce<{ id: string; name: string }[]>(
      (acc, next) => {
        if (next.model) {
          acc.push({
            id: next.model.contact.associations?.auth as string,
            name: getFormattedContactName(
              next.model.contact.firstName as string,
              next.model.contact.lastName as string,
              next.model.contact.middleName as string,
            ),
          });
        }

        return acc;
      },
      [],
    );

    onChange && onChange(newValueFormatted as any);
  };

  return (
    <FormAutocomplete
      {...rest}
      mode="multiple"
      value={formattedFieldValue}
      fetchData={fetchOptions}
      onChange={(_, option) =>
        option &&
        handleChangeRecipients(
          option as AutocompleteEventOption<ClientGroupMemberModel>[],
        )
      }
    />
  );
};

export default RecipientAutocomplete;
