import ApiConfig from '../config/api';
import { IUploadFile } from '../typings/documents/documents';
import { generateUniqId } from './commonHelpers';
import { getAccessToken } from './localStorageHelpers';
import { generateFingerprint } from './sessionHelpers';
import { MAX_UPLOAD_DOCUMENT_SIZE, UploadStatus } from '../constants/documents';

export interface IChunkHash {
  [key: string]: IChunkItem;
}

export interface IChunkItem {
  index: number;
  file: Blob;
  status: UploadStatus;
  uploadPercentage: number;
}

export function getFileFromDataURL(dataurl: any, filename: string): File {
  const arr = dataurl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

export function getDownloadDocumentLink(
  documentId: string,
  fileId: string,
  fingerprint: string,
): string {
  const token = getAccessToken();
  return `${ApiConfig.dmsApi}/${documentId}/download/${fileId}?tokenb64=${btoa(`Bearer ${token}`)}&fingerprint=${fingerprint}`;
}

export async function downloadDocumentByURL(
  downloadURL: string,
  fileName: string,
) {
  const link = document.createElement('a');
  link.href = downloadURL;
  link.download = fileName || '';
  link.target = '_blank';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export async function downloadDocument(
  documentId: string,
  fileId: string,
  fileName: string,
) {
  const fingerprint = await generateFingerprint();
  downloadDocumentByURL(
    getDownloadDocumentLink(documentId, fileId, fingerprint),
    fileName,
  );
}

export function verifyFilesThatCanBeUploaded(
  files: File[],
  maxSize = MAX_UPLOAD_DOCUMENT_SIZE,
): {
  approved: File[];
  rejected: { file: File; reason: 'empty' | 'max_size' }[];
} {
  return files.reduce(
    (
      acc: {
        approved: File[];
        rejected: { file: File; reason: 'empty' | 'max_size' }[];
      },
      next,
    ) => {
      if (next.size && next.size <= maxSize) {
        acc.approved.push(next);
      }

      if (!next.size) {
        acc.rejected.push({ file: next, reason: 'empty' });
      }

      if (next.size > maxSize) {
        acc.rejected.push({ file: next, reason: 'max_size' });
      }

      return acc;
    },
    { approved: [], rejected: [] },
  );
}

export function formatFileToLocalDocument(
  file: File,
  tags: string[],
  relatedTo?: string,
): IUploadFile {
  return {
    id: generateUniqId(),
    originalName: file.name,
    name: file.name,
    tags,
    file,
    relatedTo,
  };
}
