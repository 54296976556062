import React, { useEffect, useMemo, useState } from 'react';

// helpers
import moment from 'moment';
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import ErrorHandlerService, {
  ErrorFromServer,
} from 'services/error-handler/service';
import { userAPI } from 'api/profile/userAPI';
import { darkTheme } from 'resources/theme/styled';
import { StateModel } from 'redux/reducers';
import { FormikHelpers } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { StateModel as AuthStateModel } from 'redux/reducers/auth';
import { setActiveApplicationPhoneNumber } from 'redux/actions/applications';
import { USER_TAG_IS_SKIPPED_VERIFICATION_SMS } from 'constants/userTags';
import {
  profileAPI,
  UpdatePhoneNumberResponseModel,
} from 'api/profile/profileAPI';
import { FormValuesModel as EditPhoneFormValuesModel } from 'components/ModalDialogs/TemplateModalDialogs/Auth/EditPhoneModal';
import {
  setIsPhoneVerifiedStatus,
  setPrimaryPhoneNumber,
  setUserTags,
  signOut,
} from 'redux/actions/auth';
import {
  getAttemptsOfResendingSmsOTP,
  setAttemptsOfResendingSmsOTP,
} from 'helpers/localStorageHelpers';

// components
import { Text } from '@ui';
import { Link } from '@ui';
import ResendOtp from 'components/Additional/ResendOtp';
import { AsyncButton } from '@ui';
import AuthContainer from '../../../components/Additional/AuthContainer';
import EditPhoneModal from './EditPhoneModal';
import LoadingWrapper from 'components/WrapperComponents/LoadingWrapper';
import VerifyPhoneNumberForm, {
  FormValuesModel,
} from 'components/Forms/TemplateForms/Onboarding/VerifyPhoneNumberForm';

const PhoneVerification = () => {
  const { t } = useTranslation('auth');
  const dispatch = useDispatch();
  const attemptsOfResendingSmsOTP = Number(getAttemptsOfResendingSmsOTP());
  const [isEditing, setEditing] = useState(false);
  const [amountOfResendAttempts, setAmountOfResendAttempts] = useState(
    attemptsOfResendingSmsOTP,
  );
  const { contactData, profileData } = useSelector<StateModel, AuthStateModel>(
    (state) => state.auth,
  );

  const [timerConfig, setTimerConfig] = useState<{
    isInitialized: boolean;
    timerTillDate: Date | null;
  }>({
    isInitialized: false,
    timerTillDate: null,
  });

  const profilePhone = useMemo(() => contactData?.phoneNumber, [contactData]);

  const initialFormValues: FormValuesModel = useMemo(
    () => ({ code: '', phoneNumber: profilePhone?.number }),
    [profilePhone],
  );

  const editPhoneNumberFormValues: EditPhoneFormValuesModel = useMemo(
    () => ({ phone: profilePhone?.number || '' }),
    [profilePhone?.number],
  );

  const sendSMS = async (number: string) => {
    const response = await profileAPI.resendPhoneNumberOTP(number as string);
    setTimerConfig({
      isInitialized: true,
      timerTillDate: new Date(response.verification.verificationExpiresAt),
    });
  };

  useEffect(() => {
    if (profilePhone?.number) {
      if (attemptsOfResendingSmsOTP >= 2) {
        // When user made 2 resend attempts then we don't need to show the timer
        // Because user will be able to only skip verification process
        setTimerConfig({ isInitialized: true, timerTillDate: null });
      } else {
        const smsSendDate = profilePhone?.verification?.verificationExpiresAt;

        // Case when the sms was not sent yet (basically it's right after sign up)
        if (!smsSendDate) {
          sendSMS(profilePhone.number);
        } else {
          const difference = moment(smsSendDate).diff(moment(), 'seconds');
          // OTP already expired, resend email and start the timer
          if (difference <= 0) {
            sendSMS(profilePhone.number);
          } else {
            // OTP code is still alive, just continue timer
            setTimerConfig({
              isInitialized: true,
              timerTillDate: new Date(smsSendDate),
            });
          }
        }
      }
    }
  }, [profilePhone]);

  const handleSubmit = async ({ code }: FormValuesModel) => {
    await profileAPI.verifyPhoneNumber(code, profilePhone?.number as string);
    dispatch(setIsPhoneVerifiedStatus(true));
  };

  const resendOTP = async () => {
    await sendSMS(profilePhone?.number as string);
    const newAmountOfResendingAttempts = amountOfResendAttempts + 1;
    setAmountOfResendAttempts(newAmountOfResendingAttempts);
    setAttemptsOfResendingSmsOTP(newAmountOfResendingAttempts);
  };

  const handleSkip = async () => {
    await userAPI.setUserTags([USER_TAG_IS_SKIPPED_VERIFICATION_SMS]);
    dispatch(
      setUserTags([
        ...(profileData?.tags as string[]),
        USER_TAG_IS_SKIPPED_VERIFICATION_SMS,
      ]),
    );
    setAttemptsOfResendingSmsOTP(0);
  };

  const handleEditModalClose = (
    wasSubmitted?: boolean,
    response?: UpdatePhoneNumberResponseModel,
  ) => {
    setEditing(false);
    if (wasSubmitted && response) {
      dispatch(setPrimaryPhoneNumber(response.number, response.verification));
      dispatch(setActiveApplicationPhoneNumber(response.number));
    }
  };

  const handleOnSubmitError = (
    error: ErrorFromServer,
    _: FormValuesModel,
    formikHelpers: FormikHelpers<FormValuesModel>,
  ) => {
    const errorCode = ErrorHandlerService.getErrorCodeFromError(error);

    switch (errorCode) {
      case '1005006': {
        formikHelpers.setFieldError(
          'code',
          t('1005006', { ns: 'server_errors' }),
        );
        break;
      }

      default: {
        ErrorHandlerService.handleError(error);
        break;
      }
    }
  };

  return (
    <AuthContainer
      marginTop="50px"
      footerText={
        <StyledLinkWrapper>
          <Link onClick={() => dispatch(signOut())}>
            {t('phone_verification.sign_out')}
          </Link>
        </StyledLinkWrapper>
      }
    >
      <div>
        <Title variant="h5" gutterBottom>
          {t('phone_verification.title')}
        </Title>
      </div>
      <div>
        <LoadingWrapper loading={!timerConfig.isInitialized}>
          <VerifyPhoneNumberForm
            initialValues={initialFormValues}
            onSubmit={handleSubmit}
            onSubmitError={handleOnSubmitError}
            handleEditModalOpen={() => setEditing(true)}
          />
          <ResendOtp
            timeout={0}
            startTimerFromDate={timerConfig?.timerTillDate ?? undefined}
            successMessage={t('phone_verification.resend_success')}
            resendOtpCallback={resendOTP}
            renderResendButton={
              amountOfResendAttempts < 2
                ? undefined
                : () => (
                    <StyledAsyncButton
                      type="bordered"
                      fullWidth
                      size="large"
                      onClick={handleSkip}
                    >
                      {t('phone_verification.skip_button')}
                    </StyledAsyncButton>
                  )
            }
          />
          <StyledText gutterTop color={darkTheme.colorLightD1}>
            {t(
              'phone_verification.verification_can_be_skip_after_two_attempts',
            )}
          </StyledText>
        </LoadingWrapper>
      </div>
      <EditPhoneModal
        isVisible={isEditing}
        closeCallback={handleEditModalClose}
        initialFormValues={editPhoneNumberFormValues}
      />
    </AuthContainer>
  );
};

const Title = styled(Text)`
  display: block;
  text-align: center;
`;

const StyledLinkWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${({ theme }) => theme.marginXs};
`;

const StyledAsyncButton = styled(AsyncButton)`
  margin-top: ${({ theme }) => theme.marginSm};
`;

const StyledText = styled(Text)`
  font-style: italic;
`;

export default PhoneVerification;
