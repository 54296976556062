import React, { useCallback } from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';
import moment, { Moment } from 'moment';
import { styled } from 'styled-components';
import { debounce } from 'lodash';
import { generateUniqId } from 'helpers/commonHelpers';
import { AutocompleteOption, Col, Row } from '@ui';
import { FieldArray, FieldArrayRenderProps, useFormikContext } from 'formik';
import {
  FetchTransactionBalancesPayload,
  transfersAPI,
} from 'api/accounting/transfersAPI';
import {
  convertAmountFromBigIntToInt,
  convertAmountFromIntToBigInt,
} from 'helpers/accountsHelpers';

// components
import ContentCell from './ContentCell';
import InfoTooltip from 'components/Tooltips/InfoTooltip';
import DivAlignCenter from 'components/Additional/DivAlignCenter';
import FormErrorTooltip from 'components/Forms/FormErrorTooltip';
import { AddButton, TableAntd } from '@ui';

export type TransactionModel = {
  customId: string;
  from: string | null;
  balance: {
    actual: number;
    available: number | null;
  } | null;
  purpose: string;
  amount: number | null;
  currencyId: number | null;
  currency: string | null;
  valueDate: Moment | null;
  fromReference: string;
  to: string | null;
  toFieldLabel: string | null;
  toInitialOption?: AutocompleteOption;
  toReference: string;
};

export type FormValuesModel = {
  transactions: TransactionModel[];
};

const MAX_TRANSACTIONS_LIMIT = 200;

const columns = [
  {
    width: '50px',
    key: 'index',
    columnKey: 'index',
  },

  {
    width: '285px',
    title: 'international_transfer.submit_multiple_grid.from',
    key: 'from',
    columnKey: 'from',
    fieldNameSuffix: 'from',
  },

  {
    width: '200px',
    title: 'international_transfer.submit_multiple_grid.balance',
    titleContent:
      'international_transfer.submit_multiple_grid.available_balance_info',
    key: 'available_balance',
    columnKey: 'available_balance',
    fieldNameSuffix: 'balance',
  },

  {
    width: '285px',
    title: 'international_transfer.submit_multiple_grid.purpose',
    key: 'purpose',
    columnKey: 'purpose',
    fieldNameSuffix: 'purpose',
  },

  {
    width: '200px',
    title: 'international_transfer.submit_multiple_grid.amount',
    key: 'amount',
    columnKey: 'amount',
    fieldNameSuffix: 'amount',
  },

  {
    width: '200px',
    title: 'international_transfer.submit_multiple_grid.currency',
    key: 'currency',
    columnKey: 'currency',
    fieldNameSuffix: 'currency',
  },

  {
    width: '200px',
    title: 'international_transfer.submit_multiple_grid.value_date',
    key: 'valueDate',
    columnKey: 'valueDate',
    fieldNameSuffix: 'valueDate',
  },

  {
    width: '285px',
    title: 'international_transfer.submit_multiple_grid.from_reference',
    key: 'fromReference',
    columnKey: 'fromReference',
    fieldNameSuffix: 'fromReference',
  },

  {
    width: '285px',
    title: 'international_transfer.submit_multiple_grid.to',
    key: 'to',
    columnKey: 'to',
    fieldNameSuffix: 'to',
  },

  {
    width: '285px',
    title: 'international_transfer.submit_multiple_grid.to_reference',
    key: 'to_reference',
    columnKey: 'toReference',
    fieldNameSuffix: 'toReference',
  },

  {
    key: 'delete',
    columnKey: 'delete',
    fieldNameSuffix: 'delete',
  },
] as const;

export type ColumnKeys = (typeof columns)[number]['columnKey'];

interface IProps {
  viewOnly?: boolean;
}

const WireTransferGridForm = ({ viewOnly }: IProps) => {
  const { t } = useTranslation('transfers');
  const { values, isSubmitting, setSubmitting, setValues } =
    useFormikContext<FormValuesModel>();

  const updateAvailableBalances = async (
    newTransactions: TransactionModel[],
  ) => {
    const formattedTransactions =
      newTransactions.reduce<FetchTransactionBalancesPayload>((acc, curr) => {
        if (curr.from) {
          acc.push({
            sequenceNumber: curr.customId,
            fromAccountNumber: curr.from,
            currencyId: curr.currencyId as number,
            amount: curr.amount ? convertAmountFromIntToBigInt(curr.amount) : 0,
          });
        }

        return acc;
      }, []);

    setSubmitting(true);

    const response = await transfersAPI.fetchBalancesForWireTransfers(
      formattedTransactions,
    );

    const updatedTransactions = newTransactions.map((transaction) => {
      const updatedTransaction = response.find(
        (responseTransaction) =>
          responseTransaction.sequenceNumber === transaction.customId,
      );

      if (updatedTransaction) {
        return {
          ...transaction,
          balance: transaction.balance
            ? {
                ...transaction.balance,
                available: convertAmountFromBigIntToInt(
                  updatedTransaction.availableBalance,
                ),
              }
            : null,
        };
      }

      return transaction;
    });

    setValues({ transactions: updatedTransactions });
    setSubmitting(false);
  };

  const debouncedUpdateAvailableBalances = useCallback(
    debounce(updateAvailableBalances, 300),
    [],
  );

  const deleteRow = (rowIndex: number) => {
    const arrayCopy = [...values.transactions];
    arrayCopy.splice(rowIndex, 1);
    setValues({ transactions: arrayCopy });
    if (arrayCopy.length > 0) {
      debouncedUpdateAvailableBalances(arrayCopy);
    }
  };

  const generateTableColumns = () => {
    return columns.reduce((columnsArray: any, col: any) => {
      columnsArray.push({
        ...col,
        title:
          col.title && col.titleContent ? (
            <StyledDivAlignCenter>
              {t(col.title)}
              <InfoTooltip tooltipContent={t(col.titleContent)} />
            </StyledDivAlignCenter>
          ) : col.title ? (
            t(col.title)
          ) : (
            ''
          ),
        onCell: (_record: any, rowIndex: number) => ({
          viewOnly,
          rowIndex,
          onDelete: () => deleteRow(rowIndex),
          columnKey: col.columnKey,
          fieldName: col.fieldNameSuffix
            ? `transactions.${rowIndex}.${col.fieldNameSuffix}`
            : undefined,
        }),
      });
      return columnsArray;
    }, []);
  };

  const newTransaction: TransactionModel = {
    customId: generateUniqId(),
    from: null,
    balance: null,
    purpose: '',
    amount: null,
    currencyId: null,
    currency: null,
    valueDate: moment(),
    fromReference: '',
    to: null,
    toFieldLabel: null,
    toReference: '',
  };

  const renderAddButton = useCallback(
    (fieldProps: FieldArrayRenderProps) => {
      if (viewOnly) {
        return null;
      }
      if (values.transactions.length === MAX_TRANSACTIONS_LIMIT) {
        return (
          <Row>
            <Col>
              <InfoTooltip
                tooltipContent={t(
                  'international_transfer.submit_multiple_grid.max_add_transaction_limit',
                  { limit: MAX_TRANSACTIONS_LIMIT },
                )}
              >
                <div>
                  <StyledAddButton
                    disabled
                    onClick={() => fieldProps.push(newTransaction)}
                  >
                    {t(
                      'international_transfer.submit_multiple_grid.add_transaction_button',
                    )}
                  </StyledAddButton>
                </div>
              </InfoTooltip>
            </Col>
          </Row>
        );
      } else {
        return (
          <StyledAddButton
            disabled={isSubmitting}
            onClick={() => fieldProps.push(newTransaction)}
          >
            {t(
              'international_transfer.submit_multiple_grid.add_transaction_button',
            )}
          </StyledAddButton>
        );
      }
    },
    [newTransaction, isSubmitting],
  );

  return (
    <FieldArray
      name="transactions"
      render={(props) => (
        <>
          <TableAntd
            bordered
            scroll={{ x: 'max-content' }}
            size="small"
            columns={generateTableColumns()}
            dataSource={values.transactions}
            components={{ body: { cell: ContentCell } }}
            pagination={false}
          />
          <FormErrorTooltip<FormValuesModel> errorKey="transactions" />
          {renderAddButton(props)}
        </>
      )}
    />
  );
};

const StyledAddButton = styled(AddButton)`
  margin-top: ${({ theme }) => theme.marginSm};
`;

const StyledDivAlignCenter = styled(DivAlignCenter)`
  gap: ${({ theme }) => theme.marginXXs};
`;

export default WireTransferGridForm;
