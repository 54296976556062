import { COUNTRY_CODES } from 'constants/countryCodes';
import { JournalEntryFromQuery } from 'api/accounting/transactionsAPI';
import { WireTransactionSenderData } from 'components/Additional/DetailsSections/Accounting/WireTransaction/Sender';
import {
  formatTimestampDateToString,
  formatTimestampDateToUTCString,
} from 'helpers/dateHelpers';
import { WireTransactionRecipientData } from 'components/Additional/DetailsSections/Accounting/WireTransaction/Recipient';
import { InternalTransactionSenderData } from 'components/Additional/DetailsSections/Accounting/InternalTransaction/Sender';
import { InternalTransactionRecipientData } from 'components/Additional/DetailsSections/Accounting/InternalTransaction/Recipient';
import {
  TransactionData,
  TransactionTypes,
} from 'components/ModalDialogs/TemplateModalDialogs/Accounts/TransactionDetailsModalDialog';
import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_DATE_TIME_FORMAT,
} from 'constants/global';
import {
  TransactionSourceTypes,
  TransferApprovalStatuses,
} from 'enums/accounting/transfers';
import {
  convertAmountBigIntToLocaleString,
  getDistrict,
} from 'helpers/accountsHelpers';

function getTransactionTypeByJournalEntry(
  data: JournalEntryFromQuery,
): TransactionTypes | null {
  switch (data.sourceType) {
    case TransactionSourceTypes.InternalTransfer:
      return data.isSender ? 'internal-sender' : 'internal-recipient';

    case TransactionSourceTypes.WireTransfer:
      return data.isSender ? 'wire-sender' : 'wire-recipient';

    default:
      return null;
  }
}

function getWireTransferTemplateData(data: JournalEntryFromQuery) {
  const {
    beneficiaryCustomer,
    templateId,
    templateName,
    isTemplateAccessible,
  } = data;

  return {
    templateId,
    templateName,
    canAccessTemplate: isTemplateAccessible,
    beneficiaryAccountName: beneficiaryCustomer.accountName,
    beneficiaryAccountNumber: beneficiaryCustomer.accountNumber,
    beneficiaryCountry: beneficiaryCustomer.countryCode
      ? COUNTRY_CODES[beneficiaryCustomer.countryCode]
      : '',
    beneficiaryAddress: beneficiaryCustomer.address1,
    beneficiaryCity: beneficiaryCustomer.city,
    beneficiaryDistrict: getDistrict(
      beneficiaryCustomer.countryCode,
      beneficiaryCustomer.district,
    ),
    beneficiaryPostCode: beneficiaryCustomer.postCode,
    beneficiaryBankCode: beneficiaryCustomer.accountWithInstitution?.code || '',
    beneficiaryBankName: beneficiaryCustomer.accountWithInstitution?.name || '',
    beneficiaryBankCodeTypeName:
      beneficiaryCustomer.accountWithInstitution?.codeType || '',
    beneficiaryBankAddress:
      beneficiaryCustomer.accountWithInstitution?.address1 || '',
    beneficiaryBankCity: beneficiaryCustomer.accountWithInstitution?.city || '',
    beneficiaryBankPostCode:
      beneficiaryCustomer.accountWithInstitution?.postCode || '',
    beneficiaryBankCountry: beneficiaryCustomer.accountWithInstitution
      ?.countryCode
      ? COUNTRY_CODES[beneficiaryCustomer.accountWithInstitution?.countryCode]
      : '',
    beneficiaryBankDistrict: getDistrict(
      beneficiaryCustomer.accountWithInstitution?.countryCode || '',
      beneficiaryCustomer.accountWithInstitution?.district || '',
    ),
    intermediaryBankCode:
      beneficiaryCustomer.intermediaryInstitution?.code || '',
    intermediaryBankName:
      beneficiaryCustomer.intermediaryInstitution?.name || '',
    intermediaryBankCodeTypeName:
      beneficiaryCustomer.intermediaryInstitution?.codeType || '',
    intermediaryBankAddress:
      beneficiaryCustomer.intermediaryInstitution?.address1 || '',
    intermediaryBankCity:
      beneficiaryCustomer.intermediaryInstitution?.city || '',
    intermediaryBankPostCode:
      beneficiaryCustomer.intermediaryInstitution?.postCode || '',
    intermediaryBankDistrict: getDistrict(
      beneficiaryCustomer.intermediaryInstitution?.countryCode || '',
      beneficiaryCustomer.intermediaryInstitution?.district || '',
    ),
    intermediaryBankCountry: beneficiaryCustomer.intermediaryInstitution
      ?.countryCode
      ? COUNTRY_CODES[beneficiaryCustomer.intermediaryInstitution?.countryCode]
      : '',
    reference: beneficiaryCustomer.beneficiaryReference,
  };
}

function getFormattedTransaction(
  type: TransactionTypes,
  data: JournalEntryFromQuery,
):
  | InternalTransactionSenderData
  | InternalTransactionRecipientData
  | WireTransactionSenderData
  | WireTransactionRecipientData
  | null {
  switch (type) {
    case 'internal-sender': {
      const {
        sourceType,
        purpose,
        orderingCustomer,
        beneficiaryCustomer,
        currencyIsoCode,
        amount,
        postingDate,
        valueDate,
        requestedByName,
        statusId,
        templateId,
        templateName,
        relatedDocuments,
        workflowExpirationDate,
        transactionNumber,
        isTemplateAccessible,
      } = data;
      const address = [
        orderingCustomer.address1,
        orderingCustomer.address2,
        orderingCustomer.address3,
      ]
        .filter(Boolean)
        .join(', ');

      return {
        purpose,
        type: sourceType,
        status: statusId,
        workflowExpiresAt: workflowExpirationDate,
        template: templateId
          ? {
              id: templateId,
              name: templateName,
              canAccessTemplate: isTemplateAccessible,
            }
          : null,
        amount: `${currencyIsoCode} ${convertAmountBigIntToLocaleString(amount)}`,
        valueDate: formatTimestampDateToUTCString(
          valueDate,
          DEFAULT_DATE_FORMAT,
        ),
        postingDate: formatTimestampDateToString(
          postingDate,
          DEFAULT_DATE_TIME_FORMAT,
        ),
        requestedBy: requestedByName,
        relatedDocuments: relatedDocuments,
        transactionNumber,
        orderingCustomer: {
          address,
          accountName: orderingCustomer.accountName,
          accountNumber: orderingCustomer.accountNumber,
          reference: orderingCustomer.senderReference,
        },
        beneficiary: {
          accountName: beneficiaryCustomer.accountName,
          accountNumber: beneficiaryCustomer.accountNumber,
          reference: beneficiaryCustomer.beneficiaryReference,
        },
      };
    }

    case 'internal-recipient': {
      const {
        sourceType,
        orderingCustomer,
        beneficiaryCustomer,
        currencyIsoCode,
        amount,
        postingDate,
        valueDate,
        relatedDocuments,
        transactionNumber,
      } = data;

      return {
        type: sourceType,
        amount: `${currencyIsoCode} ${convertAmountBigIntToLocaleString(amount)}`,
        valueDate: formatTimestampDateToUTCString(
          valueDate,
          DEFAULT_DATE_FORMAT,
        ),
        postingDate: formatTimestampDateToString(
          postingDate,
          DEFAULT_DATE_TIME_FORMAT,
        ),
        relatedDocuments: relatedDocuments,
        transactionNumber,
        orderingCustomer: {
          accountName: orderingCustomer.accountName,
          accountNumber: orderingCustomer.accountNumber,
        },
        beneficiary: {
          accountName: beneficiaryCustomer.accountName,
          accountNumber: beneficiaryCustomer.accountNumber,
          reference: beneficiaryCustomer.beneficiaryReference,
        },
      };
    }

    case 'wire-sender': {
      const {
        amount,
        purpose,
        statusId,
        valueDate,
        sourceType,
        postingDate,
        senderReference,
        requestedByName,
        currencyIsoCode,
        orderingCustomer,
        relatedDocuments,
        workflowExpirationDate,
        transactionNumber,
      } = data;
      const address = [
        orderingCustomer.address1,
        orderingCustomer.address2,
        orderingCustomer.address3,
      ]
        .filter(Boolean)
        .join(', ');

      return {
        purpose,
        type: sourceType,
        status: statusId,
        workflowExpiresAt: workflowExpirationDate,
        amount: `${currencyIsoCode} ${convertAmountBigIntToLocaleString(amount)}`,
        valueDate: formatTimestampDateToUTCString(
          valueDate,
          DEFAULT_DATE_FORMAT,
        ),
        postingDate: formatTimestampDateToString(
          postingDate,
          DEFAULT_DATE_TIME_FORMAT,
        ),
        requestedBy: requestedByName,
        relatedDocuments: relatedDocuments,
        template: getWireTransferTemplateData(data),
        transactionNumber,
        senderReference,
        orderingCustomer: {
          address,
          accountName: orderingCustomer.accountName,
          accountNumber: orderingCustomer.accountNumber,
          reference: orderingCustomer.senderReference,
        },
      };
    }

    case 'wire-recipient': {
      const {
        sourceType,
        beneficiaryCustomer,
        orderingCustomer,
        currencyIsoCode,
        amount,
        postingDate,
        valueDate,
        relatedDocuments,
        transactionNumber,
        repaired,
      } = data;
      const address = [
        orderingCustomer.address1,
        orderingCustomer.address2,
        orderingCustomer.address3,
      ]
        .filter(Boolean)
        .join(', ');

      return {
        type: sourceType,
        amount: `${currencyIsoCode} ${convertAmountBigIntToLocaleString(amount)}`,
        valueDate: formatTimestampDateToUTCString(
          valueDate,
          DEFAULT_DATE_FORMAT,
        ),
        postingDate: formatTimestampDateToString(
          postingDate,
          DEFAULT_DATE_TIME_FORMAT,
        ),
        relatedDocuments: relatedDocuments,
        template: getWireTransferTemplateData(data),
        transactionNumber,
        repairedData: repaired
          ? {
              accountName: beneficiaryCustomer.repairedBeneficiaryAccountName,
              accountNumber:
                beneficiaryCustomer.repairedBeneficiaryAccountNumber,
            }
          : null,
        orderingCustomer: {
          address,
          accountName: orderingCustomer.accountName,
          accountNumber: orderingCustomer.accountNumber,
          reference: orderingCustomer.senderReference,
        },
      } as WireTransactionRecipientData;
    }

    default:
      return null;
  }
}

const transactionsAdapter = {
  formatJournalEntryForTransactionDetailsModal: (
    data: JournalEntryFromQuery,
  ): TransactionData => {
    const type = getTransactionTypeByJournalEntry(data);
    const transaction = type ? getFormattedTransaction(type, data) : null;

    return {
      type,
      transaction,
      sourceType: data.sourceType,
      requestorId: data.requestedById,
      transactionStatus: data.statusId as TransferApprovalStatuses,
      transactionId: null,
      journalEntryId: null,
      approvalWorkflowId: null,
      transactionApprovals: null,
    };
  },
};

export { transactionsAdapter };
