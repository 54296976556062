import React, { useEffect, useMemo, useState } from 'react';

// helpers
import styled from 'styled-components';
import useFetch from 'hooks/useFetch';
import useTranslation from 'hooks/useTranslation';
import { colorsTheme } from 'resources/theme/styled/colors';
import { generateUniqId } from 'helpers/commonHelpers';
import { useFormikContext } from 'formik';
import { DISABLED_FORM_STATUS } from 'constants/form';
import { accountManagementRulesAPI } from 'api/accountManagement/accountManagementRulesAPI';
import {
  CreateUpdateOnboardingApprovalRule,
  onboardingAPI,
  OnboardingApprovalRuleFromQuery,
} from 'api/onboarding/onboardingAPI';

// components
import DivAlignCenter from 'components/Additional/DivAlignCenter';
import LoadingWrapper from 'components/WrapperComponents/LoadingWrapper';
import ApprovalRuleMatrix, {
  ApprovalRuleMatrixFormValuesModel,
} from './ApprovalRuleMatrix';
import { Text, IconSVG, Message } from '@ui';
import { ReactComponent as ErrorIcon } from 'resources/icons/remix-icons/close-circle-line.svg';

interface IProps {
  onAction: () => void;
}

const ApprovalRules = ({ onAction }: IProps) => {
  const { t } = useTranslation('onboarding');
  const [updateApprovalRuleTrigger, updateApprovalRule] = useState({});
  const { status } = useFormikContext();
  const isParentFormDisabled = status == DISABLED_FORM_STATUS;
  const [isRuleInvalid, setIsRuleInvalid] = useState(false);

  const { response: approvalRules } = useFetch(
    () =>
      onboardingAPI.fetchApprovalRules(
        {
          page: 1,
          limit: 1,
        },
        'onboarding-account-management',
      ),
    [updateApprovalRuleTrigger],
  );

  const { response: approvalRuleTriggerTypes } = useFetch(
    () => accountManagementRulesAPI.fetchApprovalRuleTriggerTypes(),
    [],
  );

  const initialFormValues =
    useMemo<ApprovalRuleMatrixFormValuesModel | null>(() => {
      if (!approvalRuleTriggerTypes || !approvalRules) {
        return null;
      }

      if (!approvalRules.data.length) {
        const actionStatuses: { [key: number]: boolean } = {};
        approvalRuleTriggerTypes.forEach(
          ({ id }) => (actionStatuses[id] = true),
        );

        const initialApprovalRule = {
          actionStatuses,
          approvalGroupConditions: [
            {
              customUniqId: generateUniqId(),
              approvalGroupId: null,
              requiredApprovers: null,
              maximumRequiredApprovers: 0,
            },
          ],
        };

        return {
          id: null,
          approvalRuleType: 'onboarding-account-management',
          isInitialTemplate: true,
          approvalConditions: [initialApprovalRule],
        };
      } else {
        const approvalRule = approvalRules
          .data[0] as OnboardingApprovalRuleFromQuery;

        const approvalConditions = approvalRule.ruleSets.map((ruleSet) => {
          const approvalGroupConditions = ruleSet.items.map((item) => ({
            customUniqId: item.group.id,
            approvalGroupId: item.group.id,
            requiredApprovers:
              item.group.participants.length < item.minimumThreshold
                ? null
                : item.minimumThreshold,
            maximumRequiredApprovers: item.group.participants.length,
            initialAutocompleteValue: {
              id: item.group.id,
              label: item.group.name,
            },
          }));

          const actionStatuses: { [key: number]: boolean } = {};
          approvalRuleTriggerTypes.forEach(
            (e) => (actionStatuses[e.id] = true),
          );

          return {
            actionStatuses,
            approvalGroupConditions,
          };
        });

        return {
          id: approvalRule._id,
          approvalRuleType: 'onboarding-account-management',
          isInitialTemplate: false,
          approvalConditions,
        };
      }
    }, [approvalRules, approvalRuleTriggerTypes]);

  useEffect(() => {
    if (!approvalRules) {
      isRuleInvalid && setIsRuleInvalid(false);
    } else if (approvalRules.data.length) {
      setIsRuleInvalid(!!approvalRules.data[0].validationErrors?.length);
    }
  }, [approvalRules]);

  const handleSubmit = async (values: ApprovalRuleMatrixFormValuesModel) => {
    const formattedData: CreateUpdateOnboardingApprovalRule = {
      type: 'onboarding-account-management',
      ruleSets: values.approvalConditions.map((e) => ({
        items: e.approvalGroupConditions.map((c) => ({
          groupId: c.approvalGroupId as string,
          minimumThreshold: c.requiredApprovers as number,
        })),
      })),
      criteriaList: Object.keys(
        values.approvalConditions[0].actionStatuses,
      ).map((actionKey) => {
        const activeSetIndices = values.approvalConditions.reduce<number[]>(
          (acc, next, conditionIndex) => {
            if (next.actionStatuses[+actionKey]) {
              acc.push(conditionIndex);
            }
            return acc;
          },
          [],
        );

        return {
          type: 'key',
          key: actionKey,
          activeSetIndices,
        };
      }),
    };

    if (values.id) {
      await onboardingAPI.updateApprovalRuleById(values.id, formattedData);
      Message.success(
        t(
          'administration.administration_rules.approval_rules.edit.success_message',
        ),
      );
      isRuleInvalid && setIsRuleInvalid(false);
    } else {
      await onboardingAPI.createApprovalRule(formattedData);
      Message.success(
        t(
          'administration.administration_rules.approval_rules.new.success_message',
        ),
      );
      updateApprovalRule({});
    }

    onAction();
  };

  return (
    <LoadingWrapper loading={!initialFormValues}>
      <Text
        weight="semi-bold"
        color={colorsTheme.colorWhite}
        variant="h5"
        gutterBottom
      >
        {t('administration.administration_rules.approval_rules.title')}
      </Text>
      <Text gutterBottom>
        {t('administration.administration_rules.approval_rules.description')}
      </Text>
      {isRuleInvalid ? (
        <DivAlignCenter>
          <StyledIconSVG color={colorsTheme.colorError} component={ErrorIcon} />
          <Text color={colorsTheme.colorError}>
            {t(
              'administration.administration_rules.approval_rules.invalid_approval_rule',
            )}
          </Text>
        </DivAlignCenter>
      ) : null}
      <ApprovalRuleMatrix
        disabled={isParentFormDisabled}
        initialValues={initialFormValues}
        onSubmit={handleSubmit}
        approvalRuleTriggerTypes={approvalRuleTriggerTypes || []}
      />
    </LoadingWrapper>
  );
};

const StyledIconSVG = styled(IconSVG)`
  margin-right: ${({ theme }) => theme.marginXXs};
`;

export default ApprovalRules;
