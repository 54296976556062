import React, { useMemo } from 'react';

// helpers
import useFetch from 'hooks/useFetch';
import useTranslation from 'hooks/useTranslation';
import { ActionKeys } from 'components/Forms/TemplateForms/Onboarding/Components/SubmitButtons';
import { ContactModel } from 'typings/application/contact';
import { onboardingAPI } from 'api/onboarding/onboardingAPI';
import { formatDateToUTC } from 'helpers/dateHelpers';
import { SocialMediaTypes } from 'enums/onboarding/crm';
import { onboardingAPIAdapter } from 'apiAdapters/onboarding/onboardingAPIAdapter';
import { findSocialMediaByType } from 'helpers/crmHelpers';

// components
import LoadingWrapper from 'components/WrapperComponents/LoadingWrapper';
import ApplicantInformationForm, {
  FormValuesModel,
} from 'components/Forms/TemplateForms/Onboarding/ApplicantInformationForm';
import { Message } from '@ui';

interface IProps {
  onAction: (key: ActionKeys | null) => void;
  isViewOnly: boolean;
  onboardingData: {
    contactId: string;
    applicationId: string;
  };
}

const ApplicantInformation = ({
  onAction,
  onboardingData,
  isViewOnly,
}: IProps) => {
  const { t } = useTranslation(['common', 'onboarding']);

  const { response, loading } = useFetch(
    () =>
      onboardingAPI.fetchStatusForOnboardingItem(
        onboardingData.contactId,
        onboardingData.applicationId,
      ),
    [onboardingData],
  );

  const initialFormValues = useMemo<FormValuesModel | null>(() => {
    if (!response) {
      return null;
    }

    const item = response.item as ContactModel;

    let emails = [];
    if (item.emails.length) {
      emails = item.emails.map((e) => ({
        type: e.type,
        value: e.address,
        primary: {
          status: e.isPrimary,
          canEdit: !e.isPrimary,
        },
      }));

      const hasPrimary = emails.some((e) => e.primary.status);
      if (!hasPrimary) {
        emails[0].primary.status = true;
      }
    } else {
      emails = [
        {
          type: null,
          value: '',
          primary: {
            status: true,
            canEdit: true,
          },
        },
      ];
    }

    let phoneNumbers = [];
    if (item.phoneNumbers.length) {
      phoneNumbers = item.phoneNumbers.map((e) => ({
        type: e.type,
        value: e.number,
        primary: {
          status: e.isPrimary,
          canEdit: !e.isPrimary,
        },
      }));

      const hasPrimary = phoneNumbers.some((e) => e.primary.status);
      if (!hasPrimary) {
        phoneNumbers[0].primary.status = true;
      }
    } else {
      phoneNumbers = [
        {
          type: null,
          value: '',
          primary: {
            status: true,
            canEdit: true,
          },
        },
      ];
    }

    return {
      isSave: false,
      submitActionType: null,
      isContactBaseInfoFormDisabled: false,
      firstName: item.firstName || '',
      middleName: item.middleName || '',
      lastName: item.lastName || '',
      dateOfBirth: item.dateOfBirth ? formatDateToUTC(item.dateOfBirth) : null,
      countryOfBirth: item.countryOfBirth || '',
      gender: item.gender || null,
      emails,
      phoneNumbers,
      socialMedia: {
        twitterLink: item.socialMedia
          ? findSocialMediaByType(SocialMediaTypes.Twitter, item.socialMedia)
          : '',
        facebookLink: item.socialMedia
          ? findSocialMediaByType(SocialMediaTypes.Facebook, item.socialMedia)
          : '',
        linkedInLink: item.socialMedia
          ? findSocialMediaByType(SocialMediaTypes.LinkedIn, item.socialMedia)
          : '',
      },
    };
  }, [response]);

  const handleOnSubmit = async (values: FormValuesModel) => {
    const formattedBody =
      onboardingAPIAdapter.submitApplicantInformationIndividualStep(values);
    await onboardingAPI.submitApplicantInformationIndividualStep(
      formattedBody,
      onboardingData.applicationId,
    );
    Message.success(t('success_save'));
    onAction(values.submitActionType);
  };

  return (
    <LoadingWrapper loading={loading}>
      {initialFormValues ? (
        <ApplicantInformationForm
          title={t('applicant_information.general.title', { ns: 'onboarding' })}
          initialFormValues={initialFormValues}
          disabled={isViewOnly}
          onSubmit={handleOnSubmit}
        />
      ) : null}
    </LoadingWrapper>
  );
};

export default ApplicantInformation;
