import React, { memo, useState } from 'react';

// helpers
import APIConfig from 'config/api';
import useTranslation from 'hooks/useTranslation';
import { getAccessToken } from 'helpers/localStorageHelpers';
import { generateFingerprint } from 'helpers/sessionHelpers';
import { downloadDocumentByURL } from 'helpers/documentsHelpers';

// components
import { Button } from '@ui';

interface IProps {
  type: 'transaction' | 'journal-entry';
  entryId: number;
}

const ExportToPDF = memo(({ type, entryId }: IProps) => {
  const { t } = useTranslation('common');
  const [loader, setLoader] = useState(false);

  const onExportClick = async () => {
    setLoader(true);
    const token = getAccessToken();
    const fingerprint = await generateFingerprint();
    let link =
      type == 'journal-entry'
        ? `${APIConfig.accountingApi}/report/outgoing-wire-confirmation/journal-entry/${entryId}/pdf`
        : `${APIConfig.accountingApi}/report/outgoing-wire-confirmation/${entryId}/pdf`;
    link += `?tokenb64=${btoa(`Bearer ${token}`)}&fingerprint=${fingerprint}`;
    await downloadDocumentByURL(link, 'wire-confirmation');
    setLoader(false);
  };

  return (
    <Button loading={loader} onClick={onExportClick}>
      {t('export_to_pdf')}
    </Button>
  );
});

export default ExportToPDF;
