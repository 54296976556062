export const USER_ACCESS_TOKEN = 'token';
export const USER_REFRESH_TOKEN = 'refresh_token';
export const ACTIVE_APPLICATION_ID = 'active_application';
export const AMOUNT_OF_ATTEMPTS_RESENDING_OTP_SMS =
  'amount_of_attempts_resending_otp_sms';
export const AMOUNT_OF_ATTEMPTS_RESENDING_OTP_EMAIL =
  'amount_of_attempts_resending_otp_email';
export const TIME_OFFSET = 'timeOffset';

// 1. Access Token
export function getAccessToken(): string {
  return localStorage.getItem(USER_ACCESS_TOKEN) || '';
}

export function setAccessToken(token: string) {
  localStorage.setItem(USER_ACCESS_TOKEN, token);
}

// 2. Refresh Token
export function getRefreshToken(): string {
  return localStorage.getItem(USER_REFRESH_TOKEN) || '';
}

export function setRefreshToken(token: string) {
  localStorage.setItem(USER_REFRESH_TOKEN, token);
}

// 3. setAuthenticationTokens
export function setAuthenticationTokens(
  accessToken: string,
  refreshToken: string,
) {
  localStorage.setItem(USER_ACCESS_TOKEN, accessToken);
  localStorage.setItem(USER_REFRESH_TOKEN, refreshToken);
}

// 4. Active Application
export function getActiveApplication(): string {
  return localStorage.getItem(ACTIVE_APPLICATION_ID) || '';
}

export function setActiveApplication(applicationId: string) {
  localStorage.setItem(ACTIVE_APPLICATION_ID, applicationId);
}

// 5. Attempts for Email OTP
export function getAttemptsOfResendingEmailOTP(): number {
  const val = localStorage.getItem(AMOUNT_OF_ATTEMPTS_RESENDING_OTP_EMAIL);
  return val ? parseInt(val, 10) || 0 : 0;
}

export function setAttemptsOfResendingEmailOTP(amount: number) {
  localStorage.setItem(AMOUNT_OF_ATTEMPTS_RESENDING_OTP_EMAIL, String(amount));
}

// 6. Attempts for SMS OTP
export function getAttemptsOfResendingSmsOTP(): number {
  const val = localStorage.getItem(AMOUNT_OF_ATTEMPTS_RESENDING_OTP_SMS);
  return val ? parseInt(val, 10) || 0 : 0;
}

export function setAttemptsOfResendingSmsOTP(amount: number) {
  localStorage.setItem(AMOUNT_OF_ATTEMPTS_RESENDING_OTP_SMS, String(amount));
}

export function setTimeOffset(serverTime: number) {
  const timeOffset = serverTime * 1000 - Date.now();
  const localTimeOffset = getTimeOffset();

  if (!localTimeOffset || Number(localTimeOffset) !== timeOffset) {
    localStorage.setItem(TIME_OFFSET, String(timeOffset));
  }
}

export function getTimeOffset() {
  return localStorage.getItem(TIME_OFFSET) || '';
}

// 7. Clear
export function clearLocalStorage() {
  localStorage.clear();
}
