import React, { useCallback, useContext, useEffect } from 'react';
import styled from 'styled-components';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import useTranslation from 'hooks/useTranslation';
import { useDispatch } from 'react-redux';
import { useFormikContext } from 'formik';
import {
  CHANGE_VIEW_TYPES,
  changeView,
  removeChangeViewHandler,
} from 'redux/actions/view';
import SubmitButton from '../../../Buttons/SubmitButton';
import LoadingWrapper from '../../../WrapperComponents/LoadingWrapper';
import { Button, FormContext, Row, Col } from '@ui';
import { Modal } from 'antd';
import { IProps } from './types';

/**
 * The `ModalDialog` component renders a customizable modal dialog with support for form integration,
 * responsive design, and various footer configurations. It leverages Formik for form state management
 * and integrates with Redux for view state handling.
 *
 * **Key Features:**
 * - **Form Integration:** Automatically manages form state if `shouldUseFormContext` is enabled.
 * - **Responsive Design:** Adjusts modal width based on screen size.
 * - **Custom Footer:** Supports custom footer content, extended footer buttons, and configurable button positions.
 * - **Loading State:** Displays a loading indicator while form data is being fetched or processed.
 * - **Accessibility:** Provides options to disable modal closing and reset forms after submission.
 *
 * @param {IProps} props - The properties defined in `IProps`.
 * @returns A styled Ant Design `Modal` component with integrated form handling and customizable footer.
 */
const ModalDialog = ({
  title,
  width = '70%',
  footer = null,
  isVisible,
  closeCallback,
  children,
  closable = true,
  cancelButtonProps = {},
  submitButtonProps = {},
  afterCloseCallback,
  extendedFooterButtons = null,
  extendedFooterButtonsPosition,
  hideFooterButtons,
  renderSubmitButton,
  shouldUseFormContext = true,
  shouldResetFormAfterSubmit = true,
  closeModalAfterSubmit = true,
  closeModalAfterError = true,
  className,
}: IProps) => {
  const { t } = useTranslation('common');
  const form = shouldUseFormContext && useFormikContext();
  const dispatch = useDispatch();
  const breakpoint = useBreakpoint();
  const customFormContext = useContext(FormContext);

  useEffect(() => {
    if (customFormContext && customFormContext.setThrowOnSubmitError) {
      customFormContext.setThrowOnSubmitError(!closeModalAfterError);
    }
  }, [closeModalAfterError, customFormContext]);

  const closeModal = useCallback(
    async (isAfterSubmit: boolean, wasSubmitted?: boolean) => {
      if ((isAfterSubmit && closeModalAfterSubmit) || !isAfterSubmit) {
        if (form && shouldResetFormAfterSubmit) {
          await form.resetForm({
            values: {},
            errors: {},
            touched: {},
            isSubmitting: false,
            isValidating: false,
            status: undefined,
            submitCount: 0,
          });
        }

        closeCallback && closeCallback(wasSubmitted);
      }
    },
    [closeCallback, form, closeModalAfterSubmit, shouldResetFormAfterSubmit],
  );

  // Handle close modal dialog event
  const handleCloseDialog = async () => {
    if (closable) {
      if (form) {
        await dispatch(
          changeView(() => {
            closeModal && closeModal(false);
          }, CHANGE_VIEW_TYPES.MODAL),
        );
      } else {
        if (closeCallback) {
          await closeCallback();
        }
      }
    }
  };

  const handleSubmit = async () => {
    if (form) {
      try {
        const response: any = await form.submitForm();
        if (response?.success) {
          closeModal(true, true);
        }
      } catch {
        if (closeModalAfterError) {
          closeModal(true, true);
        }
      }
    }
  };

  const handleModalAfterClose = () => {
    dispatch(removeChangeViewHandler());
    afterCloseCallback && afterCloseCallback();
  };

  const isTabletResolution = () => !breakpoint.xl;
  const isMobileResolution = () => !breakpoint.lg;

  const footerGridSizes = isMobileResolution()
    ? { xl: 6, lg: 24, md: 24, sm: 24, xs: 24 }
    : {};

  return (
    <StyledModal
      title={title}
      style={{ top: 20 }}
      width={isTabletResolution() ? '90%' : width}
      footer={footer}
      visible={isVisible}
      closable={closable}
      onCancel={handleCloseDialog}
      afterClose={handleModalAfterClose}
      destroyOnClose={true}
      className={className}
    >
      <LoadingWrapper
        loading={
          !isVisible ||
          (form &&
            (!form?.initialValues ||
              !form?.values ||
              !Object.keys(form?.values as any)?.length))
        }
      >
        {children}
      </LoadingWrapper>

      {!hideFooterButtons && (
        <FooterWrapper gutter={[16, 16]}>
          {extendedFooterButtonsPosition === 'before' &&
          extendedFooterButtons ? (
            <ButtonCol {...footerGridSizes} fullWidth={isMobileResolution()}>
              {extendedFooterButtons}
            </ButtonCol>
          ) : null}

          {(!cancelButtonProps.hidden || closable === false) && (
            <ButtonCol {...footerGridSizes} fullWidth={isMobileResolution()}>
              <Button
                type="bordered"
                size="large"
                onClick={handleCloseDialog}
                {...cancelButtonProps}
              >
                {cancelButtonProps && cancelButtonProps.text
                  ? cancelButtonProps.text
                  : t('cancel')}
              </Button>
            </ButtonCol>
          )}

          {!submitButtonProps.hidden && (
            <ButtonCol
              {...footerGridSizes}
              style={submitButtonProps?.style}
              fullWidth={isMobileResolution()}
            >
              {renderSubmitButton ? (
                renderSubmitButton
              ) : (
                <SubmitButton onClick={handleSubmit} {...submitButtonProps}>
                  {submitButtonProps && submitButtonProps.text
                    ? submitButtonProps.text
                    : t('save')}
                </SubmitButton>
              )}
            </ButtonCol>
          )}

          {extendedFooterButtonsPosition === 'after' &&
          extendedFooterButtons ? (
            <ButtonCol {...footerGridSizes} fullWidth={isMobileResolution()}>
              {extendedFooterButtons}
            </ButtonCol>
          ) : null}
        </FooterWrapper>
      )}
    </StyledModal>
  );
};

const FooterWrapper = styled(Row)`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 0px !important;
  padding-top: ${({ theme }) => theme.modalPadding};
`;

const ButtonCol = styled(Col)<{ fullWidth: boolean }>`
  ${({ fullWidth }) =>
    fullWidth && '.ant-btn { width: 100%; margin-right: 0px !important; }'}
`;

const StyledModal = styled(Modal)`
  .ant-modal-body {
    padding-bottom: 0px;
  }
`;

export default ModalDialog;
