import moment, { MomentInput } from 'moment';
import { DEFAULT_DATE_FORMAT } from '../constants/global';

/**
 * Convert the given date to a moment in UTC (useful if date is local).
 */
export function formatDateToUTC(date: MomentInput) {
  return moment.utc(date);
}

export function formatDateToString(
  date: MomentInput,
  toFormat = DEFAULT_DATE_FORMAT,
  utc?: boolean,
): string {
  return utc
    ? formatDateToUTC(date).format(toFormat)
    : moment(date).format(toFormat);
}

/**
 * If timestamp > 0, format it as date string, otherwise return ''.
 * Multiplies timestamp by 1000 to convert seconds -> milliseconds for moment.
 */
export function formatTimestampDateToString(
  timestampSeconds: number,
  format = DEFAULT_DATE_FORMAT,
): string {
  return timestampSeconds > 0
    ? formatDateToString(timestampSeconds * 1000, format)
    : '';
}

/**
 * Return the UNIX timestamp (in seconds) corresponding to the start of 'date' (midnight).
 */
export function formatDateToTimestampWithTruncatedTime(
  date: MomentInput,
): number {
  const m = moment(date);

  if (!m.isValid()) {
    return 0;
  }

  return moment(date).startOf('day').unix();
}

/**
 * If no timestamp, return ''. Else create moment in UTC, then format using 'formatDateToString'.
 */
export function formatTimestampDateToUTCString(
  timestampSeconds: number,
  format = DEFAULT_DATE_FORMAT,
): string {
  if (!timestampSeconds || timestampSeconds <= 0) {
    return '';
  }

  return formatDateToString(timestampSeconds * 1000, format, true);
}

/**
 * Convert minutes -> textual representation, e.g. "60" -> "1 hour", "70" -> "1 hour 10 minutes"
 */
export function formatMinutesAsHumanReadable(
  minutes: number,
  labels: { minute: string; minutes: string; hour: string; hours: string },
): string {
  if (minutes < 0) {
    return '';
  }

  let result = '';

  if (minutes < 60) {
    result = `${minutes} ${minutes === 1 ? labels.minute : labels.minutes}`;
  } else if (minutes === 60) {
    result = `1 ${labels.hour}`;
  } else {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    const minuteLabel = remainingMinutes === 1 ? labels.minute : labels.minutes;
    const minutesPart = remainingMinutes
      ? ` ${remainingMinutes} ${minuteLabel}`
      : '';

    if (hours < 2) {
      result = `${hours} ${labels.hour}${minutesPart}`;
    } else {
      result = `${hours} ${labels.hours}${minutesPart}`;
    }
  }
  return result;
}
