import { base64ToBuffer, bufferToBase64 } from './base64Helpers';
import {
  ChallengeForLoginModel,
  ChallengeForRegistrationModel,
} from '../typings/profile/fido';

export function preformatChallengeForRegistration(
  credentialsChallengeRequest: ChallengeForRegistrationModel,
): PublicKeyCredentialCreationOptions {
  return {
    ...credentialsChallengeRequest,
    challenge: base64ToBuffer(String(credentialsChallengeRequest.challenge)),
    excludeCredentials: credentialsChallengeRequest.excludeCredentials.map(
      (e) => ({
        id: base64ToBuffer(String(e.id)),
        type: e.type,
      }),
    ),
    user: {
      ...credentialsChallengeRequest.user,
      id: base64ToBuffer(String(credentialsChallengeRequest.user.id)),
    },
    authenticatorSelection: {
      userVerification: 'required',
      authenticatorAttachment: 'cross-platform',
    },
  };
}

export function preformatChallengeForLogin(
  challenge: ChallengeForLoginModel,
): PublicKeyCredentialRequestOptions {
  return {
    challenge: base64ToBuffer(String(challenge.challenge)),
    allowCredentials: challenge.allowCredentials.map((e) => ({
      ...e,
      id: base64ToBuffer(String(e.id)),
    })),
  };
}

export function publicKeyCredentialToJSON(item: Credential | any): unknown {
  if (item instanceof Array) {
    return item.map(publicKeyCredentialToJSON);
  }

  if (item instanceof ArrayBuffer) {
    return bufferToBase64(new Uint8Array(item));
  }

  if (item instanceof Object) {
    const obj: { [n: string]: unknown } = {};

    for (const key in item) {
      obj[key] = publicKeyCredentialToJSON(item[key]);
    }

    return obj;
  }

  return item;
}
