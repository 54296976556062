import React, { useMemo } from 'react';

// helpers
import useFetch from 'hooks/useFetch';
import useTranslation from 'hooks/useTranslation';
import { ContactModel } from 'typings/application/contact';
import { onboardingAPI } from 'api/onboarding/onboardingAPI';
import { onboardingAPIAdapter } from 'apiAdapters/onboarding/onboardingAPIAdapter';
import { OnboardingStatusModel } from 'typings/onboarding/onboarding';
import { formatExpiringDocumentToPassportFormItemModel } from 'helpers/crmHelpers';

// components
import LoadingWrapper from 'components/WrapperComponents/LoadingWrapper';
import IdentificationForIndividualForm, {
  AddressItemModel,
  FormValuesModel,
  PassportItemModel,
} from 'components/Forms/TemplateForms/Onboarding/IdentificationForIndividualForm';
import { Message } from '@ui';

interface IProps {
  onboardingStatus: OnboardingStatusModel;
  isViewOnly: boolean;
  onSave: () => void;
}

const Identification = ({ onboardingStatus, isViewOnly, onSave }: IProps) => {
  const { t } = useTranslation('common');

  const { response, loading } = useFetch(
    () => onboardingAPI.fetchIdentificationStepData(onboardingStatus.crmItemId),
    [onboardingStatus],
  );

  const initialFormValues = useMemo<FormValuesModel | null>(() => {
    if (!response) {
      return null;
    }

    let addresses: AddressItemModel[] = [
      {
        isPrimary: true,
        type: null,
        country: null,
        city: '',
        street: '',
        state: '',
        postalCode: '',
      },
    ];

    let passports: PassportItemModel[] = [
      {
        number: '',
        country: '',
        issuedAt: null,
        expirationDate: null,
        document: [],
      },
    ];

    if (onboardingStatus.item) {
      const contact = onboardingStatus.item as ContactModel;

      if (response.addresses.data?.length) {
        addresses = response.addresses.data.map((address) => ({
          _id: address._id,
          isPrimary: !!address.isPrimary,
          type: address.type || null,
          country: address.country || null,
          city: address.city,
          street: address.street,
          state: address.state,
          review: address.review,
          postalCode: address.postalCode,
          documents:
            address?.documents &&
            address.documents.map((doc) => ({
              id: doc.id,
              name: doc.files[0].name,
              fileId: doc.files[0].id,
              file: null,
            })),
        }));
      }

      if (response.passportExpiringDocuments.data.length) {
        passports = response.passportExpiringDocuments.data.map(
          formatExpiringDocumentToPassportFormItemModel,
        );
      }

      return {
        submitActionType: null,
        isSave: false,
        isPEP: typeof contact.isPEP === 'boolean' ? contact.isPEP : null,
        pepInformation: contact.pepInformation || '',
        isRegulated:
          typeof contact.isRegulated === 'boolean' ? contact.isRegulated : null,
        regulationCountry: contact.regulationCountry,
        addresses,
        passports,
        nationality: contact.nationality || [],
        disabledPep: true,
      };
    } else {
      return {
        submitActionType: null,
        isSave: false,
        isPEP: null,
        pepInformation: '',
        isRegulated: null,
        regulationCountry: [],
        addresses,
        passports,
        nationality: [],
        disabledPep: true,
      };
    }
  }, [onboardingStatus, response]);

  const onSubmit = async (values: FormValuesModel) => {
    const formattedBody =
      onboardingAPIAdapter.submitIdentificationForIndividualStep(values);
    await onboardingAPI.submitIdentificationForIndividualStep(
      formattedBody,
      onboardingStatus.applicationId,
      onboardingStatus.crmItemId,
    );
    Message.success(t('success_save'));
    onSave();
  };

  return (
    <LoadingWrapper loading={loading}>
      {initialFormValues && (
        <IdentificationForIndividualForm
          showOnlySaveButton
          disabled={isViewOnly}
          onSubmit={onSubmit}
          initialFormValues={initialFormValues}
        />
      )}
    </LoadingWrapper>
  );
};

export default Identification;
