// helpers
import { userAPI } from 'api/profile/userAPI';
import { Dispatch } from 'redux';
import { profileAPI } from 'api/profile/profileAPI';
import { cleanUpViewReducer } from './view';
import { isAccessTokenAlive } from 'helpers/commonHelpers';
import { cleanUpUploadReducer } from './upload';
import { cleanUpSettingsReducer } from './settings';
import { cleanUpDocumentsReducer } from './documents';
import { TwoFactorVerificationTypes } from 'enums/profile/auth';
import { PhoneVerificationDataModel } from 'typings/application/contact';
import { cleanUpNotificationsReducer } from './notifications';
import { USER_TAG_IS_LOGGED_IN_FIRST_TIME } from 'constants/userTags';
import { cleanUpAppReducer, setInitializedStatus } from './app';
import { cleanUpApplicationsReducer, initApplications } from './applications';
import { cleanUpUserAccessReducer, initUserPermissions } from './userAccess';
import {
  cleanUpPersonalDetailsState,
  setIsVerifiedStatus,
} from './personalDetails';
import {
  ExternalServiceStatuses,
  TwoFactorVerificationDataModel,
} from '../reducers/auth';
import {
  clearAuthenticatedUserTelemetryContext,
  setUserInfoToTelemetryContext,
} from 'services/azure-app-insights/service';
import { clearLocalStorage, getAccessToken } from 'helpers/localStorageHelpers';

// Actions
export enum ActionType {
  PROFILE_GET_START = 'auth/PROFILE_GET_START',
  PROFILE_GET_SUCCESS = 'auth/PROFILE_GET_SUCCESS',
  PROFILE_GET_ERROR = 'auth/PROFILE_GET_ERROR',
  SET_AUTH_STATUS = 'auth/SET_AUTH_STATUS',
  SET_TWO_FACTOR_VERIFICATION_ENABLED = 'auth/SET_TWO_FACTOR_VERIFICATION_ENABLED',
  SET_AUTHENTICATOR_ENABLED_STATUS = 'auth/SET_AUTHENTICATOR_ENABLED_STATUS',
  SET_FIDO_AUTHENTICATOR_ENABLED_STATUS = 'auth/SET_FIDO_AUTHENTICATOR_ENABLED_STATUS',
  SET_DEFAULT_AUTHENTICATOR = 'auth/SET_DEFAULT_AUTHENTICATOR',
  SET_TWO_FACTOR_AUTHENTICATION_DATA = 'auth/SET_TWO_FACTOR_AUTHENTICATION_DATA',
  SET_FIRST_TIME_LOGGED_IN_STATUS = 'auth/SET_FIRST_TIME_LOGGED_IN_STATUS',
  SET_SESSION_DURATION = 'auth/SET_SESSION_DURATION',
  SET_CIRCLE_STATUS = 'auth/SET_CIRCLE_STATUS',
  SET_EMAIL_CONFIRMATION = 'auth/SET_EMAIL_CONFIRMATION',
  SET_IS_EMAIL_VERIFIED_STATUS = 'auth/SET_IS_EMAIL_VERIFIED_STATUS',
  SET_IS_PHONE_VERIFIED_STATUS = 'auth/SET_IS_PHONE_VERIFIED_STATUS',
  SET_FIRST_AND_LAST_NAMES = 'auth/SET_FIRST_AND_LAST_NAMES',
  SET_PRIMARY_PHONE_NUMBER = 'auth/SET_PRIMARY_PHONE_NUMBER',
  SET_INVITATION_CODE = 'auth/SET_INVITATION_CODE',
  SET_TEMPORARY_TOKEN = 'auth/SET_TEMPORARY_TOKEN',
  SET_USER_TAGS = 'auth/SET_USER_TAGS',
  SET_PHONE_NUMBER_START_VERIFICATION_DATE = 'auth/SET_PHONE_NUMBER_START_VERIFICATION_DATE',
  SET_PRIMARY_EMAIL = 'auth/SET_PRIMARY_EMAIL',
  CLEAN_UP = 'auth/CLEAN_UP',
}

// Action creators
export const setAuthenticatorEnabledStatus = (isEnabled: boolean) => ({
  type: ActionType.SET_AUTHENTICATOR_ENABLED_STATUS,
  payload: { isEnabled },
});

export const setTwoFactorVerificationEnabled = (
  isTwoFactorVerificationEnabled: boolean,
) => ({
  type: ActionType.SET_TWO_FACTOR_VERIFICATION_ENABLED,
  payload: { isTwoFactorVerificationEnabled },
});

export const setFidoAuthenticatorEnabledStatus = (isEnabled: boolean) => ({
  type: ActionType.SET_FIDO_AUTHENTICATOR_ENABLED_STATUS,
  payload: { isEnabled },
});

export const setDefaultAuthenticator = (
  authenticatorType: TwoFactorVerificationTypes,
) => ({
  type: ActionType.SET_DEFAULT_AUTHENTICATOR,
  payload: { authenticatorType },
});

export const setTwoFactorVerificationData = (
  data: TwoFactorVerificationDataModel | null,
) => ({
  type: ActionType.SET_TWO_FACTOR_AUTHENTICATION_DATA,
  payload: { data },
});

export const setSessionDuration = (seconds: number) => ({
  type: ActionType.SET_SESSION_DURATION,
  payload: { seconds },
});

export const setCircleStatus = (status: ExternalServiceStatuses) => ({
  type: ActionType.SET_CIRCLE_STATUS,
  payload: { status },
});

export const setEmailConfirmationStatus = (isEmailConfirmed: boolean) => ({
  type: ActionType.SET_EMAIL_CONFIRMATION,
  payload: { isEmailConfirmed },
});

export const setIsEmailVerifiedStatus = (status: boolean) => ({
  type: ActionType.SET_IS_EMAIL_VERIFIED_STATUS,
  payload: { status },
});

export const setIsPhoneVerifiedStatus = (status: boolean) => ({
  type: ActionType.SET_IS_PHONE_VERIFIED_STATUS,
  payload: { status },
});

export const setFirstAndLastNameForUser = (
  firstName?: string,
  lastName?: string,
) => ({
  type: ActionType.SET_FIRST_AND_LAST_NAMES,
  payload: { firstName, lastName },
});

export const setPrimaryPhoneNumber = (
  phoneNumber: string,
  verification?: PhoneVerificationDataModel,
) => ({
  type: ActionType.SET_PRIMARY_PHONE_NUMBER,
  payload: { phoneNumber, verification },
});

export const setPrimaryEmail = (email: string) => ({
  type: ActionType.SET_PRIMARY_EMAIL,
  payload: { email },
});

export const setInvitationCode = (invitationCode: string | null) => ({
  type: ActionType.SET_INVITATION_CODE,
  payload: { invitationCode },
});

export const setUserTags = (tags: string[]) => ({
  type: ActionType.SET_USER_TAGS,
  payload: { tags },
});

export const setPhoneNumberStartVerificationDate = (
  verificationStartOn: Date,
) => ({
  type: ActionType.SET_PHONE_NUMBER_START_VERIFICATION_DATE,
  payload: { verificationStartOn },
});

export const cleanUpAuthReducer = () => ({
  type: ActionType.CLEAN_UP,
  payload: null,
});

export const signOut = (skipAPICall?: boolean) => async (dispatch: any) => {
  dispatch(setInitializedStatus(false));

  if (!skipAPICall) {
    const token = getAccessToken();
    const isTokenAlive = isAccessTokenAlive(token);

    if (token && isTokenAlive) {
      try {
        await userAPI.signOut();
      } catch {
        // TODO: handle error
      }
    }
  }

  clearLocalStorage();
  clearAuthenticatedUserTelemetryContext();

  // TODO: rework this to clean up state in a more efficient way (in single action)
  dispatch(cleanUpAppReducer());
  dispatch(cleanUpApplicationsReducer());
  dispatch(cleanUpAuthReducer());
  dispatch(cleanUpDocumentsReducer());
  dispatch(cleanUpNotificationsReducer());
  dispatch(cleanUpPersonalDetailsState());
  dispatch(cleanUpSettingsReducer());
  dispatch(cleanUpUploadReducer());
  dispatch(cleanUpUserAccessReducer());
  dispatch(cleanUpViewReducer());

  dispatch(setInitializedStatus(true));
};

export const setTemporaryToken = (temporaryToken: string | null) => ({
  type: ActionType.SET_TEMPORARY_TOKEN,
  payload: { temporaryToken },
});

export const getMe = () => async (dispatch: any) => {
  dispatch({
    type: ActionType.PROFILE_GET_START,
    payload: true,
    key: 'profileData',
  });

  const response = await profileAPI.fetchMe();
  setUserInfoToTelemetryContext(response.user._id);
  const isPhoneVerified =
    response.contact.phoneNumber.verification &&
    response.contact.phoneNumber.verification.isVerified;
  await dispatch(setIsEmailVerifiedStatus(response.user.emailConfirmed));
  await dispatch(setIsPhoneVerifiedStatus(!!isPhoneVerified));
  await dispatch(
    setTwoFactorVerificationEnabled(
      response.user.isTwoFactorAuthenticationEnabled ||
        response.user.isTwoFactorFidoEnabled,
    ),
  );
  await dispatch(initializeIsCircleUserStatus());
  await dispatch(initApplications(response.applications));
  await dispatch(initUserPermissions(response.permissions));
  await dispatch(setIsVerifiedStatus(response.contact.isKYCProvided));

  dispatch({
    type: ActionType.PROFILE_GET_SUCCESS,
    payload: { ...response },
    key: 'profileData',
  });
};

export const updateUserFirstTimeLoggedInStatus =
  () => async (dispatch: Dispatch) => {
    await userAPI.removeUserTags([USER_TAG_IS_LOGGED_IN_FIRST_TIME]);
    dispatch({
      type: ActionType.SET_FIRST_TIME_LOGGED_IN_STATUS,
      payload: { status: false },
    });
  };

// TODO: uncomment when we add Circle back
export const initializeIsCircleUserStatus =
  () => async (dispatch: Dispatch) => {
    // const response = await circleAPI.getIsConnectedStatus();

    // const circleStatus: ExternalServiceStatuses = response.isActive
    //   ? 'connected'
    //   : !response.isActive && response.isConnected
    //   ? 'pending_synchronization'
    //   : 'disconnected';

    dispatch(setCircleStatus('disconnected'));
  };
