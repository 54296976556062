import React, { useMemo } from 'react';

// helpers
import styled from 'styled-components';
import { StateModel } from 'redux/reducers';
import { onboardingAPI } from 'api/onboarding/onboardingAPI';
import { setInvitationCode } from 'redux/actions/auth';
import { onboardingAPIAdapter } from 'apiAdapters/onboarding/onboardingAPIAdapter';
import { setAuthenticationTokens } from 'helpers/localStorageHelpers';
import { useDispatch, useSelector } from 'react-redux';
import { initializeApp, setInitializedStatus } from 'redux/actions/app';

// components
import { Content } from '@ui';
import {
  default as TemplateSignUpForm,
  FormValuesModel,
} from 'components/Forms/TemplateForms/Auth/SignUpForm';

const SignUpForm = () => {
  const dispatch = useDispatch();
  const invitationCode = useSelector<StateModel, string | null>(
    (state) => state.auth.invitationCode,
  );

  const initialFormValues = useMemo<FormValuesModel>(
    () => ({
      step: 0,
      entityDescription: '',
      firstName: '',
      lastName: '',
      phoneNumber: '',
      email: '',
      password: '',
      passwordConfirm: '',
      accountType: null,
      keyRelationshipTemplateId: null,
    }),
    [],
  );

  const handleSubmit = async (values: FormValuesModel) => {
    const body = await onboardingAPIAdapter.startOnboardingProcess(
      values,
      invitationCode as string,
    );
    const response = await onboardingAPI.startOnboardingProcess(body);
    if (response && response.jwt) {
      dispatch(setInitializedStatus(false));
      setAuthenticationTokens(response.jwt, response.refreshToken);
      dispatch(setInvitationCode(null));
      dispatch(initializeApp());
    }
  };

  return (
    <StyledWrapper>
      <TemplateSignUpForm
        onSubmit={handleSubmit}
        initialFormValues={initialFormValues}
      />
    </StyledWrapper>
  );
};

const StyledWrapper = styled(Content)`
  padding: 50px 25%;
  overflow: auto;
`;

export default SignUpForm;
