import React, { useMemo } from 'react';

// helpers
import { userAPI } from 'api/profile/userAPI';
import { useDispatch } from 'react-redux';
import { userAPIAdapter } from 'apiAdapters/profile/userAPIAdapter';
import { setAuthenticationTokens } from 'helpers/localStorageHelpers';
import { initializeApp, setInitializedStatus } from 'redux/actions/app';

// components
import ActivateUserForm, {
  FormValuesModel,
} from 'components/Forms/TemplateForms/Auth/SignIn/ActivateUserForm';

interface IProps {
  username: string;
}

const ActivateUser = ({ username }: IProps) => {
  const dispatch = useDispatch();

  const initialFormValues: FormValuesModel = useMemo(
    () => ({
      otpCode: '',
      newPassword: '',
      confirmNewPassword: '',
    }),
    [],
  );

  const handleSubmit = async (values: FormValuesModel) => {
    const { otpCode, newPassword } = values;
    const activateData = await userAPIAdapter.activateUser({
      username,
      otpCode,
      password: newPassword,
    });
    const response = await userAPI.activateUser(activateData);

    dispatch(setInitializedStatus(false));
    setAuthenticationTokens(response.jwt, response.refreshToken);
    dispatch(initializeApp());
  };
  return (
    <ActivateUserForm
      initialFormValues={initialFormValues}
      username={username}
      handleSubmit={handleSubmit}
    />
  );
};

export default ActivateUser;
