import React, { useEffect, useMemo, useState } from 'react';
import useTranslation from 'hooks/useTranslation';
import { roundNumber } from 'helpers/accountsHelpers';
import { InputAmountProps } from './types';
import { InputNumber as AntDInputNumber } from 'antd';

const FORMAT_AMOUNT_REG = /\B(?=(\d{3})+(?!\d))/g;

/**
 * The `InputAmount` component is a numeric input field with special logic
 * for handling amounts, including decimal precision, formatting with commas,
 * and rounding upon blur. It’s designed to help users enter currency-like values
 * with controlled decimal places.
 *
 * - Users can type numeric values.
 * - The component applies formatting (commas) and restricts decimal places.
 * - On blur, the value is validated, rounded, and reported via `onChange`.
 *
 * @param {InputAmountProps} props - The properties defined in `InputAmountProps`.
 */
const InputAmount: React.FC<InputAmountProps> = ({
  value,
  onChange,
  disabled,
  placeholder,
  name,
  size,
  precision = 2,
  ...rest
}) => {
  const { t } = useTranslation('form');
  const [tempValue, setTempValue] = useState<string | number | undefined>(
    value,
  );

  useEffect(() => {
    if (value !== tempValue) {
      setTempValue(value);
    }
  }, [value]);

  const handleOnChange = (newValue: string | number) => {
    setTempValue(newValue);
  };

  const handleOnBlur = (newValue: string | number | undefined = 0) => {
    let inputValue = newValue;

    if (!Number(inputValue)) {
      inputValue = parseFloat(String(newValue));
    }

    let roundedNumber = 0;

    if (Number(inputValue) > 0) {
      roundedNumber = roundNumber(+inputValue, 2);
    }

    // Save new value
    setTempValue(roundedNumber);
    onChange && onChange(roundedNumber);
  };

  const inputNumberAmountFormatting = useMemo(
    () => ({
      formatter: (value: any) => String(value).replace(FORMAT_AMOUNT_REG, ','),
      parser: (value: any) => {
        if (!value.length) {
          return;
        }

        const formattedValue = value.replaceAll(',', '');
        const reg = new RegExp(`^\\d+\\.?\\d{0,${precision}}$`);

        if (reg.test(formattedValue)) {
          return formattedValue;
        } else {
          return formattedValue.substring(0, formattedValue.length - 1);
        }
      },
    }),
    [precision],
  );

  return (
    <AntDInputNumber
      {...rest}
      {...inputNumberAmountFormatting}
      step={0.01}
      size={size}
      name={name}
      value={tempValue as any}
      onBlur={() => handleOnBlur(tempValue)}
      onChange={(newValue) => handleOnChange(newValue as number)}
      disabled={disabled}
      placeholder={placeholder || t('enter_value')}
    />
  );
};

export default React.memo(InputAmount);
