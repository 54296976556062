import { AnyObject, Maybe, ObjectSchema, reach, SchemaDescription } from 'yup';

function accessPropertyByString<T>(obj: T, fieldString: string): unknown {
  const keys = fieldString.split('.');
  let value: unknown = obj;

  for (const key of keys) {
    if (Array.isArray(value)) {
      const index = parseInt(key, 10);
      if (!isNaN(index) && index >= 0 && index < value.length) {
        value = value[index];
      } else {
        value = undefined;
        break;
      }
    } else {
      value = (value as { [key: string]: unknown })[key];
    }
  }

  return value;
}

export function getFieldSchema(
  formValidationSchema: ObjectSchema<Maybe<AnyObject>, AnyObject, any, ''>,
  fieldName: string,
  formValues: any,
): SchemaDescription | null {
  let result: SchemaDescription | null = null;

  try {
    const fieldSchema = reach(
      formValidationSchema,
      fieldName,
      formValues,
      formValues,
    );
    let parent = formValues ? { ...formValues } : {};
    const splitName = fieldName.split('.');
    if (splitName.length > 1) {
      splitName.pop();
      const namePrefix = splitName.join('.');
      parent = accessPropertyByString(formValues, namePrefix);
    }

    result = fieldSchema
      ? (fieldSchema.describe({
          value: formValues,
          parent,
          context: formValues,
        } as any) as SchemaDescription)
      : null;
  } catch (err: any) {
    // TODO: investigate how we can track this error
  }

  return result;
}

export function isRequired(
  formValidationSchema: ObjectSchema<Maybe<AnyObject>, AnyObject, any, ''>,
  fieldName: string,
  formValues: any,
): boolean {
  let result = false;

  try {
    const describedSchema = getFieldSchema(
      formValidationSchema,
      fieldName,
      formValues,
    );
    result = describedSchema
      ? !describedSchema.optional && !describedSchema.nullable
      : false;
  } catch (err: any) {
    // TODO: investigate how we can track this error
  }

  return result;
}

export function getMinMaxRules(
  formValidationSchema: ObjectSchema<Maybe<AnyObject>, AnyObject, any, ''>,
  fieldName: string,
  formValues: any,
): { min?: { value: number }; max?: { value: number } } | null {
  const result: { min?: { value: number }; max?: { value: number } } = {};

  try {
    const describedSchema = getFieldSchema(
      formValidationSchema,
      fieldName,
      formValues,
    );

    const minLengthTest = describedSchema?.tests.find((e) => e.name === 'min');
    if (minLengthTest) {
      result['min'] = { value: (minLengthTest.params?.min as number) || 0 };
    }

    const maxLengthTest = describedSchema?.tests.find((e) => e.name === 'max');
    if (maxLengthTest) {
      result['max'] = { value: (maxLengthTest.params?.max as number) || 0 };
    }
  } catch (err: any) {
    // TODO: investigate how we can track this error
  }

  return Object.keys(result).length ? result : null;
}
