import React, { useMemo } from 'react';

// helpers
import styled from 'styled-components';
import { getDistrict } from 'helpers/accountsHelpers';
import { COUNTRY_CODES } from 'constants/countryCodes';
import { WireTransferTemplateData } from 'typings/accounting/transfer';

// components
import WireTransferTemplateInfo, {
  WireTemplate,
} from 'components/Additional/DetailsSections/Accounting/WireTransferTemplateInfo';
import { Card } from '@ui';

interface IProps {
  template: WireTransferTemplateData;
}

const TransferTemplate = ({ template }: IProps) => {
  const templateData = useMemo<WireTemplate>(() => {
    return {
      templateId: template.templateId,
      templateName: template.templateName,
      canAccessTemplate: true,
      beneficiaryAccountName: template.accountName,
      beneficiaryAccountNumber: template.accountNumber,
      beneficiaryCountry: template.country
        ? COUNTRY_CODES[template.country]
        : '',
      beneficiaryAddress: template.address,
      beneficiaryCity: template.city,
      beneficiaryDistrict: template.district
        ? getDistrict(template.country, template.district)
        : '',
      beneficiaryPostCode: template.postCode,

      beneficiaryBankCode: template.beneficiaryBank?.bankCode || '',
      beneficiaryBankName: template.beneficiaryBank?.bankName || '',
      beneficiaryBankCountry: template.beneficiaryBank?.country
        ? COUNTRY_CODES[template.beneficiaryBank?.country]
        : '',

      beneficiaryBankAddress: template.beneficiaryBank?.address || '',
      beneficiaryBankCodeTypeName:
        template.beneficiaryBank?.bankCodeTypeName || '',
      beneficiaryBankCity: template.beneficiaryBank?.city || '',
      beneficiaryBankDistrict: template.beneficiaryBank?.district
        ? getDistrict(
            template.beneficiaryBank.country,
            template.beneficiaryBank.district,
          )
        : '',
      beneficiaryBankPostCode: template.beneficiaryBank?.postCode || '',

      intermediaryBankCode: template.intermediaryBank?.bankCode || '',
      intermediaryBankCodeTypeName:
        template.intermediaryBank?.bankCodeTypeName || '',
      intermediaryBankName: template.intermediaryBank?.bankName || '',
      intermediaryBankCountry: template.intermediaryBank?.country
        ? COUNTRY_CODES[template.intermediaryBank?.country]
        : '',
      intermediaryBankAddress: template.intermediaryBank?.address || '',
      intermediaryBankCity: template.intermediaryBank?.city || '',
      intermediaryBankDistrict: template.intermediaryBank?.district
        ? getDistrict(
            template.intermediaryBank.country,
            template.intermediaryBank.district,
          )
        : '',
      intermediaryBankPostCode: template.intermediaryBank?.postCode || '',

      reference: '',
    };
  }, [template]);

  return (
    <StyledCard>
      <WireTransferTemplateInfo
        template={templateData}
        hideReference
        maxValueWidthForTooltip="300px"
      />
    </StyledCard>
  );
};

const StyledCard = styled(Card)`
  margin-bottom: 10px;
`;

export default TransferTemplate;
