import React, { useMemo, memo } from 'react';

// helpers
import styled from 'styled-components';
import useFetch from 'hooks/useFetch';
import useTranslation from 'hooks/useTranslation';
import { RoutePaths } from 'routes/routes';
import { useNavigate } from 'react-router-dom';
import { accountsAPI } from 'api/accounting/accountsAPI';
import { colorsTheme } from 'resources/theme/styled/colors';
import { IAccountModel } from 'typings/onboarding/account';
import {
  convertAmountBigIntToLocaleString,
  generateAccountFullName,
} from 'helpers/accountsHelpers';

// components
import {
  Text,
  IconSVG,
  DescriptionSection,
  DescriptionItem,
  Link,
  Col,
  Row,
  Popover,
} from '@ui';
import DivAlignCenter from 'components/Additional/DivAlignCenter';
import BankAccountStatus from 'components/Additional/Statuses/BankAccountStatus';
import DropdownField, { OptionModel } from 'components/Fields/DropdownField';
import { ReactComponent as ArrowRightIcon } from 'resources/icons/remix-icons/arrow-right-s-line.svg';

interface IProps {
  accounts: IAccountModel[];
  selectedAccount: IAccountModel;
  onAccountSelect: (newAccountNumber: IAccountModel) => void;
}

const AccountInfoSection = memo(
  ({ accounts, selectedAccount, onAccountSelect }: IProps) => {
    const navigate = useNavigate();
    const { t } = useTranslation(['dashboard', 'accounts']);

    const { response } = useFetch(
      () =>
        accountsAPI.getApprovalGroupsByAccountNumber(
          selectedAccount.accountNumber,
        ),
      [selectedAccount],
    );

    const dropdownOptions = useMemo(() => {
      let optionsArray: OptionModel[] = [];

      if (accounts.length) {
        optionsArray = accounts.map(
          ({ accountNumber, accountName, currencyCode, balance }) => ({
            key: accountNumber,
            label: generateAccountFullName(
              accountName,
              accountNumber,
              currencyCode,
              balance,
            ),
          }),
        );
      }

      return optionsArray;
    }, [accounts]);

    const handleAccountSelect = (newAccountNumber: string) => {
      const accountEntry = accounts.find(
        (e) => e.accountNumber === newAccountNumber,
      ) as IAccountModel;
      onAccountSelect(accountEntry);
    };

    const renderAccountBalance = (account: IAccountModel) => {
      let result = null;

      const { balance, availableBalance, currencyCode, accountNumber } =
        account;

      const accountBalanceString = `${currencyCode} ${convertAmountBigIntToLocaleString(balance)}`;
      const shouldRenderAvailableBalance = availableBalance !== balance;

      if (!shouldRenderAvailableBalance) {
        result = accountBalanceString;
      } else {
        result = (
          <Popover
            placement="bottom"
            content={
              <>
                <Text variant="body1">
                  {`${t('account_summary.available_balance', { ns: 'accounts' })}: ${convertAmountBigIntToLocaleString(
                    availableBalance || 0,
                  )}`}
                </Text>

                <Text variant="body1">
                  {t('account_summary.available_balance_helper', {
                    ns: 'accounts',
                  })}
                </Text>
                <Link
                  onClick={() =>
                    navigate({
                      pathname: RoutePaths.Accounts_PendingTransactions,
                      search: `?accountNumber=${accountNumber}`,
                    })
                  }
                >
                  <DivAlignCenter>
                    {t('account_summary.go_to_pending_transfers_button', {
                      ns: 'accounts',
                    })}
                    <IconSVG
                      component={ArrowRightIcon}
                      color={colorsTheme.colorWhite}
                    />
                  </DivAlignCenter>
                </Link>
              </>
            }
          >
            <StyledDottedText variant="body1">
              {accountBalanceString}
            </StyledDottedText>
          </Popover>
        );
      }

      return result;
    };

    const descriptionsData = useMemo<DescriptionItem[]>(() => {
      return [
        {
          label: t('account_details_section.account_number'),
          description: selectedAccount.accountNumber,
          copyable: true,
        },
        {
          label: t('account_details_section.account_name'),
          description: selectedAccount.accountName,
          copyable: true,
        },
        {
          label: t('account_details_section.currency'),
          description: selectedAccount.currencyCode,
        },
        {
          label: t('account_details_section.status'),
          description: <BankAccountStatus status={selectedAccount.status} />,
        },
        {
          label: t('account_details_section.approval_group'),
          description: response
            ? response.items.map((e) => (
                <Text
                  variant="body1"
                  color={colorsTheme.colorWhite}
                  key={e.name}
                >
                  {e.name}
                </Text>
              ))
            : '',
        },
      ];
    }, [selectedAccount, response]);

    return (
      <>
        <StyledDropdownWithOptions
          options={dropdownOptions}
          onSelect={(newAccountNumber) =>
            handleAccountSelect(newAccountNumber as string)
          }
          renderSelectedOption={(label) => (
            <SelectedAccountText variant="h5">{label}</SelectedAccountText>
          )}
        />

        <Row>
          <Col span={16}>
            <DescriptionSection
              size="small"
              data={descriptionsData}
              bordered={false}
              background="transparent"
            />
          </Col>
          <Col span={8}>
            <BankAccountBalanceWrapper>
              <StyledAmountText
                variant="h6"
                color={colorsTheme.colorWhite}
                weight="semi-bold"
                gutterBottom
              >
                {renderAccountBalance(selectedAccount)}
              </StyledAmountText>
              <StyledBalanceText
                variant="body1"
                color={colorsTheme.colorLightD1}
                gutterBottom
              >
                {t('account_details_section.available_funds')}
              </StyledBalanceText>
            </BankAccountBalanceWrapper>
          </Col>
        </Row>
      </>
    );
  },
);

const BankAccountBalanceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const StyledDropdownWithOptions = styled(DropdownField)`
  margin-bottom: ${({ theme }) => theme.marginMd};
`;

const StyledBalanceText = styled(Text)`
  margin-top: -5px;
  text-align: right;
  color: ${({ theme }) => theme.colorLightD1};
`;

const StyledAmountText = styled(Text)`
  letter-spacing: 1.5px;
  text-align: right;
  width: fit-content;
`;

const SelectedAccountText = styled(Text)`
  font-weight: 500;
  letter-spacing: 1.2px;
`;

const StyledDottedText = styled(Text)`
  cursor: pointer;
  border-bottom: ${({ theme }) => `1px dotted ${theme.colorWarning}`};
`;

export default AccountInfoSection;
