import React, { useRef } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { colorsTheme } from 'resources/theme/styled/colors';
import { useFormikContext } from 'formik';
import { ALLOWED_UPLOAD_TYPES } from 'constants/documents';
import { verifyFilesThatCanBeUploaded } from 'helpers/documentsHelpers';

// components
import EllipsisTooltip from 'components/Tooltips/EllipsisTooltip';
import { Text, Button, IconSVG, Row, Col, Modal } from '@ui';
import { ReactComponent as UploadIcon } from 'resources/icons/remix-icons/upload-2-line.svg';

export interface UploadDocumentButtonProps {
  onUploadButtonClick?: (triggerClickOnInputRef: () => void) => void;
}

interface IProps extends UploadDocumentButtonProps {
  multiple?: boolean;
  disabled?: boolean;
  onFilesSelect: (files: File[]) => void;
}

const UploadDocumentButton = ({
  onFilesSelect,
  multiple = true,
  disabled,
  onUploadButtonClick,
}: IProps) => {
  const uploadInputRef = useRef<any>(null);
  const { t } = useTranslation(['documents', 'onboarding', 'common']);
  const { isSubmitting } = useFormikContext();

  const onBrowseClick = () => {
    if (onUploadButtonClick) {
      onUploadButtonClick(() => uploadInputRef.current.click());
    } else {
      uploadInputRef.current.click();
    }
  };

  const onFileSelect = () => {
    if (uploadInputRef.current && uploadInputRef.current.files.length) {
      const allowedFiles = verifyFilesThatCanBeUploaded(
        Array.prototype.slice.call(uploadInputRef.current.files),
      );

      if (allowedFiles.rejected.length) {
        Modal.error({
          width: 650,
          title: t('upload_documents_warning_modal.title'),
          content: (
            <>
              {allowedFiles.rejected.map((e, i) => (
                <Row key={i} wrap={false} gutter={[16, 16]}>
                  <Col span={6}>
                    <Text key={i} variant="body1">
                      <EllipsisTooltip
                        maxTextContainerWidth="100%"
                        title={e.file.name}
                      >
                        {e.file.name}
                      </EllipsisTooltip>
                    </Text>
                  </Col>
                  <Col flex="auto">
                    <Text key={i} variant="body1">
                      {t(`upload_documents_warning_modal.errors.${e.reason}`)}
                    </Text>
                  </Col>
                </Row>
              ))}
            </>
          ),
        });
      }

      if (allowedFiles.approved.length) {
        onFilesSelect(allowedFiles.approved);
      }

      uploadInputRef.current.value = '';
    }
  };

  return (
    <>
      <Button onClick={onBrowseClick} disabled={isSubmitting || disabled}>
        <StyledIconSVG component={UploadIcon} color={colorsTheme.colorDark} />{' '}
        {t('upload_button')}
      </Button>

      <input
        ref={uploadInputRef}
        type="file"
        multiple={multiple}
        accept={ALLOWED_UPLOAD_TYPES}
        style={{ display: 'none' }}
        onChange={() => onFileSelect()}
      />
    </>
  );
};

const StyledIconSVG = styled(IconSVG)`
  margin-right: ${({ theme }) => theme.marginXXs};
`;

export default UploadDocumentButton;
