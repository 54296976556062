import React, { useEffect, useMemo, useState } from 'react';

// helpers
import styled from 'styled-components';
import useFetch from 'hooks/useFetch';
import useTranslation from 'hooks/useTranslation';
import { getBadges } from 'redux/actions/app';
import { StateModel } from 'redux/reducers';
import { userHasPendingApproval } from 'helpers/approvalHelpers';
import { updateActiveApplication } from 'redux/actions/applications';
import { useDispatch, useSelector } from 'react-redux';
import { accountManagementRulesAPI } from 'api/accountManagement/accountManagementRulesAPI';
import {
  ApprovalWorkflowEntryTypes,
  AccountManagementApprovalStatuses,
} from 'enums/approvalManagement/approvalManagement';

// components
import {
  Divider,
  Button,
  ModalDialog,
  RequiredPropsForModalDialogModel,
  Col,
  Row,
  Message,
} from '@ui';
import InnerContent from './InnerContent';
import DivAlignCenter from 'components/Additional/DivAlignCenter';
import LoadingWrapper from 'components/WrapperComponents/LoadingWrapper';
import WarningIconWithText from 'components/Additional/WarningIconWithText';
import ConfirmActionModalDialog, {
  FormValuesModel,
} from 'components/ModalDialogs/ConfirmActionModalDialog';

enum ACTION_TYPE {
  APPROVE = 1,
  REJECT,
}

interface IProps extends RequiredPropsForModalDialogModel {
  workflowId: string | null;
  disabled?: boolean;
}

const ApprovalEntryModal = ({
  workflowId,
  isVisible,
  disabled,
  closeCallback,
}: IProps) => {
  const { t } = useTranslation(['account_management', 'common']);
  const dispatch = useDispatch();
  const [selectedAction, setSelectedAction] = useState<ACTION_TYPE | null>(
    null,
  );

  const [canReviewApprovalWorkflow, setCanReviewApprovalWorkflow] =
    useState<boolean>(false);

  // Needed to close the confirmation modal dialog
  // if main modal was closed
  useEffect(() => {
    if (selectedAction && !isVisible) {
      setSelectedAction(null);
    }
  }, [isVisible, selectedAction]);

  const currentUserId = useSelector<StateModel, string>(
    (state) => state.auth.profileData?._id || '',
  );
  const buttonGridSizes = { xl: 6, lg: 8, md: 8, sm: 24, xs: 24 };

  const confirmationModalWordings = useMemo(() => {
    if (selectedAction === ACTION_TYPE.APPROVE) {
      return {
        title: t('confirm_approve_title', { ns: 'common' }),
        description: t(
          'pending_approvals.approval_details_modal.approve_description',
        ),
        submitButton: t('approve', { ns: 'common' }),
      };
    } else if (selectedAction === ACTION_TYPE.REJECT) {
      return {
        title: t('confirm_approve_title', { ns: 'common' }),
        description: t(
          'pending_approvals.approval_details_modal.reject_description',
        ),
        submitButton: t('reject', { ns: 'common' }),
      };
    }
  }, [selectedAction]);

  const initialValues = useMemo<FormValuesModel>(() => {
    return { reason: '' };
  }, []);

  const { response, loading } = useFetch(
    () =>
      workflowId
        ? accountManagementRulesAPI.getApprovalWorkflowById(workflowId)
        : null,
    [workflowId],
  );

  useEffect(() => {
    if (response) {
      const approvalsArray = response?.approvalProgressStateSets
        ? response.approvalProgressStateSets.map((e) => e.progressStateItems)
        : [];

      const isUserPendingApproval = userHasPendingApproval(
        currentUserId,
        approvalsArray,
      );

      setCanReviewApprovalWorkflow(isUserPendingApproval);
    }
  }, [response]);

  const handleSubmit = async (values: FormValuesModel) => {
    const { reason } = values;

    if (selectedAction === ACTION_TYPE.APPROVE) {
      await accountManagementRulesAPI.approveApprovalById(
        workflowId as string,
        reason,
      );
    } else {
      await accountManagementRulesAPI.rejectApprovalById(
        workflowId as string,
        reason,
      );
    }

    const response = await accountManagementRulesAPI.getApprovalWorkflowById(
      workflowId as string,
    );

    if (
      (response.entry.status === AccountManagementApprovalStatuses.Approved ||
        response.entry.status === AccountManagementApprovalStatuses.Rejected) &&
      response.entry.type ===
        ApprovalWorkflowEntryTypes.ACCOUNT_MNGT_UPDATE_CLIENTGROUP
    ) {
      dispatch(updateActiveApplication(false, false));
    }

    const messageText =
      selectedAction === ACTION_TYPE.APPROVE
        ? t('pending_approvals.approval_details_modal.approve_success_message')
        : t('pending_approvals.approval_details_modal.reject_success_message');

    dispatch(getBadges());
    Message.success(messageText);
    setSelectedAction(null);
    closeCallback(true);
  };

  const modalTitle = useMemo(() => {
    if (canReviewApprovalWorkflow && disabled) {
      return (
        <DivAlignCenter>
          <StyledTitle>
            {t('pending_approvals.approval_details_modal.title')}
          </StyledTitle>
          <StyledWarningInfoNot2FA
            text={t('pending_approvals.approval_details_modal.warning_not_2fa')}
          />
        </DivAlignCenter>
      );
    }

    return t('pending_approvals.approval_details_modal.title');
  }, [canReviewApprovalWorkflow, disabled]);

  return (
    <ModalDialog
      title={modalTitle}
      isVisible={isVisible}
      closeCallback={closeCallback}
      submitButtonProps={{ hidden: true }}
      cancelButtonProps={{ text: t('close', { ns: 'common' }) }}
    >
      <LoadingWrapper loading={loading}>
        {response && (
          <>
            <InnerContent approvalEntry={response} />
            {canReviewApprovalWorkflow && (
              <>
                <Divider />
                <Row gutter={[16, 16]} justify="end">
                  <Col {...buttonGridSizes}>
                    <StyledButton
                      disabled={disabled}
                      danger
                      size="large"
                      onClick={() => setSelectedAction(ACTION_TYPE.REJECT)}
                    >
                      {t('reject', { ns: 'common' })}
                    </StyledButton>
                  </Col>

                  <Col {...buttonGridSizes}>
                    <StyledButton
                      disabled={disabled}
                      size="large"
                      onClick={() => setSelectedAction(ACTION_TYPE.APPROVE)}
                    >
                      {t('approve', { ns: 'common' })}
                    </StyledButton>
                  </Col>
                </Row>
              </>
            )}
          </>
        )}
      </LoadingWrapper>
      <ConfirmActionModalDialog
        title={confirmationModalWordings?.title || ''}
        description={confirmationModalWordings?.description}
        submitButtonText={confirmationModalWordings?.submitButton || ''}
        onSubmit={handleSubmit}
        isVisible={!!selectedAction}
        initialValues={initialValues}
        closeCallback={() => setSelectedAction(null)}
      />
    </ModalDialog>
  );
};

const StyledButton = styled(Button)`
  width: 100%;
`;

const StyledWarningInfoNot2FA = styled(WarningIconWithText)`
  margin-left: ${({ theme }) => theme.marginXs};
`;

const StyledTitle = styled.div`
  margin-right: ${({ theme }) => theme.marginXs};
`;

export default ApprovalEntryModal;
