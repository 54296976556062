import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { RoutePaths } from 'routes/routes';
import { StateModel } from 'redux/reducers';
import { changeView } from 'redux/actions/view';
import { MenuItemModel } from 'hooks/useSidebarMenu';
import { getDefaultOpenedKeys } from 'helpers/commonHelpers';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { StateModel as SettingsStateModel } from 'redux/reducers/settings';

// components
import DivAlignCenter from 'components/Additional/DivAlignCenter';
import RenderBadgeByRoutePath from 'components/Additional/RenderBadgeByRoutePath';
import { ReactComponent as ArrowLeft } from 'resources/icons/remix-icons/arrow-left-line.svg';
import { Link, Divider, IconSVG, Menu, MenuItem, SubMenu } from '@ui';

interface IProps {
  menuItems: MenuItemModel[];
}

const SidebarMenu = ({ menuItems }: IProps) => {
  const { t } = useTranslation('onboarding');
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { layoutVariant } = useSelector<StateModel, SettingsStateModel>(
    (state) => state.settings,
  );

  const getMenuItem = (menuItem: MenuItemModel) => {
    const { title, path, subItems, disabled } = menuItem;

    if (!menuItem.subItems.length) {
      return (
        <StyledMenuItem key={path} itemKey={path} disabled={disabled}>
          <MenuItemTitle>
            <StyledLink
              href={path}
              onClick={(e) => {
                e && e.preventDefault();
                dispatch(changeView(() => navigate(path)));
              }}
            >
              {title}
            </StyledLink>
            <RenderBadgeByRoutePath path={path} />
          </MenuItemTitle>
        </StyledMenuItem>
      );
    }

    return (
      <SubMenu
        key={path}
        itemKey={path}
        disabled={disabled}
        className={path == location.pathname ? 'menu-submenu-selected' : ''}
        title={
          <MenuItemTitle>
            <StyledLink
              href={path}
              onClick={(e) => {
                e && e.preventDefault();
                dispatch(changeView(() => navigate(path)));
              }}
            >
              {title}
            </StyledLink>
          </MenuItemTitle>
        }
      >
        {subItems.map((e) => getMenuItem(e))}
      </SubMenu>
    );
  };

  return (
    <MenuWrapper>
      {layoutVariant == 'OnboardingClientGroup' ? (
        <>
          <LinkWrapper>
            <StyledLink to={RoutePaths.OnboardingPage}>
              <DivAlignCenter>
                <StyledIconSVG component={ArrowLeft} />
                {t('go_back_to_onboarding_btn')}
              </DivAlignCenter>
            </StyledLink>
          </LinkWrapper>
          <StyledDivider />
        </>
      ) : null}
      <Menu
        mode="inline"
        selectedKeys={getDefaultOpenedKeys(location.pathname)}
        defaultOpenKeys={getDefaultOpenedKeys(location.pathname)}
      >
        {menuItems.map(getMenuItem)}
      </Menu>
    </MenuWrapper>
  );
};

const MenuWrapper = styled.div`
  padding: ${({ theme }) => theme.paddingXl} ${({ theme }) => theme.paddingSm};
  margin: ${({ theme }) => theme.containerPadding};
  background-color: ${({ theme }) => theme.colorDarkD1};
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  min-height: 95%;
  width: auto;
`;

const StyledMenuItem = styled(MenuItem)`
  overflow: unset !important;
  display: flex;
  align-items: center;

  &.ant-menu-item a {
    color: ${({ theme }) => theme.colorLight} !important;
    &:hover {
      color: ${({ theme }) => theme.colorPrimary} !important;
      filter: none;
    }
  }

  &.ant-menu-item-selected a {
    color: ${({ theme }) => theme.colorPrimary} !important;
    &:hover {
      color: ${({ theme }) => theme.colorPrimary} !important;
      filter: none;
    }
  }
`;

const MenuItemTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 10px;

  .ant-menu-item a {
    color: ${({ theme }) => theme.colorLight} !important;
  }
  .ant-menu-item-selected a {
    color: ${({ theme }) => theme.colorPrimary} !important;
  }
`;

const StyledDivider = styled(Divider)`
  margin-bottom: 0px;
  margin-top: ${({ theme }) => theme.marginSm};
`;

const LinkWrapper = styled.div`
  padding: 0px 24px;
`;

const StyledIconSVG = styled(IconSVG)`
  margin-right: ${({ theme }) => theme.marginXs};
`;

const StyledLink = styled(Link)`
  font-size: ${({ theme }) => theme.fontSizeXXs};
`;

export default SidebarMenu;
