import React, { useMemo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { COUNTRY_CODES } from 'constants/countryCodes';
import { TravelCountryModel } from 'typings/profile/travelNotifications';
import { formatDateToString } from 'helpers/dateHelpers';

// components
import { Table, RequiredPropsForTableModel, TableColumnModel } from '@ui';

type IProps = RequiredPropsForTableModel<TravelCountryModel>;

const CountriesTable = (props: IProps) => {
  const { t } = useTranslation('profile');

  const tableColumns = useMemo<TableColumnModel[]>(() => {
    return [
      {
        width: '50%',
        title: t(
          'travel_notifications.add_travel_notification_modal.form_fields.country',
        ),
        key: 'country',
        render: (record: TravelCountryModel) => COUNTRY_CODES[record.country],
      },
      {
        title: t(
          'travel_notifications.add_travel_notification_modal.form_fields.departure_date',
        ),
        key: 'departureDate',
        render: (record: TravelCountryModel) =>
          formatDateToString(record.departureDate),
      },
      {
        title: t(
          'travel_notifications.add_travel_notification_modal.form_fields.return_date',
        ),
        key: 'returnDate',
        render: (record: TravelCountryModel) =>
          formatDateToString(record.returnDate),
      },
    ];
  }, []);

  return <StyledTable {...props} columns={tableColumns} />;
};

const StyledTable = styled(Table)`
  display: flex;

  .ant-spin-nested-loading {
    width: 100%;
  }

  .ant-table {
    margin: 0 !important;
    border-bottom: ${({ theme }) => `1px solid ${theme.colorDarkL4}`};
  }
`;

export default CountriesTable;
