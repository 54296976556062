import React, { useMemo } from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';
import { checkAppVersionAndRefreshIfNeeded } from 'helpers/commonHelpers';
import {
  SigninSubmitUsernameResponseModel,
  userAPI,
} from '../../../../../api/profile/userAPI';

// components
import SignInUsernameForm, {
  FormValuesModel,
} from '../../../../../components/Forms/TemplateForms/Auth/SignIn/SignInUsernameForm';

interface IProps {
  onSubmit: (
    username: string,
    response: SigninSubmitUsernameResponseModel,
  ) => void;
}

const SignInUsername = ({ onSubmit }: IProps) => {
  const { t } = useTranslation('common');
  const initialFormValues: FormValuesModel = useMemo(
    () => ({
      username: '',
    }),
    [],
  );

  const handleSubmit = async (values: FormValuesModel) => {
    const response = await userAPI.checkUsername({ username: values.username });
    checkAppVersionAndRefreshIfNeeded(
      response.swaggerVersion,
      t('new_version_page_refresh'),
    );

    if (response) {
      onSubmit(values.username, response);
    }
  };

  return (
    <SignInUsernameForm
      handleSubmit={handleSubmit}
      initialFormValues={initialFormValues}
    />
  );
};

export default SignInUsername;
