import * as Yup from 'yup';
import { validatePhoneNumbers } from 'helpers/crmHelpers';
import {
  CRM_NAME_REG,
  MAX_ACCOUNT_NAME_LENGTH,
  MIN_ACCOUNT_NAME_LENGTH,
  ONE_LOWER_LETTER,
  ONE_NUMBER,
  ONE_SPECIAL_CHARACTER,
  ONE_TIME_PASSWORD_LENGTH,
  ONE_UPPER_LETTER,
  PASSWORD_MAX_LENGTH,
  PASSWORD_MIN_LENGTH,
  SPECIAL_CHARACTERS_REG,
  SWIFT_CHARACTERS_REG,
} from '../constants/regularExpressions';

export const STRING_VALIDATION_RULE = Yup.string().trim();
export const NULLABLE_STRING_VALIDATION_RULE =
  STRING_VALIDATION_RULE.nullable();
export const NULLABLE_DATE_VALIDATION_RULE = Yup.date().nullable();
export const NULLABLE_NUMBER_VALIDATION_RULE = Yup.number().nullable();
export const NULLABLE_BOOLEAN_VALIDATION_RULE = Yup.boolean().nullable();
export const NULLABLE_ARRAY_VALIDATION_RULE = Yup.array().nullable();
export const NULLABLE_OBJECT_VALIDATION_RULE = Yup.object().nullable();
export const NOT_REQUIRED_BOOLEAN_VALIDATION_RULE = Yup.boolean().notRequired();

export const STRING_WITH_NO_SPECIAL_CHARS_VALIDATION_RULE =
  STRING_VALIDATION_RULE.matches(SPECIAL_CHARACTERS_REG, {
    message: { key: 'form:validation_messages.no_special_characters' },
  });

export const SWIFT_RELATED_TEXT_VALIDATION_RULE =
  STRING_VALIDATION_RULE.matches(SWIFT_CHARACTERS_REG, {
    message: { key: 'form:validation_messages.no_special_characters' },
  });

export const CRM_NAME_VALIDATION_RULE = STRING_VALIDATION_RULE.matches(
  CRM_NAME_REG,
  {
    message: { key: 'form:validation_messages.no_special_characters' },
    excludeEmptyString: true,
  },
);

export const CRM_NAME_NO_SPECIAL_CHARACTERS_VALIDATION_RULE =
  STRING_VALIDATION_RULE.matches(SWIFT_CHARACTERS_REG, {
    message: { key: 'form:validation_messages.no_special_characters' },
  });

export const EMAIL_VALIDATION_RULE = STRING_VALIDATION_RULE.email({
  key: 'form:validation_messages.invalid_value_with_label',
  values: { label: 'common:email' },
});

export const PASSWORD_VALIDATION_RULE = STRING_VALIDATION_RULE.min(
  PASSWORD_MIN_LENGTH,
  {
    key: 'form:validation_messages.min_characters',
    values: {
      number: PASSWORD_MIN_LENGTH,
    },
  },
)
  .max(PASSWORD_MAX_LENGTH, {
    key: 'form:validation_messages.max_characters',
    values: {
      number: PASSWORD_MAX_LENGTH,
    },
  })
  .matches(ONE_UPPER_LETTER, {
    message: {
      key: 'form:validation_messages.must_contain_capital_letter_with_label',
      values: { label: 'auth:signin.steps.signin.form_fields.password' },
    },
  })
  .matches(ONE_LOWER_LETTER, {
    message: {
      key: 'form:validation_messages.must_contain_letter_with_label',
      values: { label: 'auth:signin.steps.signin.form_fields.password' },
    },
  })
  .matches(ONE_NUMBER, {
    message: {
      key: 'form:validation_messages.must_contain_number_with_label',
      values: { label: 'auth:signin.steps.signin.form_fields.password' },
    },
  })
  .matches(ONE_SPECIAL_CHARACTER, {
    message: {
      key: 'form:validation_messages.must_contain_special_character_with_label',
      values: { label: 'auth:signin.steps.signin.form_fields.password' },
    },
  });

export const PHONE_ASYNC_VALIDATION_RULE = STRING_VALIDATION_RULE.test(
  'async-validation',
  'custom_errors:validation_phone_number.invalid_phone_number',
  async function (value) {
    // Call the custom asynchronous validation function
    const errors = await validatePhoneNumbers([value as string]);
    if (errors) {
      return false;
    }
    return true;
  },
);

export const OTP_VALIDATION_RULE = NULLABLE_STRING_VALIDATION_RULE.min(
  ONE_TIME_PASSWORD_LENGTH,
  {
    key: 'form:validation_messages.min_characters',
    values: {
      number: ONE_TIME_PASSWORD_LENGTH,
    },
  },
);

export const ACCOUNT_NAME_VALIDATION_RULE =
  SWIFT_RELATED_TEXT_VALIDATION_RULE.min(MIN_ACCOUNT_NAME_LENGTH, {
    key: 'form:validation_messages.min_characters',
    values: { number: MIN_ACCOUNT_NAME_LENGTH },
  }).max(MAX_ACCOUNT_NAME_LENGTH, {
    key: 'form:validation_messages.max_characters',
    values: { number: MAX_ACCOUNT_NAME_LENGTH },
  });
