import React, { memo, useMemo, useState } from 'react';

// helpers
import useFetch from 'hooks/useFetch';
import useTranslation from 'hooks/useTranslation';
import { getBadges } from 'redux/actions/app';
import { useDispatch } from 'react-redux';
import { RequestModel } from '../../..';
import { RequestTypes } from 'enums/connections/connections';
import { connectionsAPI } from 'api/connections/connectionsAPI';
import { personalDetailsAPI } from 'api/profile/personalDetailsAPI';
import { connectionsAdapter } from 'apiAdapters/connections/connectionsAdapter';
import { IContactEmail, IContactPhone } from 'typings/application/contact';
import { getPrimaryEmail, getPrimaryPhoneNumber } from 'helpers/crmHelpers';

// components
import { Text, RequiredPropsForModalDialogModel, Message, Modal } from '@ui';
import KYCVerificationModal, {
  FormValuesModel as KycVerificationFormValuesModel,
} from 'components/ModalDialogs/TemplateModalDialogs/Connections/KYCVerificationModal';
import ShareContactRequestModal, {
  FormValuesModel,
} from 'components/ModalDialogs/TemplateModalDialogs/Connections/ShareContactRequestModal';

interface IProps extends RequiredPropsForModalDialogModel {
  data: RequestModel;
  updateRequests: () => void;
}

export type QuestionnaireTemplateModel = {
  questionKey: string;
  text: string;
  answer: boolean | undefined;
};

const ShareContactRequestModalDialog = memo(
  ({ isVisible, closeCallback, data, updateRequests }: IProps) => {
    const { t } = useTranslation('connections');
    const dispatch = useDispatch();
    const [isKYCVerificationVisible, setIsKYCVerificationVisible] =
      useState(false);

    const { response: request } = useFetch(
      () =>
        data.id && isVisible ? connectionsAPI.fetchRequestById(data.id) : null,
      [data.id, isVisible],
    );

    const { response: existingContact } = useFetch(
      () =>
        isVisible ? personalDetailsAPI.fetchCurrentContactDetails() : null,
      [isVisible],
    );

    const requestInitialFormValues = useMemo<FormValuesModel | null>(() => {
      if (request && existingContact) {
        return {
          actionType: null,
          requestType: RequestTypes.HandoffApplication,
          data: {
            clientGroupName: request.details.clientGroupName,
            existingContact: {
              firstName: existingContact.contact.firstName,
              middleName: existingContact.contact.middleName,
              lastName: existingContact.contact.lastName,
              email: getPrimaryEmail(existingContact.contact.emails),
              phoneNumber: getPrimaryPhoneNumber(
                existingContact.contact.phoneNumbers,
              ),
              isPep: existingContact.contact.isPEP,
            },
            newContact: {
              email: getPrimaryEmail(
                request.details.relatedContact?.emails as IContactEmail[],
              ),
              firstName: request.details.relatedContact?.firstName || '',
              middleName: request.details.relatedContact?.middleName || '',
              lastName: request.details.relatedContact?.lastName || '',
              phoneNumber: getPrimaryPhoneNumber(
                request.details.relatedContact?.phoneNumbers as IContactPhone[],
              ),
              isPep: !!request.details.relatedContact?.isPEP,
            },
          },
          handoffRequestData: null,
        };
      }

      return null;
    }, [request, existingContact]);

    const kycVerificationInitialValues =
      useMemo<KycVerificationFormValuesModel | null>(() => {
        if (!request) {
          return null;
        }

        return {
          agreement: null,
          questions: request.details.questionnaireTemplate
            ? request.details.questionnaireTemplate.questions.map(
                (question) => ({
                  questionKey: question.key,
                  text: question.text,
                  answer: undefined,
                }),
              )
            : [],
        };
      }, [request]);

    const onKycVerificationSubmit = async (
      values: KycVerificationFormValuesModel,
    ) => {
      const payload = connectionsAdapter.generateShareContactBody(values);
      await connectionsAPI.shareClientGroupRequest(data.id, payload);
      dispatch(getBadges());
      Message.success(
        t('requests.kyc_verification_modal.success_sharing_contact_message'),
      );
      updateRequests();
    };

    const onSubmit = async (values: FormValuesModel) => {
      switch (values.actionType) {
        case 'reject': {
          Modal.confirm({
            title: t(
              'requests.view_request_modal.share_contact_request.reject_request.title',
            ),
            content: (
              <>
                <Text gutterBottom>
                  {t(
                    'requests.view_request_modal.share_contact_request.reject_request.description',
                  )}
                </Text>
                <ul>
                  <li>
                    <Text>
                      {t(
                        'requests.view_request_modal.share_contact_request.reject_request.point_1',
                      )}
                    </Text>
                  </li>
                  <li>
                    <Text>
                      {t(
                        'requests.view_request_modal.share_contact_request.reject_request.point_2',
                      )}
                    </Text>
                  </li>
                </ul>
              </>
            ),
            icon: null,
            closable: true,
            maskClosable: true,
            width: 600,
            okText: t('yes_continue', { ns: 'common' }),
            cancelText: t('cancel', { ns: 'common' }),
            async onOk() {
              await connectionsAPI.rejectClientGroupRequest(data.id);
              dispatch(getBadges());
              Message.success(
                t(
                  'requests.view_request_modal.share_contact_request.success_creating_contact_message',
                ),
              );
              updateRequests();
              closeCallback();
            },
          });
          return;
        }
        case 'approve': {
          setIsKYCVerificationVisible(true);
        }
      }
    };

    const closeKycVerificationCallback = (wasSubmitted?: boolean) => {
      setIsKYCVerificationVisible(false);

      if (wasSubmitted) {
        closeCallback();
      }
    };

    return (
      <>
        <ShareContactRequestModal
          isVisible={isVisible}
          initialValues={requestInitialFormValues}
          onSubmit={onSubmit}
          closeCallback={closeCallback}
        />
        <KYCVerificationModal
          isVisible={isKYCVerificationVisible}
          initialValues={kycVerificationInitialValues}
          closeModalAfterError={false}
          onSubmit={onKycVerificationSubmit}
          closeCallback={closeKycVerificationCallback}
        />
      </>
    );
  },
);

export default ShareContactRequestModalDialog;
