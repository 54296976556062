export function bufferToBinaryString(buf: BufferSource): string {
  const binstr = Array.prototype.map
    .call(buf, function (ch) {
      return String.fromCharCode(ch);
    })
    .join('');

  return binstr;
}

export function bufferToBase64(arr: BufferSource): string {
  const binstr = bufferToBinaryString(arr);
  return btoa(binstr);
}

export function binaryStringToBuffer(binstr: string): BufferSource {
  let buf: any;

  if ('undefined' !== typeof Uint8Array) {
    buf = new Uint8Array(binstr.length);
  } else {
    buf = [];
  }

  Array.prototype.forEach.call(binstr, function (ch, i) {
    buf[i] = ch.charCodeAt(0);
  });

  return buf;
}

export function base64ToBuffer(base64: string) {
  const binstr = atob(base64);
  const buf = binaryStringToBuffer(binstr);
  return buf;
}
