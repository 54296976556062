import React from 'react';

// Helpers & Utils
import { documentsAPI } from 'api/documentsAPI';

import { UploadStatus } from 'constants/documents';
import { IDocumentBase } from 'typings/documents/documents';
import {
  IFileHash,
  setUploadStatus,
  setUploadFinishStatus,
  setUploadFileStatus,
  setFileChunks,
  setChunkStatus,
  updateChunkPercentage,
  setFileDataBaseId,
  setDataBaseFile,
} from 'redux/actions/upload';

// Redux
import { connect } from 'react-redux';
import { IChunkHash } from 'helpers/documentsHelpers';

// ** components **
import UploadDocumentNotification from './UploadDocumentNotification';

interface IProps {
  uploadStatus: UploadStatus;
  files: IFileHash;
  shouldShowUploadNotification: boolean;
  temporaryToken: string;
  setFileDataBaseId: (fileId: string, dataBaseId: string) => void;
  setDataBaseFile: (fileId: string, dataBaseEntry: IDocumentBase) => void;
  setUploadStatus: (status: UploadStatus) => void;
  setUploadFinishStatus: (isFinished: boolean) => void;
  setUploadFileStatus: (fileId: string, status: UploadStatus) => void;
  setFileChunks: (fileId: string, chunksArray: IChunkHash) => void;
  setChunkStatus: (
    fileId: string,
    chunkId: string,
    status: UploadStatus,
  ) => void;
  updateChunkPercentage: (
    fileId: string,
    chunkId: string,
    percentega: number,
  ) => void;
}

class UploadDocumentsService extends React.Component<IProps, any> {
  // Remove beforeunload listener
  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.keepOnPageHandler);
  }

  componentDidUpdate(prevProps: IProps) {
    // Start uploading files
    if (prevProps.uploadStatus !== this.props.uploadStatus) {
      if (this.props.uploadStatus === UploadStatus.ACTIVE) {
        window.addEventListener('beforeunload', this.keepOnPageHandler);
        this.uploadFiles();
      } else {
        window.removeEventListener('beforeunload', this.keepOnPageHandler);
      }
    }
  }

  keepOnPageHandler(e: any) {
    const message =
      'Warning!\n\nNavigating away from this page will stop the upload process.';

    if (typeof e === 'undefined') {
      e = window.event;
    }

    if (e) {
      e.returnValue = message;
    }

    return message;
  }

  splitFileToChunks(fileData: File, chunkSize: number, fileId: string) {
    const fileChunkList: IChunkHash = {};

    let cur = 0;
    let index = 1;
    while (cur < fileData.size) {
      const next = cur + chunkSize;
      fileChunkList[`${fileId}-${index}`] = {
        index: index - 1,
        file: fileData.slice(cur, next),
        status: UploadStatus.WAIT,
        uploadPercentage: 0,
      };
      index += 1;
      cur = next;
    }

    return fileChunkList;
  }

  // Upload file by chunks
  async uploadFiles() {
    const {
      files,
      setUploadStatus,
      setUploadFinishStatus,
      setUploadFileStatus,
    } = this.props;

    if (!files) {
      return;
    }

    const hasActiveUploadingFile = Object.keys(files).find(
      (key) => files[key].status === UploadStatus.UPLOADING,
    );

    if (!hasActiveUploadingFile) {
      const willUploadFileList = Object.keys(files)
        .sort()
        .filter((key) => files[key].status === UploadStatus.WAIT);

      if (willUploadFileList.length) {
        // Get first file from upload queue
        const uploadFileId = willUploadFileList[0];
        try {
          await this.handleUploadFile(uploadFileId);
        } catch {
          setUploadFileStatus(uploadFileId, UploadStatus.FAILED);
        }
      } else {
        // Uploaded all files
        setUploadStatus(UploadStatus.WAIT);
        setUploadFinishStatus(true);
      }
    }
  }

  async handleUploadFile(fileId: string) {
    const { files, setFileChunks, setUploadFileStatus } = this.props;
    if (!files[fileId]) return;

    const fileObject = files[fileId];

    // Set uploading status to file
    setUploadFileStatus(fileId, UploadStatus.UPLOADING);
    // Generate file chunk list
    const chunkList = this.splitFileToChunks(
      fileObject.file,
      fileObject.chunkSize,
      fileId,
    );

    // Store chunks to redux file item
    setFileChunks(fileId, chunkList);

    // Start uploading chunks
    setTimeout(async () => {
      await this.uploadChunks(fileId);
    }, 0);
  }

  async uploadChunks(fileId: string) {
    const file = this.props.files[fileId];
    const { chunksArray } = file;
    // Filter only chunks that needs to be uploaded
    const willUploadChunkList = Object.keys(chunksArray).filter(
      (key) => chunksArray[key].status === UploadStatus.WAIT,
    );

    if (willUploadChunkList.length) {
      const uploadChunkId = willUploadChunkList[0];

      try {
        const formData = new FormData();
        const { file, fileName, description, tags, association } =
          this.props.files[fileId];
        const temporaryToken = this.props.temporaryToken;

        formData.append('id', fileId);
        formData.append('chunk', chunksArray[uploadChunkId].file);
        formData.append('chunkIndex', String(chunksArray[uploadChunkId].index));
        formData.append('chunks', String(Object.keys(chunksArray).length));
        formData.append('contentType', file.type);
        formData.append('name', fileName);
        formData.append('fileName', file.name);

        if (description && description.length) {
          formData.append('description', String(description));
        }

        if (tags && tags.length) {
          formData.append('tags', String(tags));
        }

        if (association) {
          formData.append('itemId', association.id);
          formData.append('itemType', association.type);
        }

        const response = await documentsAPI.uploadDocument(
          formData,
          this.createProgressHandler((percentage: number) =>
            this.props.updateChunkPercentage(fileId, uploadChunkId, percentage),
          ),
          temporaryToken,
        );

        if (response && response._id) {
          this.props.setFileDataBaseId(fileId, response._id);
          this.props.setDataBaseFile(fileId, response);
        }

        // Mark chunk as DONE
        this.props.setChunkStatus(fileId, uploadChunkId, UploadStatus.DONE);

        // Upload next chunk
        setTimeout(() => {
          this.uploadChunks(fileId);
        }, 0);
      } catch {
        this.props.setUploadFileStatus(fileId, UploadStatus.FAILED);
        this.uploadFiles();
      }
    } else {
      // Mark file as DONE
      this.props.setUploadFileStatus(fileId, UploadStatus.DONE);

      // Continue uploading next files
      this.uploadFiles();
    }
  }

  //Handle chunk progress percentage
  createProgressHandler(updatePercentageCallback: any) {
    return function (e: any) {
      const chunkPercentage = ((e.loaded / e.total) * 100) | 0;
      updatePercentageCallback(chunkPercentage);
    };
  }

  render() {
    const { shouldShowUploadNotification } = this.props;

    return (
      <>{shouldShowUploadNotification && <UploadDocumentNotification />}</>
    );
  }
}

const mapStateToProps = (state: any) => ({
  uploadStatus: state.upload.status,
  files: state.upload.files,
  shouldShowUploadNotification: state.upload.shouldShowUploadNotification,
  temporaryToken: state.auth.temporaryToken,
});

const mapDispatchToProps = (dispatch: any) => ({
  setUploadStatus: (uploadStatus: UploadStatus) =>
    dispatch(setUploadStatus(uploadStatus)),
  setUploadFinishStatus: (isFinished: boolean) =>
    dispatch(setUploadFinishStatus(isFinished)),
  setUploadFileStatus: (fileId: string, status: UploadStatus) =>
    dispatch(setUploadFileStatus(fileId, status)),
  setFileChunks: (fileId: string, chunksArray: IChunkHash) =>
    dispatch(setFileChunks(fileId, chunksArray)),
  setChunkStatus: (fileId: string, chunkId: string, status: UploadStatus) =>
    dispatch(setChunkStatus(fileId, chunkId, status)),
  setFileDataBaseId: (fileId: string, dataBaseId: string) =>
    dispatch(setFileDataBaseId(fileId, dataBaseId)),
  setDataBaseFile: (fileId: string, dataBaseEntry: IDocumentBase) =>
    dispatch(setDataBaseFile(fileId, dataBaseEntry)),
  updateChunkPercentage: (
    fileId: string,
    chunkId: string,
    percentage: number,
  ) => dispatch(updateChunkPercentage(fileId, chunkId, percentage)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UploadDocumentsService as any) as any;
