import React, { useMemo } from 'react';

// helpers
import moment from 'moment';
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { getBadges } from 'redux/actions/app';
import { scrollTop } from 'helpers/commonHelpers';
import { StateModel } from 'redux/reducers';
import { transfersAPI } from 'api/accounting/transfersAPI';
import { IAccountModel } from 'typings/onboarding/account';
import { transfersAdapter } from 'apiAdapters/accounting/transfersAdapter';
import { AccountProcessingTypes } from 'enums/accounting/transfers';
import { useDispatch, useSelector } from 'react-redux';
import { OTHER_BANK_ACCOUNT_OPTION } from 'components/Forms/FormComponents/Autocompletes/Accounting/RecipientBankAccountAutocomplete';
import { InternalTransferPrefillDataFromQuery } from 'api/accounting/transactionsAPI';
import {
  convertAmountFromBigIntToInt,
  generateAccountFullName,
} from 'helpers/accountsHelpers';

// components
import WarningIconWithText from 'components/Additional/WarningIconWithText';
import InternalTransferForm, {
  FormValuesModel,
} from 'components/Forms/TemplateForms/Transfers/InternalTransferForm';
import { Message } from '@ui';

interface IProps {
  prefillData?: InternalTransferPrefillDataFromQuery | null;
}

const InternalTransfer = ({ prefillData }: IProps) => {
  const { t } = useTranslation('transfers');
  const dispatch = useDispatch();
  const isTwoFactorVerificationEnabled = useSelector<StateModel, boolean>(
    (state) => state.auth.isTwoFactorVerificationEnabled,
  );

  const emptyTransactionInitialFormData = useMemo<FormValuesModel>(() => {
    return {
      sender: {
        accountNumber: '',
        reference: '',
        processingType: null,
        account: null,
      },
      recipient: {
        processingType: null,
        accountNumber: '',
        reference: '',
        account: null,
        templateId: null,
        template: null,
      },
      amount: null,
      transactionCurrencyId: null,
      valueDate: moment(),
      documents: [],
      transactionPurpose: '',
    };
  }, []);

  const initialFormData = useMemo<FormValuesModel>(() => {
    if (prefillData) {
      return {
        sender: {
          accountNumber: prefillData.fromAccount.accountNumber,
          accountNumberInitialValue: prefillData.fromAccount
            ? {
                id: prefillData.fromAccount.accountNumber,
                label: generateAccountFullName(
                  prefillData.fromAccount.accountName,
                  prefillData.fromAccount.accountNumber,
                  prefillData.fromAccount.currencyIsoCode,
                  prefillData.fromAccount.balance,
                ),
              }
            : undefined,
          reference: prefillData.fromAccountReference,
          processingType: AccountProcessingTypes.Native,
          account: {
            balance: prefillData.fromAccount.balance,
            availableBalance: prefillData.fromAccount.availableBalance,
            currencyId: prefillData.fromAccount.currencyId,
            currencyCode: prefillData.fromAccount.currencyIsoCode,
          } as IAccountModel,
        },
        recipient: {
          processingType: AccountProcessingTypes.Native,
          accountNumber:
            prefillData.beneficiaryAccount?.accountNumber ||
            OTHER_BANK_ACCOUNT_OPTION,
          accountNumberInitialValue: prefillData.beneficiaryAccount
            ? {
                id: prefillData.beneficiaryAccount.accountNumber,
                label: generateAccountFullName(
                  prefillData.beneficiaryAccount.accountName,
                  prefillData.beneficiaryAccount.accountNumber,
                  prefillData.beneficiaryAccount.currencyIsoCode,
                  prefillData.beneficiaryAccount.balance,
                ),
              }
            : {
                id: OTHER_BANK_ACCOUNT_OPTION,
                label: t('internal_transfer.other_bank_account_option'),
              },

          templateId: prefillData.beneficiaryTemplate?.id || null,
          templateIdInitialValue: prefillData.beneficiaryTemplate?.id
            ? {
                id: prefillData.beneficiaryTemplate.id,
                label: prefillData.beneficiaryTemplate.templateName,
              }
            : undefined,
          reference: prefillData.beneficiaryReference,
          template: prefillData.beneficiaryTemplate
            ? {
                accountName: prefillData.beneficiaryTemplate.accountName,
                accountNumber: prefillData.beneficiaryTemplate.accountNumber,
                currencyCode: prefillData.beneficiaryTemplate.currencyIsoCode,
                currencyId: prefillData.beneficiaryTemplate.currencyId,
                reference: prefillData.beneficiaryTemplate.accountName,
              }
            : null,
          account: prefillData.beneficiaryAccount
            ? ({
                currencyId: prefillData.beneficiaryAccount.currencyId,
                currencyCode: prefillData.beneficiaryAccount.currencyIsoCode,
              } as IAccountModel)
            : null,
        },
        amount: convertAmountFromBigIntToInt(prefillData.amount),
        transactionCurrencyId: prefillData.currencyId,
        valueDate: moment(),
        documents: [],
        transactionPurpose: prefillData.purpose,
      };
    } else {
      return emptyTransactionInitialFormData;
    }
  }, [prefillData, emptyTransactionInitialFormData]);

  const handleSubmit = async (values: FormValuesModel) => {
    const formattedTransferData =
      transfersAdapter.generateBodyForInternalTransfer(values);
    await transfersAPI.createInternalTransfer(formattedTransferData);
    dispatch(getBadges());
    Message.success(t('internal_transfer.success_submit_message'));
    scrollTop();
  };

  return (
    <>
      {!isTwoFactorVerificationEnabled ? (
        <StyledWarningInfoNot2FA
          text={t('internal_transfer.warning_not_2fa')}
        />
      ) : null}
      <InternalTransferForm
        initialValues={initialFormData}
        onSubmit={handleSubmit}
        disabled={!isTwoFactorVerificationEnabled}
        emptyTransactionFormValues={emptyTransactionInitialFormData}
      />
    </>
  );
};

const StyledWarningInfoNot2FA = styled(WarningIconWithText)`
  margin-bottom: ${({ theme }) => theme.marginXs};
`;

export default InternalTransfer;
