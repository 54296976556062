import React, { useEffect } from 'react';

// helpers
import { useLocation } from 'react-router-dom';
import { Route, routes } from 'routes/routes';
import { findRouteByPath } from 'helpers/routesHelpers';

const useCurrentRoute = (): Route | null => {
  const location = useLocation();
  const [currentRoute, setCurrentRoute] = React.useState<Route | null>(null);

  useEffect(() => {
    const currentRoute = findRouteByPath(routes, location.pathname);
    setCurrentRoute(currentRoute || null);
  }, [location.pathname]);

  return currentRoute;
};

export default useCurrentRoute;
