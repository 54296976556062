import React, { useMemo } from 'react';

// helpers
import styled from 'styled-components';
import useUserAccess from 'hooks/useUserAccess';
import useTranslation from 'hooks/useTranslation';
import { darkTheme } from 'resources/theme/styled';
import { StateModel } from 'redux/reducers';
import { useSelector } from 'react-redux';
import { colorsTheme } from 'resources/theme/styled/colors';
import { IClientGroupUser } from 'typings/approvalManagement/accountManagement';
import { ClientGroupUserStatuses } from 'enums/onboarding/crm';
import { getFormattedContactName } from 'helpers/crmHelpers';

// components
import {
  IconSVG,
  Button,
  Col,
  Row,
  Link,
  Table,
  RequiredPropsForTableModel,
  TableColumnModel,
  Tooltip,
} from '@ui';
import UserStatus from 'components/Additional/Statuses/UserStatus';
import InfoTooltip from '../../../../Tooltips/InfoTooltip';
import StatusLabel from '../../../../Typography/StatusLabel';
import DivAlignCenter from '../../../../Additional/DivAlignCenter';
import { ReactComponent as DocumentInfoIcon } from 'resources/icons/remix-icons/file-info-line.svg';

export type ActionTypes =
  | 'enable_user'
  | 'deactivate_user'
  | 'view_user'
  | 'update-permissions';

interface IProps extends RequiredPropsForTableModel<IClientGroupUser> {
  onActionsClick: (key: ActionTypes, record: IClientGroupUser) => void;
  disabledActions: boolean;
}

const UsersTable = ({ onActionsClick, disabledActions, ...rest }: IProps) => {
  const { t } = useTranslation(['account_management', 'common']);
  const currentContactId = useSelector<StateModel, string>((state) =>
    String(state.auth.contactData?._id),
  );
  const [hasEnableAccess, hasUpdateUserAccess, hasClientDetailsPageAccess] =
    useUserAccess([
      'AccountManagement_UserManagement_EnableUser',
      'AccountManagement_UserManagement_UpdateUser',
      'AccountManagement_ClientProfile_ClientDetails_View',
    ]);

  const renderUserName = (user: IClientGroupUser) => {
    const isDeactivated =
      user.status.name == ClientGroupUserStatuses.Deactivated;
    const contactName = getFormattedContactName(
      user.contact.firstName,
      user.contact.lastName,
      user.contact.middleName,
    );
    const showDocumentsIcon =
      user.status.name === ClientGroupUserStatuses.Onboarding ||
      user.status.name === ClientGroupUserStatuses.Rejected;

    return (
      <DivAlignCenter>
        {hasClientDetailsPageAccess && !isDeactivated ? (
          <Link onClick={() => onActionsClick('view_user', user)}>
            {contactName}
          </Link>
        ) : (
          contactName
        )}
        {showDocumentsIcon && (
          <StyledIconSVG
            component={DocumentInfoIcon}
            color={colorsTheme.colorError}
          />
        )}
      </DivAlignCenter>
    );
  };

  const renderUsername = (user: IClientGroupUser) => {
    return user.contact.username || '-';
  };

  const renderPrimaryEmail = (user: IClientGroupUser) => {
    let result = '-';
    const { contact } = user;

    if (contact.emails.length) {
      // First item in array is a primary value if there is no other item with isPrimary flag
      const firstEmail = contact.emails[0].address;
      if (contact.emails.length === 1) {
        result = firstEmail;
      } else {
        result =
          contact.emails.find(({ isPrimary }) => isPrimary)?.address ||
          firstEmail;
      }
    }

    return result;
  };

  const renderPrimaryPhone = (user: IClientGroupUser) => {
    let result = '-';
    const { contact } = user;

    if (contact.phoneNumbers.length) {
      // First item in array is a primary value if there is no other item with isPrimary flag
      const firstNumber = contact.phoneNumbers[0].number;
      if (contact.phoneNumbers.length === 1) {
        result = firstNumber;
      } else {
        result =
          contact.phoneNumbers.find(({ isPrimary }) => isPrimary)?.number ||
          firstNumber;
      }
    }

    return result;
  };

  const renderStatus = (user: IClientGroupUser) => {
    let result = null;

    if (user.toUpdate?.deactivate) {
      result = (
        <StatusLabel
          status="warning"
          content={t('user_management.user_statuses.deactivated')}
        />
      );
    } else {
      result = <UserStatus status={user.status.name} />;
    }

    return result;
  };

  const renderAccountPermissions = (user: IClientGroupUser) => {
    let isModified = false;
    let permission = user.permissionType;
    const canUpdatePermissions =
      hasUpdateUserAccess &&
      user.status.name !== ClientGroupUserStatuses.Deactivated;

    if (
      user.toUpdate &&
      !user.toUpdate.deactivate &&
      user.toUpdate.permissionType !== user.permissionType
    ) {
      isModified = true;
      permission = user.toUpdate.permissionType;
    }

    return (
      <DivAlignCenter>
        {canUpdatePermissions ? (
          <Link onClick={() => onActionsClick('update-permissions', user)}>
            <StatusLabel
              status={isModified ? 'warning' : ''}
              content={t(`user_management.account_permissions.${permission}`)}
            />
          </Link>
        ) : (
          <StatusLabel
            status={isModified ? 'warning' : ''}
            content={t(`user_management.account_permissions.${permission}`)}
          />
        )}
        <StyledTooltip
          color={darkTheme.colorWhite}
          tooltipContent={t(
            `user_management.account_permissions_tooltips.${permission}`,
          )}
        />
      </DivAlignCenter>
    );
  };

  const renderAccountAdmin = (user: IClientGroupUser) => {
    let isModified = false;
    let administrationPermission = user.adminPermissionType;
    const canUpdatePermissions =
      hasUpdateUserAccess &&
      user.status.name !== ClientGroupUserStatuses.Deactivated;

    if (
      user.toUpdate &&
      !user.toUpdate.deactivate &&
      user.toUpdate.adminPermissionType !== user.adminPermissionType
    ) {
      isModified = true;
      administrationPermission = user.toUpdate.adminPermissionType;
    }

    return canUpdatePermissions ? (
      <Link onClick={() => onActionsClick('update-permissions', user)}>
        <StatusLabel
          status={isModified ? 'warning' : ''}
          content={t(
            `user_management.administration_permissions.${administrationPermission}`,
          )}
        />
      </Link>
    ) : (
      <StatusLabel
        status={isModified ? 'warning' : ''}
        content={t(
          `user_management.administration_permissions.${administrationPermission}`,
        )}
      />
    );
  };

  const renderActions = (user: IClientGroupUser) => {
    const { name } = user.status;

    const canRenderInviteButton =
      hasEnableAccess &&
      (name === ClientGroupUserStatuses.Approved ||
        name === ClientGroupUserStatuses.InvitationExpired);

    if (canRenderInviteButton) {
      const btnText =
        name === ClientGroupUserStatuses.InvitationExpired
          ? t('user_management.resend_invitation.resend_invitation_button')
          : t('user_management.enable_user.enable_user_button');

      return (
        <Row justify="center">
          <Col>
            <DivAlignCenter>
              {disabledActions ? (
                <StyledActionTooltip
                  tooltipContent={t('disabled_by_2fa_button_information', {
                    ns: 'common',
                  })}
                />
              ) : null}
              <Button
                disabled={disabledActions}
                onClick={() => onActionsClick('enable_user', user)}
              >
                {btnText}
              </Button>
            </DivAlignCenter>
          </Col>
        </Row>
      );
    }
    const canDeactivateUser =
      name === ClientGroupUserStatuses.Enabled &&
      user.contact._id !== currentContactId &&
      !user.toUpdate;

    if (canDeactivateUser) {
      return (
        <Row justify="center">
          <Col>
            <DivAlignCenter>
              {disabledActions ? (
                <StyledActionTooltip
                  tooltipContent={t('disabled_by_2fa_button_information', {
                    ns: 'common',
                  })}
                />
              ) : null}
              <Tooltip
                placement="left"
                title={t(
                  'user_management.deactivate_user.deactivate_user_tooltip',
                )}
              >
                <Button
                  disabled={disabledActions}
                  onClick={() => onActionsClick('deactivate_user', user)}
                >
                  {t('user_management.deactivate_user.deactivate_user_button')}
                </Button>
              </Tooltip>
            </DivAlignCenter>
          </Col>
        </Row>
      );
    }
  };

  const tableColumns = useMemo<TableColumnModel[]>(() => {
    return [
      {
        key: 'name',
        title: t('user_management.table.name'),
        render: renderUserName,
      },
      {
        key: 'username',
        title: t('user_management.table.username'),
        render: renderUsername,
      },
      {
        key: 'email',
        title: t('user_management.table.email'),
        render: renderPrimaryEmail,
      },
      {
        key: 'phone',
        title: t('user_management.table.phone'),
        render: renderPrimaryPhone,
      },
      {
        key: 'status',
        title: t('user_management.table.status'),
        width: 220,
        render: renderStatus,
      },
      {
        key: 'accountPermission',
        title: t('user_management.table.permissions'),
        width: 220,
        render: renderAccountPermissions,
      },
      {
        key: 'administrationPermission',
        title: (
          <DivAlignCenter>
            {t('user_management.table.administrator')}
            <StyledTooltip
              color={darkTheme.colorWhite}
              tooltipContent={t(
                'user_management.table.administrator_description',
              )}
            />
          </DivAlignCenter>
        ),
        width: 230,
        render: renderAccountAdmin,
      },
      {
        key: 'enableUser',
        maxWidth: 200,
        render: renderActions,
      },
    ];
  }, [
    hasEnableAccess,
    hasUpdateUserAccess,
    hasClientDetailsPageAccess,
    onActionsClick,
  ]);

  return <Table columns={tableColumns} {...rest} />;
};

const StyledIconSVG = styled(IconSVG)`
  margin-left: ${({ theme }) => theme.marginXXs};
`;

const StyledTooltip = styled(InfoTooltip)`
  margin-left: ${({ theme }) => theme.marginXXs};
`;

const StyledActionTooltip = styled(InfoTooltip)`
  margin-right: ${({ theme }) => theme.marginXs};
`;

export default UsersTable;
