import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { generateUniqId } from 'helpers/commonHelpers';
import { IApprovalGroupShort } from 'typings/approvalManagement/accountManagement';
import { FieldArray, useFormikContext } from 'formik';
import {
  ApprovalRuleEntryModel,
  ApprovalTemplateColumns,
  FormValuesModel,
  TableColumnModel,
} from '..';

// components
import HideIfDisabledForm from 'components/Forms/HideIfDisabledForm';
import RequiredApprovalsCountSelect, {
  IProps as RequiredApprovalsCountSelectProps,
} from 'components/Forms/FormComponents/SelectInputs/AccountManagement/RequiredApprovalsCountSelect';
import ApprovalGroupsAutocomplete, {
  ApprovalGroupsAutocompleteProps,
} from 'components/Forms/FormComponents/Autocompletes/AccountManagement/ApprovalGroupsAutocomplete';
import { Button, Checkbox, FormField, Col, Row, DeleteButton } from '@ui';

interface IProps extends TableColumnModel {
  colIndex: number;
  rowIndex: number;
  dataIndex: ApprovalTemplateColumns;
  record: ApprovalRuleEntryModel;
}

const ContentCell = ({ dataIndex, rowIndex, approvalTriggerId }: IProps) => {
  const { t } = useTranslation('account_management');
  const { values, setFieldValue } = useFormikContext<FormValuesModel>();

  const getInitialApprovalGroup = () => {
    return {
      customUniqId: generateUniqId(),
      approvalGroupId: null,
      requiredApprovers: null,
      maximumRequiredApprovers: 0,
    };
  };

  const onDeleteRow = (rowIndex: number) => {
    const approvalRulesCopy = values.approvalConditions.slice();
    approvalRulesCopy.splice(rowIndex, 1);
    setFieldValue('approvalConditions', approvalRulesCopy);
  };

  const onAddNewANDClick = (rowIndex: number) => {
    const approvalGroupConditionsCopy =
      values.approvalConditions[rowIndex].approvalGroupConditions.slice();
    approvalGroupConditionsCopy.push(getInitialApprovalGroup());
    setFieldValue(
      `approvalConditions[${rowIndex}].approvalGroupConditions`,
      approvalGroupConditionsCopy,
    );
  };

  const handleApprovalGroupClear = (
    rowIndex: number,
    conditionIndex: number,
  ) => {
    setFieldValue(
      `approvalConditions[${rowIndex}].approvalGroupConditions[${conditionIndex}]`,
      {
        groupName: '',
        approvalGroupId: null,
        requiredApprovers: null,
        maximumRequiredApprovers: 0,
      },
    );
  };

  const handleOnApprovalGroupSelect = (
    group: IApprovalGroupShort,
    rowIndex: number,
    conditionIndex: number,
  ) => {
    const { participants, name } = group;
    setFieldValue(
      `approvalConditions[${rowIndex}].approvalGroupConditions[${conditionIndex}].groupName`,
      name,
    );
    setFieldValue(
      `approvalConditions[${rowIndex}].approvalGroupConditions[${conditionIndex}].requiredApprovers`,
      0,
    );
    setFieldValue(
      `approvalConditions[${rowIndex}].approvalGroupConditions[${conditionIndex}].maximumRequiredApprovers`,
      participants.length,
    );
  };

  const renderContent = (columnIndexKey: ApprovalTemplateColumns) => {
    let result = null;

    switch (columnIndexKey) {
      case ApprovalTemplateColumns.ApprovalGroupCondition:
        {
          const conditionsLength =
            values.approvalConditions[rowIndex].approvalGroupConditions.length;
          const shouldRenderDeleteSectionBtn =
            values.approvalConditions.length > 1;
          const shouldRenderOR =
            rowIndex < values.approvalConditions.length - 1;
          result = (
            <>
              <HideIfDisabledForm>
                {shouldRenderDeleteSectionBtn && (
                  <EndAlignWrapper>
                    <DeleteButton onClick={() => onDeleteRow(rowIndex)} />
                  </EndAlignWrapper>
                )}
              </HideIfDisabledForm>
              <FieldArray
                name={`approvalConditions[${rowIndex}].approvalGroupConditions`}
                render={(helpers) => {
                  return values.approvalConditions[
                    rowIndex
                  ].approvalGroupConditions.map((e, i) => {
                    const isDisabledRequiredApproversField = !e.approvalGroupId;
                    const numOfParticipantsInApprovalGroup =
                      e.maximumRequiredApprovers;
                    const shouldRenderAND = i < conditionsLength - 1;

                    return (
                      <div
                        key={
                          values.approvalConditions[rowIndex]
                            .approvalGroupConditions[i].customUniqId
                        }
                      >
                        <ApprovalGroupConditionWrapper>
                          <Row gutter={[16, 16]}>
                            <Col span={14}>
                              <FormField<ApprovalGroupsAutocompleteProps>
                                name={`approvalConditions[${rowIndex}].approvalGroupConditions[${i}].approvalGroupId`}
                                component={ApprovalGroupsAutocomplete}
                                shouldShowErrorMessage={false}
                                additionalProps={{
                                  approvalRuleType: values.approvalRuleType,
                                  showOnlyActiveApprovalGroups: true,
                                  onClear: () =>
                                    handleApprovalGroupClear(rowIndex, i),
                                  onSelect: (_, option) =>
                                    option.model &&
                                    handleOnApprovalGroupSelect(
                                      option.model,
                                      rowIndex,
                                      i,
                                    ),
                                  initialValue:
                                    values.approvalConditions[rowIndex]
                                      .approvalGroupConditions[i]
                                      .initialAutocompleteValue,
                                  excludeElementIds: values.approvalConditions[
                                    rowIndex
                                  ].approvalGroupConditions.reduce<string[]>(
                                    (acc, next, idx) => {
                                      if (idx !== i && next.approvalGroupId) {
                                        acc.push(next.approvalGroupId);
                                      }
                                      return acc;
                                    },
                                    [],
                                  ),
                                }}
                              />
                            </Col>
                            <Col span={10}>
                              <FormField<RequiredApprovalsCountSelectProps>
                                name={`approvalConditions[${rowIndex}].approvalGroupConditions[${i}].requiredApprovers`}
                                component={RequiredApprovalsCountSelect}
                                disabled={isDisabledRequiredApproversField}
                                additionalProps={{
                                  numOfParticipantsInApprovalGroup,
                                }}
                                shouldShowErrorMessage={false}
                              />
                            </Col>
                          </Row>
                        </ApprovalGroupConditionWrapper>
                        {shouldRenderAND && (
                          <AndContainer>
                            {t('approval_rules.and')}
                            <HideIfDisabledForm>
                              <StyledDeleteANDButton
                                onClick={() => helpers.remove(i + 1)}
                              />
                            </HideIfDisabledForm>
                          </AndContainer>
                        )}
                      </div>
                    );
                  });
                }}
              />
              <HideIfDisabledForm>
                <ButtonsWrapper>
                  <Button onClick={() => onAddNewANDClick(rowIndex)}>
                    {t('approval_rules.add_and_button')}
                  </Button>
                </ButtonsWrapper>
              </HideIfDisabledForm>
              {shouldRenderOR && (
                <ORContainer>{t('approval_rules.or')}</ORContainer>
              )}
            </>
          );
        }
        break;

      case ApprovalTemplateColumns.ApprovalRuleTriggerTypes:
        {
          const fieldName = `approvalConditions.${rowIndex}.actionStatuses.${approvalTriggerId}`;
          result = (
            <CheckboxWrapper>
              <FormField
                name={fieldName}
                component={Checkbox}
                shouldShowErrorMessage={false}
                disabled
              />
            </CheckboxWrapper>
          );
        }
        break;
    }

    return result;
  };

  return <td>{renderContent(dataIndex)}</td>;
};

const ApprovalGroupConditionWrapper = styled.div`
  position: relative;
  margin: ${({ theme }) => `${theme.marginXs} 0`};
`;

const EndAlignWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const AndContainer = styled.div`
  font-weight: 600;
  margin-left: ${({ theme }) => theme.marginSm};
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledDeleteANDButton = styled(DeleteButton)`
  margin-left: ${({ theme }) => theme.marginXs};
`;

const ButtonsWrapper = styled.div`
  margin-top: ${({ theme }) => theme.marginSm};
  display: flex;
  justify-content: flex-end;
`;

const ORContainer = styled.div`
  position: absolute;
  bottom: -16px;
  left: 15px;

  width: 55px;
  height: 32px;

  display: flex;
  align-items: center;
  justify-content: center;

  background: ${({ theme }) => theme.tableCardBackground};
`;

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default ContentCell;
