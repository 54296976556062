import React, { useMemo } from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';
import ErrorHandlerService, {
  ErrorFromServer,
} from 'services/error-handler/service';
import { userAPI } from 'api/profile/userAPI';
import { StateModel } from 'redux/reducers';
import { FormikHelpers } from 'formik';
import {
  setAuthenticationTokens,
  setTimeOffset,
} from 'helpers/localStorageHelpers';
import { useDispatch, useSelector } from 'react-redux';
import { StateModel as AuthStateModel } from 'redux/reducers/auth';
import { formatMinutesAsHumanReadable } from 'helpers/dateHelpers';
import { TwoStepVerificationValidationSchema } from 'validations/profile/auth';
import { initializeApp, setInitializedStatus } from 'redux/actions/app';
import {
  setTemporaryToken,
  setTwoFactorVerificationData,
} from 'redux/actions/auth';

// components
import { Form, Message } from '@ui';
import InnerForm from './InnerForm';
export interface FormValuesModel {
  code: string;
}

const Authenticator = () => {
  const { t } = useTranslation(['auth', 'server_errors', 'common']);
  const dispatch = useDispatch();
  const { temporaryToken } = useSelector<StateModel, AuthStateModel>(
    (state) => state.auth,
  );

  const formInitialValues = useMemo<FormValuesModel>(() => ({ code: '' }), []);

  const handleSubmit = async ({ code }: FormValuesModel) => {
    if (!temporaryToken || !code) {
      return;
    }

    const response = await userAPI.loginStepTwo(temporaryToken, code);

    if (response && response.jwt) {
      if (response.serverTime) {
        setTimeOffset(response.serverTime);
      }
      dispatch(setInitializedStatus(false));
      dispatch(setTwoFactorVerificationData(null));
      dispatch(setTemporaryToken(null));
      setAuthenticationTokens(response.jwt, response.refreshToken);
      dispatch(initializeApp());
    }
  };

  const handleOnSubmitError = (
    error: ErrorFromServer,
    values: FormValuesModel,
    formikHelpers: FormikHelpers<FormValuesModel>,
  ) => {
    const errorCode = ErrorHandlerService.getErrorCodeFromError(error);
    const details = ErrorHandlerService.getDetailsObject(error);

    switch (errorCode) {
      case '1201014': {
        formikHelpers.setFieldError(
          'code',
          t('1201014', { ns: 'server_errors' }),
        );
        break;
      }

      case '1204009': {
        Message.error(
          t(errorCode, {
            time: formatMinutesAsHumanReadable(details?.object?.lockedUntil, {
              minute: t('time_units.minute', { ns: 'common' }),
              minutes: t('time_units.minutes', { ns: 'common' }),
              hour: t('time_units.hour', { ns: 'common' }),
              hours: t('time_units.hours', { ns: 'common' }),
            }),
            ns: 'server_errors',
          }),
        );
        dispatch(setTwoFactorVerificationData(null));
        dispatch(setTemporaryToken(null));
        break;
      }

      default: {
        ErrorHandlerService.handleError(error);
        break;
      }
    }
  };

  return (
    <Form<FormValuesModel>
      onSubmit={handleSubmit}
      initialValues={formInitialValues}
      onSubmitError={handleOnSubmitError}
      validationSchema={TwoStepVerificationValidationSchema}
      renderForm={<InnerForm />}
    />
  );
};

export default Authenticator;
