import React, { useCallback } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { accountsAPI } from 'api/accounting/accountsAPI';
import { IAccountModel } from 'typings/onboarding/account';

// components
import EllipsisTooltip from '../../../../../Tooltips/EllipsisTooltip';
import { FormAutocomplete } from '@ui';
import { Text, AutocompleteOption, AutocompleteProps } from '@ui';
import {
  convertAmountBigIntToLocaleString,
  generateAccountFullName,
} from 'helpers/accountsHelpers';

export interface RecipientBankAccountAutocompleteProps
  extends AutocompleteProps<IAccountModel> {
  senderAccountNumber?: string;
  addOtherBankAccountOption?: boolean;
}

export const OTHER_BANK_ACCOUNT_OPTION = 'other-bank-account';

const RecipientBankAccountAutocomplete = ({
  senderAccountNumber,
  addOtherBankAccountOption,
  ...rest
}: RecipientBankAccountAutocompleteProps) => {
  const { t } = useTranslation(['transfers', 'custom_errors', 'form']);

  const fetchOptionsCallback = useCallback(
    async (search: string, page: number, limit: number) => {
      if (!senderAccountNumber) {
        return {
          total: 0,
          options: [],
        };
      }

      const { data, total } =
        await accountsAPI.fetchEligibleAccountsForInternalTransfer(
          senderAccountNumber,
          {
            search,
            page,
            limit,
          },
        );

      let totalRecords = total;

      let result: AutocompleteOption<IAccountModel>[] = data.map((e) => {
        const { accountNumber, accountName, currencyCode, balance } = e;
        const accountBalance = `${currencyCode} ${convertAmountBigIntToLocaleString(balance)}`;
        const name = generateAccountFullName(
          accountName,
          accountNumber,
          currencyCode,
          balance,
        );

        return {
          id: e.accountNumber,
          model: e,
          label: (
            <OptionContentWrapper>
              <EllipsisTooltip
                placement="right"
                overlayStyle={{ maxWidth: '300px' }}
                title={
                  <>
                    <Text variant="body1">{accountNumber}</Text>
                    <Text variant="body1">{accountName}</Text>
                    <Text variant="body1">{accountBalance}</Text>
                  </>
                }
              >
                {name}
              </EllipsisTooltip>
            </OptionContentWrapper>
          ),
        };
      });

      if (addOtherBankAccountOption) {
        result = [
          {
            id: OTHER_BANK_ACCOUNT_OPTION,
            label: t('internal_transfer.other_bank_account_option'),
          },
          ...result,
        ];

        totalRecords++;
      }

      return { total: totalRecords, options: result };
    },
    [senderAccountNumber, addOtherBankAccountOption],
  );

  return <FormAutocomplete {...rest} fetchData={fetchOptionsCallback} />;
};

const OptionContentWrapper = styled.div`
  display: flex;
  overflow: hidden;
  max-width: 100%;
`;

export default RecipientBankAccountAutocomplete;
