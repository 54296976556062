import React, { ReactNode } from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';
import ErrorHandlerService, {
  ErrorFromServer,
} from 'services/error-handler/service';
import { RoutePaths } from 'routes/routes';
import { useNavigate } from 'react-router-dom';
import { FormikHelpers } from 'formik';
import { OnboardingEntryTypes } from 'enums/onboarding/crm';
import { getFormattedContactName } from 'helpers/crmHelpers';
import { EditNodeFormValidationSchema } from 'validations/onboarding-new/structure';
import { FormValuesModel as ContactInfoFormValuesModel } from '../../../../Forms/TemplateForms/CRM/ContactShortInfoForm';
import { FormValuesModel as OrganizationInfoFormValuesModel } from '../../../../Forms/TemplateForms/CRM/OrganizationShortInfoForm';
import {
  FormValuesModel as RelationshipFormValuesModel,
  ParentNodeOfRelationshipModel,
} from '../../../../Forms/TemplateForms/CRM/RelationshipForm';

// components
import {
  Button,
  Form,
  RequiredPropsForFormModel,
  ModalDialog,
  RequiredPropsForModalDialogModel,
  Col,
  Row,
} from '@ui';
import InnerForm from './InnerForm';
import DivAlignCenter from 'components/Additional/DivAlignCenter';
import HideIfDisabledForm from 'components/Forms/HideIfDisabledForm';
import HandoffApplicationTooltip from 'components/Tooltips/TemplateTooltips/HandoffApplicationTooltip';

export type FormValuesModel = {
  clientGroupId: string;
  activeScopeId: string;
  keyRelationshipTemplateId: string;
  clientUserId: string | null;
  entryType: OnboardingEntryTypes | null;
  clientGroupEntryType: OnboardingEntryTypes;
  contact: ContactInfoFormValuesModel | null;
  organization: OrganizationInfoFormValuesModel | null;
  relationships: RelationshipFormValuesModel[];
  parentForNewRelationships: ParentNodeOfRelationshipModel;
  hideRelationshipsSection?: boolean;
  disableNodeForm?: boolean;
};

interface IProps
  extends RequiredPropsForModalDialogModel,
    RequiredPropsForFormModel<FormValuesModel> {
  title: string | ReactNode;
  disabled?: boolean;
  showSeeMoreInfoButton?: boolean;
  onHandoffClick?: () => Promise<void>;
}

const EditNodeModalDialog = ({
  isVisible,
  initialValues,
  title,
  disabled,
  showSeeMoreInfoButton,
  onSubmit,
  closeCallback,
  onHandoffClick,
}: IProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation('onboarding');

  const handleSubmit = async (
    values: FormValuesModel,
    formikHelpers: FormikHelpers<FormValuesModel>,
  ) => {
    if (onSubmit) {
      await onSubmit(values, formikHelpers);
    }
  };

  const handleOnSubmitError = (
    error: ErrorFromServer,
    _: FormValuesModel,
    formikHelpers: FormikHelpers<FormValuesModel>,
  ) => {
    const errorCode = ErrorHandlerService.getErrorCodeFromError(error);
    switch (errorCode) {
      case '1001009': {
        formikHelpers.setFieldError('contact.email', t('1001009'));
        break;
      }
      case '1016010': {
        formikHelpers.setFieldError('contact.email', t('1016010'));
        break;
      }
      case '1001010': {
        formikHelpers.setFieldError('contact.phone', t('1001010'));
        break;
      }
      case '1001002': {
        formikHelpers.setFieldError('contact.email', t('1001002'));
        break;
      }
      default: {
        ErrorHandlerService.handleError(error);
        break;
      }
    }
  };

  return (
    <Form
      enableReinitialize
      disabled={disabled}
      onSubmit={handleSubmit}
      onSubmitError={handleOnSubmitError}
      initialValues={isVisible ? initialValues : null}
      validationSchema={EditNodeFormValidationSchema}
      renderForm={(form) => (
        <ModalDialog
          title={title}
          isVisible={isVisible}
          closeCallback={closeCallback}
          closeModalAfterError={false}
          submitButtonProps={{
            hidden: disabled,
          }}
          cancelButtonProps={{
            hidden: disabled,
          }}
          extendedFooterButtonsPosition="before"
          extendedFooterButtons={
            showSeeMoreInfoButton && isVisible ? (
              <Button
                type="bordered"
                size="large"
                onClick={() =>
                  navigate({
                    pathname:
                      RoutePaths.AccountManagement_ClientProfile_ClientDetails,
                    search: `?id=${form.values.contact?.id || form.values.organization?.id}&type=${form.values.entryType}`,
                  })
                }
              >
                {t('node_modal.navigate_to_client_profile_page_btn')}
              </Button>
            ) : undefined
          }
        >
          {onHandoffClick ? (
            <HideIfDisabledForm>
              <Row justify="end">
                <Col>
                  <DivAlignCenter>
                    <Button type="bordered" onClick={onHandoffClick}>
                      {t('node_modal.handoff_application.handoff_btn')}
                    </Button>
                    <HandoffApplicationTooltip
                      contact={getFormattedContactName(
                        initialValues?.contact?.firstName || '',
                        initialValues?.contact?.lastName || '',
                      )}
                    />
                  </DivAlignCenter>
                </Col>
              </Row>
            </HideIfDisabledForm>
          ) : null}
          <InnerForm />
        </ModalDialog>
      )}
    />
  );
};

export default EditNodeModalDialog;
