import React, { memo } from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';
import { colorsTheme } from 'resources/theme/styled/colors';
import { IAccountModel } from 'typings/onboarding/account';
import { StyledComponentProps } from 'typings/common';
import { convertAmountBigIntToLocaleString } from 'helpers/accountsHelpers';

// components
import { Text, TextProps } from '@ui';

interface IProps extends StyledComponentProps, TextProps {
  account: IAccountModel;
}

const AvailableBalanceText = memo(({ account, ...rest }: IProps) => {
  const { t } = useTranslation('transfers');
  const shouldShowText = account.balance !== account.availableBalance;

  return shouldShowText ? (
    <Text variant="body1" color={colorsTheme.colorWarning} {...rest}>
      {t('internal_transfer.available_balance', {
        currency: account.currencyCode,
        amount: convertAmountBigIntToLocaleString(account.availableBalance),
      })}
    </Text>
  ) : null;
});

export default AvailableBalanceText;
