import React, { useMemo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { StateModel } from 'redux/reducers';
import { changeView } from 'redux/actions/view';
import { Route, routes } from 'routes/routes';
import { LocalizationNamespaces } from 'constants/localization';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { filterRoutes, getMainRoutes } from 'helpers/routesHelpers';
import { StateModel as UserAccessStateModel } from 'redux/reducers/userAccess';

// components
import RenderBadgeByRoutePath from 'components/Additional/RenderBadgeByRoutePath';
import { Menu, MenuItem, SubMenu } from '@ui';

const NavigationMenu = () => {
  const { t } = useTranslation(LocalizationNamespaces);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { permissions } = useSelector<StateModel, UserAccessStateModel>(
    (state) => state.userAccess,
  );

  const activeRoutes = useMemo(() => {
    function isAllowed(route: Route): boolean {
      const { metadata, access } = route;

      const addToNavigation = metadata?.addToNavigation;
      const hasValidLayout =
        !access?.layout || access?.layout === 'ApprovedClientGroup';
      const hasValidPermission =
        !access?.permission || permissions[access.permission].isAllowed;

      return !!(addToNavigation && hasValidLayout && hasValidPermission);
    }

    return filterRoutes(getMainRoutes(routes), isAllowed);
  }, [permissions]);

  const renderRoutes = (routes: Route[], isChildren?: boolean) => {
    return routes.map(({ path, title, children }) => {
      const titleNode = (
        <MenuLink addPadding={!isChildren}>
          {t(title.key, { ns: title.ns })}
          <RenderBadgeByRoutePath path={path} />
        </MenuLink>
      );

      if (!children || !children.length) {
        return (
          <MenuItem key={path} itemKey={path}>
            {titleNode}
          </MenuItem>
        );
      } else {
        return (
          <SubMenu
            key={path}
            itemKey={path}
            title={titleNode}
            onTitleClick={() => dispatch(changeView(() => navigate(path)))}
            // TODO: try to remove this workaround that highlights parent submenu title when child menu is selected
            // From some reason, item is not highlighting when Menu.Item component is wrapped (like in our case core component)
            className={
              location.pathname == path || location.pathname.includes(path)
                ? 'menu-submenu-selected'
                : ''
            }
          >
            {renderRoutes(children, true)}
          </SubMenu>
        );
      }
    });
  };

  return (
    <StyledMenu
      mode="horizontal"
      selectedKeys={[location.pathname]}
      subMenuCloseDelay={0.3}
      onSelect={({ key }) =>
        dispatch(changeView(() => navigate(key as string)))
      }
      getPopupContainer={(triggerNode: HTMLElement) =>
        (triggerNode?.parentNode as HTMLElement) || document.body
      }
    >
      {renderRoutes(activeRoutes)}
    </StyledMenu>
  );
};

const StyledMenu = styled(Menu)`
  display: flex;
  align-items: center;

  &.ant-menu-horizontal {
    .ant-menu-submenu {
      margin: 0px;
      &:hover {
        color: ${({ theme }) => theme.colorPrimary} !important;
        filter: none;
      }
      a {
        color: ${({ theme }) => theme.colorLight} !important;

        &:hover {
          color: ${({ theme }) => theme.colorPrimary} !important;
          filter: none;
        }
      }
      .ant-menu-item {
        a {
          color: ${({ theme }) => theme.colorLight} !important;
          &:hover {
            color: ${({ theme }) => theme.colorPrimary} !important;
            filter: none;
          }
        }
      }
    }
    .ant-menu-item-only-child {
      margin: 0;
      display: flex;
      a {
        color: ${({ theme }) => theme.colorLight} !important;
        &:hover {
          color: ${({ theme }) => theme.colorPrimary} !important;
          filter: none;
        }
      }
    }
    .ant-menu-submenu-selected {
      margin: 0;
      .ant-menu-item-selected a {
        color: ${({ theme }) => theme.colorPrimary} !important;
        &:hover {
          color: ${({ theme }) => theme.colorPrimary} !important;
          filter: none;
        }
      }
      a {
        color: ${({ theme }) => theme.colorPrimary} !important;
        &:hover {
          color: ${({ theme }) => theme.colorPrimary} !important;
          filter: none;
        }
      }
    }
  }
`;

const MenuLink = styled.div<{ addPadding?: boolean }>`
  display: flex;
  align-items: center;
  padding: ${({ theme, addPadding }) =>
    addPadding ? `0 ${theme.marginSm}` : ''};
`;

export default NavigationMenu;
