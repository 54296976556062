import React, { useMemo } from 'react';

// helpers
import useFetch from 'hooks/useFetch';
import useTranslation from 'hooks/useTranslation';
import { StateModel } from 'redux/reducers';
import { useSelector } from 'react-redux';
import { getDistrict } from 'helpers/accountsHelpers';
import { StateModel as AuthStateModel } from 'redux/reducers/auth';
import { RequiredPropsForModalDialogModel, Message } from '@ui';
import {
  AccountProcessingTypes,
  TransferTemplateTypes,
} from 'enums/accounting/transfers';
import {
  transferTemplatesAPI,
  transferTemplatesApiHelpers,
} from 'api/accounting/transferTemplatesAPI';
import {
  BlockchainTransferTemplate,
  IInternalTransferTemplate,
  IInternationalTransferTemplate,
  INewBlockchainTransferRecipientFormValues,
  INewTransferTemplateFormValues,
} from 'typings/accounting/transfer';

// components
import TransferTemplateModal from 'components/ModalDialogs/TemplateModalDialogs/Transfers/TransferTemplateModal';

interface IProps extends RequiredPropsForModalDialogModel {
  templateId: number | null;
  templateType: TransferTemplateTypes | null;
  onDuplicateClick: (formValues: INewTransferTemplateFormValues | null) => void;
}

const EditTransferTemplateModal = ({
  isVisible,
  closeCallback,
  templateId,
  templateType,
  onDuplicateClick,
}: IProps) => {
  const { t } = useTranslation('transfers');

  const { isTwoFactorVerificationEnabled, profileData } = useSelector<
    StateModel,
    AuthStateModel
  >((state) => state.auth);

  const { response } = useFetch<
    | IInternationalTransferTemplate
    | IInternalTransferTemplate
    | BlockchainTransferTemplate
    | null
  >(() => {
    if (typeof templateType !== 'undefined' && templateId) {
      switch (templateType) {
        case TransferTemplateTypes.Internal:
          return transferTemplatesAPI.getInternalTransferTemplate(templateId);

        case TransferTemplateTypes.Wire:
          return transferTemplatesAPI.getInternationalTransferTemplate(
            templateId,
          );

        case TransferTemplateTypes.Blockchain:
          return transferTemplatesAPI.getBlockchainTransferTemplate(templateId);
      }
    }

    return null;
  }, [templateId]);

  const initialFormValues =
    useMemo<INewTransferTemplateFormValues | null>(() => {
      if (response) {
        switch (templateType) {
          case TransferTemplateTypes.Internal: {
            const internalTemplate = {
              ...response,
            } as IInternalTransferTemplate;
            return {
              id: internalTemplate.id,
              creationDate: internalTemplate.creationDate,
              isPermissionsDisabled:
                internalTemplate.creatorId !== profileData?._id,
              templateName: internalTemplate.templateName,
              templateType: internalTemplate.templateTypeId,
              permissions: internalTemplate.isOnlyMe ? 'onlyMe' : 'clientGroup',
              transferFields: {
                accountNumber: internalTemplate.accountNumber,
                accountName: internalTemplate.accountName,
                recipientReference: internalTemplate.recipientReference,
              },
            } as INewTransferTemplateFormValues;
          }

          case TransferTemplateTypes.Wire: {
            const wireTransferTemplate = {
              ...response,
            } as IInternationalTransferTemplate;
            const isCircle =
              wireTransferTemplate.processingType ===
              AccountProcessingTypes.Circle
                ? true
                : wireTransferTemplate.processingType ===
                    AccountProcessingTypes.Native
                  ? false
                  : null;

            return {
              id: wireTransferTemplate.id,
              creationDate: wireTransferTemplate.creationDate,
              templateName: wireTransferTemplate.templateName,
              templateType: wireTransferTemplate.templateTypeId,
              permissions: wireTransferTemplate.isOnlyMe
                ? 'onlyMe'
                : 'clientGroup',
              isPermissionsDisabled:
                wireTransferTemplate.creatorId !== profileData?._id,
              status: wireTransferTemplate.status,
              invalidityReason: wireTransferTemplate.invalidityReason,
              transferFields: {
                isCircle,
                accountName: wireTransferTemplate.beneficiaryAccountName,
                accountNumber: wireTransferTemplate.beneficiaryAccountNumber,
                country: wireTransferTemplate.beneficiaryCountry,
                address: wireTransferTemplate.beneficiaryAddress1,
                beneficiaryCity: wireTransferTemplate.beneficiaryCity,
                beneficiaryDistrict: wireTransferTemplate.beneficiaryDistrict,
                beneficiaryPostCode: wireTransferTemplate.beneficiaryPostCode,
                useIntermediary:
                  !!wireTransferTemplate.intermediaryBankCodeType &&
                  !!wireTransferTemplate.intermediaryBankName,
                reference: wireTransferTemplate.reference,

                intermediaryBank: {
                  bankName: wireTransferTemplate.intermediaryBankName,
                  bankCode: wireTransferTemplate.intermediaryBankCode,
                  bankCodeType: wireTransferTemplate.intermediaryBankCodeType,
                  country: wireTransferTemplate.intermediaryBankCountry,
                  address: wireTransferTemplate.intermediaryBankAddress1,
                  city: wireTransferTemplate.intermediaryBankCity,
                  district: getDistrict(
                    wireTransferTemplate.intermediaryBankCountry,
                    wireTransferTemplate.intermediaryBankDistrict || '',
                  ),
                  postCode: wireTransferTemplate.intermediaryBankPostCode,
                },
                beneficiaryBank: {
                  bankName: wireTransferTemplate.beneficiaryBankName,
                  bankCode: wireTransferTemplate.beneficiaryBankCode,
                  bankCodeType: wireTransferTemplate.beneficiaryBankCodeType,
                  country: wireTransferTemplate.beneficiaryBankCountry,
                  address: wireTransferTemplate.beneficiaryBankAddress1,
                  city: wireTransferTemplate.beneficiaryBankCity,
                  district: getDistrict(
                    wireTransferTemplate.beneficiaryBankCountry,
                    wireTransferTemplate.beneficiaryBankDistrict || '',
                  ),
                  postCode: wireTransferTemplate.beneficiaryBankPostCode,
                },
              },
            } as INewTransferTemplateFormValues;
          }

          case TransferTemplateTypes.Blockchain: {
            const blockchainTemplate = {
              ...response,
            } as BlockchainTransferTemplate;
            return {
              templateName: blockchainTemplate.templateName,
              templateType: blockchainTemplate.templateTypeId,
              permissions: blockchainTemplate.isOnlyMe
                ? 'onlyMe'
                : 'clientGroup',
              transferFields: {
                networkChain: blockchainTemplate.networkChain,
                beneficiaryAddress: blockchainTemplate.beneficiaryAddress,
                beneficiaryTag: blockchainTemplate.beneficiaryTag,
                processingType: blockchainTemplate.processingType,
              },
            } as INewTransferTemplateFormValues;
          }

          default:
            return null;
        }
      }

      return null;
    }, [response, profileData]);

  const modalTitle = useMemo(() => {
    switch (templateType) {
      case TransferTemplateTypes.Internal:
        return t('transfer_templates.transfer_template_types.Internal');

      case TransferTemplateTypes.Wire:
        return t('transfer_templates.transfer_template_types.Wire');

      case TransferTemplateTypes.Blockchain:
        return t('transfer_templates.transfer_template_types.Blockchain');

      default:
        return '';
    }
  }, [templateType]);

  const handleSubmit = async (values: INewTransferTemplateFormValues) => {
    if (!templateId) {
      return;
    }

    switch (values.templateType) {
      case TransferTemplateTypes.Internal:
        {
          await transferTemplatesAPI.editInternalTransferTemplate(
            templateId,
            transferTemplatesApiHelpers.generateInternalTemplateRequestBody(
              values,
            ),
          );
        }
        break;

      case TransferTemplateTypes.Wire:
        {
          await transferTemplatesAPI.editInternationalTransferTemplate(
            templateId,
            transferTemplatesApiHelpers.generateInternationalTemplateRequestBody(
              values,
            ),
          );
        }
        break;

      case TransferTemplateTypes.Blockchain:
        {
          await transferTemplatesAPI.editBlockchainTransferTemplate(
            templateId,
            transferTemplatesApiHelpers.generateBlockchainTemplateRequestBody(
              values as INewTransferTemplateFormValues<INewBlockchainTransferRecipientFormValues>,
            ),
          );
        }
        break;
    }

    Message.success(
      t(
        'transfer_templates.transfer_template_modal.edit.success_submit_message',
      ),
    );
    closeCallback(true);
  };

  return (
    <TransferTemplateModal
      isEditMode
      disabled={!isTwoFactorVerificationEnabled}
      title={modalTitle}
      isVisible={isVisible}
      closeCallback={closeCallback}
      initialValues={initialFormValues}
      onSubmit={handleSubmit}
      onDuplicateClick={() => onDuplicateClick(initialFormValues)}
    />
  );
};

export default EditTransferTemplateModal;
