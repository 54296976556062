import React, { useMemo } from 'react';

// helpers
import useFetch from 'hooks/useFetch';
import useTranslation from 'hooks/useTranslation';
import { ContactModel } from 'typings/application/contact';
import { onboardingAPI } from 'api/onboarding/onboardingAPI';
import { onboardingAPIAdapter } from 'apiAdapters/onboarding/onboardingAPIAdapter';
import { formatExpiringDocumentToPassportFormItemModel } from 'helpers/crmHelpers';

// constants
import { ActionKeys } from 'components/Forms/TemplateForms/Onboarding/Components/SubmitButtons';

// components
import LoadingWrapper from 'components/WrapperComponents/LoadingWrapper';
import IdentificationForIndividualForm, {
  AddressItemModel,
  FormValuesModel,
  PassportItemModel,
} from 'components/Forms/TemplateForms/Onboarding/IdentificationForIndividualForm';
import { Message } from '@ui';

interface IProps {
  isViewOnly: boolean;
  onAction: (key: ActionKeys | null) => void;
  onboardingData: {
    applicationId: string;
    contactId: string;
  };
}

const Identification = ({ onAction, onboardingData, isViewOnly }: IProps) => {
  const { t } = useTranslation('common');

  const { response, loading } = useFetch(
    () => onboardingAPI.fetchIdentificationStepData(onboardingData.contactId),
    [onboardingData],
  );

  const { response: onboardingItemResponse, loading: onboardingItemLoader } =
    useFetch(
      () =>
        onboardingAPI.fetchStatusForOnboardingItem(
          onboardingData.contactId,
          onboardingData.applicationId,
        ),
      [onboardingData],
    );

  const initialFormValues = useMemo<FormValuesModel | null>(() => {
    if (!response || !onboardingItemResponse) {
      return null;
    }

    let addresses: AddressItemModel[] = [
      {
        isPrimary: true,
        type: null,
        country: null,
        city: '',
        street: '',
        state: '',
        postalCode: '',
      },
    ];

    let passports: PassportItemModel[] = [
      {
        number: '',
        country: '',
        issuedAt: null,
        expirationDate: null,
        document: [],
      },
    ];
    if (onboardingItemResponse.item) {
      const contact = onboardingItemResponse.item as ContactModel;

      if (response.addresses.data?.length) {
        addresses = response.addresses.data.map((address) => ({
          _id: address._id,
          isPrimary: !!address.isPrimary,
          type: address.type || null,
          country: address.country || null,
          city: address.city,
          street: address.street,
          state: address.state,
          postalCode: address.postalCode,
          review: address.review,
          documents:
            address?.documents &&
            address.documents.map((doc) => ({
              id: doc.id,
              name: doc.files[0].name,
              fileId: doc.files[0].id,
              file: null,
            })),
        }));
      }

      if (response.passportExpiringDocuments.data.length) {
        passports = response.passportExpiringDocuments.data.map(
          formatExpiringDocumentToPassportFormItemModel,
        );
      }

      return {
        submitActionType: null,
        isSave: false,
        isPEP: typeof contact.isPEP === 'boolean' ? contact.isPEP : null,
        pepInformation: contact.pepInformation || '',
        isRegulated:
          typeof contact.isRegulated === 'boolean' ? contact.isRegulated : null,
        regulationCountry: contact.regulationCountry,
        addresses,
        passports,
        nationality: contact.nationality || [],
      };
    } else {
      return {
        submitActionType: null,
        isSave: false,
        isPEP: null,
        pepInformation: '',
        isRegulated: null,
        regulationCountry: [],
        addresses,
        passports,
        nationality: [],
      };
    }
  }, [onboardingItemResponse, response]);

  const onSubmit = async (values: FormValuesModel) => {
    const formattedBody =
      onboardingAPIAdapter.submitIdentificationForIndividualStep(values);
    await onboardingAPI.submitIdentificationForIndividualStep(
      formattedBody,
      onboardingData.applicationId,
    );
    Message.success(t('success_save'));
    onAction(values.submitActionType);
  };

  return (
    <LoadingWrapper loading={loading || onboardingItemLoader}>
      {initialFormValues && (
        <IdentificationForIndividualForm
          initialFormValues={initialFormValues}
          disabled={isViewOnly}
          onSubmit={onSubmit}
        />
      )}
    </LoadingWrapper>
  );
};

export default Identification;
