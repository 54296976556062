import React, { useMemo } from 'react';

// helpers
import useFetch from 'hooks/useFetch';
import useTranslation from 'hooks/useTranslation';
import relationshipsAPI from 'api/relationships/relationshipsAPI';
import { StateModel } from 'redux/reducers';
import { useSelector } from 'react-redux';
import { ContactModel } from 'typings/application/contact';
import { IApplication } from 'typings/application/applications';
import { onboardingAPI } from 'api/onboarding/onboardingAPI';
import { onboardingAPIAdapter } from 'apiAdapters/onboarding/onboardingAPIAdapter';
import { OnboardingStatusModel } from 'typings/onboarding/onboarding';
import { getEntityNameByNameType } from 'helpers/crmHelpers';
import { FormValuesModel as EmploymentHistoryFormValues } from 'components/Forms/TemplateForms/Onboarding/EmploymentHistoryShortForm';
import {
  ADDITIONAL_FIELD_ROLE,
  RELATIONSHIP_TEMPLATE_ID,
} from 'constants/onboarding';

// components
import SourceOfWealthForm, {
  FormValuesModel,
} from 'components/Forms/TemplateForms/Onboarding/SourceOfWealthForm';
import LoadingWrapper from 'components/WrapperComponents/LoadingWrapper';
import { Message } from '@ui';
interface IProps {
  onboardingStatus: OnboardingStatusModel;
  isViewOnly: boolean;
  onSave: () => void;
}

const SourceOfWealth = ({ onboardingStatus, isViewOnly, onSave }: IProps) => {
  const { t } = useTranslation('common');

  const activeApplication = useSelector<StateModel, IApplication | null>(
    (state) => state.applications.activeApplication,
  );

  const {
    response: fetchRelationshipsResponse,
    loading: relationshipsLoading,
  } = useFetch(() => {
    return relationshipsAPI.fetchRelationships({
      itemId: onboardingStatus?.crmItemId,
      relationshipScopeId: [
        activeApplication?.model.relationshipScopes?.active as string,
        onboardingStatus?.item.relationshipScopes?.active as string,
      ].join(','),
      relationshipTemplateId: RELATIONSHIP_TEMPLATE_ID,
    });
  }, [onboardingStatus, activeApplication]);

  const initialFormValues = useMemo<FormValuesModel>(() => {
    const { item } = onboardingStatus;
    const contact = item as ContactModel;
    const employmentHistoryValue: EmploymentHistoryFormValues[] = [];

    const notInheritedEmploymentHistory = contact.employmentInformation
      ? {
          isInheritedFromRelationships: false,
          employerName: contact.employmentInformation.employerName || '',
          occupation: contact.employmentInformation.occupation || '',
          natureOfBusiness:
            contact.employmentInformation.natureOfBusiness || '',
          isSelfEmployed:
            typeof contact.employmentInformation.isSelfEmployed === 'boolean'
              ? contact.employmentInformation.isSelfEmployed
              : null,
          isBusinessUnderOwnName:
            typeof contact.employmentInformation.isBussinessUnderOwnName ===
            'boolean'
              ? contact.employmentInformation.isBussinessUnderOwnName
              : null,
        }
      : {
          isInheritedFromRelationships: false,
          employerName: '',
          occupation: '',
          natureOfBusiness: '',
          isSelfEmployed: null,
          isBusinessUnderOwnName: false,
        };

    if (fetchRelationshipsResponse?.data.length) {
      const inheritedRelationships: EmploymentHistoryFormValues[] =
        fetchRelationshipsResponse.data.reduce<EmploymentHistoryFormValues[]>(
          (acc, relationship) => {
            // check if employment history item is not manual
            const additionalFieldRole = relationship.additionalFields.find(
              (ad) => ad.name === ADDITIONAL_FIELD_ROLE,
            );
            if (
              relationship._id !==
                contact.employmentInformation?.relationshipId &&
              additionalFieldRole
            )
              acc.push({
                isInheritedFromRelationships: true,
                isSelfEmployed: false,
                employerName: getEntityNameByNameType(
                  relationship.child?.organization?.names,
                ),
                occupation: JSON.parse(additionalFieldRole.valueJSON),
                natureOfBusiness: '',
                isBusinessUnderOwnName: false,
              });
            return acc;
          },
          [],
        );

      if (inheritedRelationships.length) {
        employmentHistoryValue.push(...inheritedRelationships);
      } else {
        employmentHistoryValue.push(notInheritedEmploymentHistory);
      }
    } else {
      employmentHistoryValue.push(notInheritedEmploymentHistory);
    }

    return {
      isSave: false,
      submitActionType: null,
      isBasedOnExistingRelationship:
        contact.employmentInformation?.isBasedOnExistingRelationship,
      sourceOfWealthCategories: contact.sourceOfWealthCategories || [],
      sourceOfWealthDescription: contact.sourceOfWealthDescription || '',
      employmentHistory: [...employmentHistoryValue],
    };
  }, [onboardingStatus, fetchRelationshipsResponse]);

  const onSubmit = async (values: FormValuesModel) => {
    const formattedBody = onboardingAPIAdapter.submitSourceOfWealthStep(values);
    await onboardingAPI.submitSourceOfWealthForIndividualStep(
      formattedBody,
      onboardingStatus.applicationId,
      onboardingStatus.crmItemId,
    );
    Message.success(t('success_save'));
    onSave();
  };

  return (
    <LoadingWrapper loading={relationshipsLoading}>
      <SourceOfWealthForm
        showOnlySaveButton
        disabled={isViewOnly}
        initialFormValues={initialFormValues}
        onSubmit={onSubmit}
      />
      ;
    </LoadingWrapper>
  );
};

export default SourceOfWealth;
