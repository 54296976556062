import React, { useEffect, useState } from 'react';

// helpers
import styled from 'styled-components';
import { StateModel } from 'redux/reducers';
import { uploadFiles } from 'redux/actions/upload';
import { useDispatch, useSelector } from 'react-redux';
import { formatFileToLocalDocument } from 'helpers/documentsHelpers';
import { StateModel as UploadStateModel } from 'redux/reducers/upload';
import {
  IDocumentAssociationInfo,
  IDocumentFile,
} from 'typings/documents/documents';

// components
import DocumentItem from './DocumentItem';
import HideIfDisabledForm from '../../HideIfDisabledForm';
import UploadDocumentButton, {
  UploadDocumentButtonProps,
} from './UploadDocumentButton';

export interface DocumentFieldValueModel {
  id: string;
  name: string;
  fileId: string;
  file: IDocumentFile | null;
}

export interface LocalDocumentValueModel {
  label: string;
  localDocumentId: string | null;
  isUploaded: boolean;

  // Data base ids
  file: IDocumentFile | null;
  fileId: string | null;
  documentId: string | null;
}

interface IProps extends UploadDocumentButtonProps {
  name: string;
  value?: DocumentFieldValueModel[];
  onChange: (value: DocumentFieldValueModel[]) => void;
  isMultiple?: boolean;
  tags?: string[];
  disabled?: boolean;
  association?: IDocumentAssociationInfo;
  isColumnReverse?: boolean;
}

const DocumentsField = ({
  name,
  value,
  tags = [],
  association,
  onChange,
  isMultiple = true,
  disabled,
  isColumnReverse = false,
  onUploadButtonClick,
}: IProps) => {
  const dispatch = useDispatch();
  const uploadState = useSelector<StateModel, UploadStateModel>(
    (state) => state.upload,
  );
  const [isUploadActive, setIsUploadActive] = useState(false);
  const [localFieldValue, setLocalFieldValue] = useState<
    LocalDocumentValueModel[]
  >([]);

  // Set initial local value
  useEffect(() => {
    if (!localFieldValue.length && Array.isArray(value) && value?.length) {
      const formattedValue: LocalDocumentValueModel[] = value.map((e) => ({
        localDocumentId: null,
        label: e.name,
        isUploaded: true,
        documentId: e.id,
        fileId: e.fileId,
        file: e.file as any,
      }));

      setLocalFieldValue(formattedValue);
    } else if ((!value || !value.length) && localFieldValue.length) {
      setLocalFieldValue([]);
    }
  }, [value]);

  useEffect(() => {
    const { isFinished, files } = uploadState;

    if (isFinished && isUploadActive) {
      const newFieldValue = localFieldValue.map((e) => {
        if (e.isUploaded) {
          return e;
        } else {
          if (e.localDocumentId && files[e.localDocumentId]) {
            const { dataBaseEntry } = files[e.localDocumentId];

            return {
              ...e,
              isUploaded: true,
              documentId: dataBaseEntry?._id as string,
              fileId: dataBaseEntry?.files[0].id as string,
              file: dataBaseEntry?.files.length ? dataBaseEntry.files[0] : null,
            };
          } else {
            // Impossible case
            return e;
          }
        }
      });

      setLocalFieldValue(newFieldValue);
      setFormFieldValue(newFieldValue);
      setIsUploadActive(false);
    }
  }, [uploadState.isFinished, isUploadActive]);

  const setFormFieldValue = (newValue: LocalDocumentValueModel[]) => {
    const formattedValue: DocumentFieldValueModel[] = newValue.map((e) => ({
      id: e.documentId as string,
      name: e.label,
      fileId: e.fileId as string,
      file: e.file,
    }));

    onChange(formattedValue);
  };

  const handleFilesSelect = (files: File[]) => {
    if (!files.length) {
      return;
    }

    const formattedDocuments = files.map((file) =>
      formatFileToLocalDocument(file, tags),
    );
    const formattedLocalValue: LocalDocumentValueModel[] =
      formattedDocuments.map((e) => ({
        label: e.name,
        localDocumentId: e.id,
        isUploaded: false,
        documentId: null,
        fileId: null,
        file: null,
      }));

    dispatch(uploadFiles([...formattedDocuments], association, name));
    setLocalFieldValue([...localFieldValue, ...formattedLocalValue]);
    setIsUploadActive(true);
  };

  const deleteDocumentByIndex = (documentIndex: number) => {
    const documentsCopy = [...localFieldValue];
    documentsCopy.splice(documentIndex, 1);

    setLocalFieldValue(documentsCopy);
    setFormFieldValue(documentsCopy);
  };

  const renderDocuments = (documents: LocalDocumentValueModel[]) => {
    if (!documents.length) {
      return null;
    }

    return documents.map((doc, i) => (
      <DocumentItem
        key={`${doc.localDocumentId}-${doc.documentId}`}
        document={doc}
        onDelete={!disabled ? () => deleteDocumentByIndex(i) : undefined}
      />
    ));
  };

  return (
    <Container isColumnReverse={isColumnReverse}>
      <HideIfDisabledForm>
        {!disabled && (
          <UploadDocumentButtonWrapper>
            <UploadDocumentButton
              multiple={isMultiple}
              disabled={!isMultiple && localFieldValue.length === 1}
              onFilesSelect={handleFilesSelect}
              onUploadButtonClick={onUploadButtonClick}
            />
          </UploadDocumentButtonWrapper>
        )}
      </HideIfDisabledForm>

      {renderDocuments(localFieldValue)}
    </Container>
  );
};

const UploadDocumentButtonWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.marginSm};
`;

const Container = styled.div<{ isColumnReverse: boolean }>`
  display: flex;
  flex-direction: ${({ isColumnReverse }) =>
    isColumnReverse ? 'column-reverse' : 'column'};
`;

export default DocumentsField;
