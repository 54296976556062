import React, { useMemo } from 'react';

// helpers
import { AutocompleteProps } from '@ui';
import { getRelationshipName } from 'helpers/crmHelpers';
import { RelationshipTemplate } from 'typings/application/relationship-template';
import { relationshipTemplatesAPI } from 'api/crm/relationshipTemplatesAPI';
import {
  OnboardingEntryTypes,
  RelationshipTrackTypes,
} from 'enums/onboarding/crm';

// components
import { FormAutocomplete } from '@ui';

export interface RelationshipTemplatesAutocompleteProps
  extends AutocompleteProps<RelationshipTemplate> {
  childId: string;
  parentType: OnboardingEntryTypes;
  relationshipScopeId: string;

  parentId?: string;
  trackType?: RelationshipTrackTypes;
}

const RelationshipTemplatesAutocomplete = ({
  childId,
  parentId,
  trackType,
  parentType,
  excludeElementIds,
  relationshipScopeId,
  ...rest
}: RelationshipTemplatesAutocompleteProps) => {
  const formattedExcludeIds = useMemo(() => {
    if (excludeElementIds && excludeElementIds.length) {
      if (rest.value) {
        return excludeElementIds.filter((e) => e !== rest.value);
      } else {
        return excludeElementIds;
      }
    }
  }, [excludeElementIds, rest.value]);

  const fetchOptions = async (
    searchQuery: string,
    page: number,
    limit: number,
  ) => {
    const { data, total } =
      await relationshipTemplatesAPI.fetchAvailableRelationshipTemplatesForRelationship(
        {
          page,
          limit,
          parentId,
          parentType,
          childId,
          relationshipScopeId,
          parentNameSearch: searchQuery,
        },
      );

    return {
      total,
      options: data.map((e) => ({
        id: e._id,
        model: e,
        label: getRelationshipName(
          { parentName: e.parentName, childName: e.childName },
          trackType || RelationshipTrackTypes.Parent,
        ),
      })),
    };
  };

  return (
    <FormAutocomplete
      {...rest}
      fetchData={fetchOptions}
      excludeElementIds={formattedExcludeIds}
    />
  );
};

export default RelationshipTemplatesAutocomplete;
