import React, { useEffect, useMemo, useRef, useState } from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';
import { signOut } from 'redux/actions/auth';
import { StateModel } from 'redux/reducers';
import { ActionKeys } from 'components/Forms/TemplateForms/Onboarding/Components/SubmitButtons';
import { ClientGroupStatuses } from 'enums/onboarding/crm';
import { useDispatch, useSelector } from 'react-redux';
import { getNumberOfInitialOnboardingStep } from 'helpers/crmHelpers';
import { StateModel as ApplicationsStateModel } from 'redux/reducers/applications';
import {
  IInformationFilled,
  OnboardingStatusModel,
} from 'typings/onboarding/onboarding';

// components
import LoadingWrapper from 'components/WrapperComponents/LoadingWrapper';
import SourceOfWealthStep from './Steps/SourceOfWealth';
import IdentificationStep from './Steps/Identification';
import AccountInformationStep from './Steps/AccountInformation';
import ApplicantInformationStep from './Steps/ApplicantInformation';
import ApplicationDocumentation from './Steps/ApplicationDocumentation';
import ApplicationInReviewStatus from '../Components/ApplicationInReviewStatus';
import ApplicationNotSubmittedStatus from '../Components/ApplicationNotSubmittedStatus';
import StepsBar, { StepItemModel } from '../Components/StepsBar';
import { Col, Row, Modal } from '@ui';

type IndividualStepKeys =
  | 'applicant_information'
  | 'identification'
  | 'employment_history'
  | 'account_information'
  | 'application_documentation';

const StepKeys: Array<keyof IInformationFilled> = [
  'generalInformation',
  'identification',
  'employmentHistory',
  'accountInformation',
  'applicationDocumentation',
];

const Individual = () => {
  const { t } = useTranslation(['onboarding', 'common']);
  const dispatch = useDispatch();
  const scrollPointRef = useRef<any>(null);
  const currentContactId = useSelector<StateModel, string>(
    (state) => state.auth.contactData?._id as string,
  );
  const { activeApplication, activeApplicationLoading } = useSelector<
    StateModel,
    ApplicationsStateModel
  >((state) => state.applications);
  const isViewOnly =
    activeApplication?.clientGroup?.status !== ClientGroupStatuses.Onboarding;

  const onboardingData = useMemo<{
    applicationId: string;
    contactId: string;
    clientGroupId: string;
  }>(() => {
    return {
      contactId: currentContactId,
      applicationId: activeApplication?._id || '',
      clientGroupId: activeApplication?.clientGroupId || '',
    } as OnboardingStatusModel;
  }, [activeApplication, currentContactId]);

  const [currentStep, setCurrentStep] = useState<number>(
    getNumberOfInitialOnboardingStep(
      activeApplication?.onboardingProcess
        ?.informationFilled as IInformationFilled,
      StepKeys,
    ),
  );

  useEffect(() => {
    scrollPointRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [currentStep]);

  const steps = useMemo<StepItemModel<IndividualStepKeys>[]>(() => {
    const result: StepItemModel<IndividualStepKeys>[] = [
      {
        key: 'applicant_information',
        title: t('applicant_information.applicant.title'),
      },
      { key: 'identification', title: t('identification.title') },
      { key: 'employment_history', title: t('source_of_wealth.title') },
      { key: 'account_information', title: t('account_information.title') },
      {
        key: 'application_documentation',
        title: t('application_documentation.title'),
      },
    ];

    return result;
  }, [t]);

  const handleStepAction = async (action: ActionKeys | null) => {
    switch (action) {
      case 'save-back':
        setCurrentStep(currentStep - 1);
        break;

      case 'save-exit':
        dispatch(signOut());
        break;

      case 'submit':
        {
          if (currentStep !== steps.length - 1) {
            setCurrentStep(currentStep + 1);
          } else {
            Modal.info({
              icon: '',
              closable: true,
              content: t('application_documentation.success_submit', {
                ns: 'onboarding',
              }),
              okText: t('ok', { ns: 'common' }),
              width: 520,
            });
          }
        }
        break;
    }
  };

  const renderStep = (step: number) => {
    switch (step) {
      case 0:
        return (
          <ApplicantInformationStep
            onboardingData={onboardingData}
            onAction={handleStepAction}
            isViewOnly={isViewOnly}
          />
        );

      case 1:
        return (
          <IdentificationStep
            onboardingData={onboardingData}
            onAction={handleStepAction}
            isViewOnly={isViewOnly}
          />
        );

      case 2:
        return (
          <SourceOfWealthStep
            onboardingData={onboardingData}
            onAction={handleStepAction}
            isViewOnly={isViewOnly}
          />
        );

      case 3:
        return (
          <AccountInformationStep
            onboardingData={onboardingData}
            onAction={handleStepAction}
            isViewOnly={isViewOnly}
          />
        );

      case 4:
        return (
          <ApplicationDocumentation
            onboardingData={onboardingData}
            onAction={handleStepAction}
            isViewOnly={isViewOnly}
          />
        );

      default:
        return null;
    }
  };

  return (
    <>
      <div ref={scrollPointRef} />
      <StepsBar
        steps={steps}
        currentStep={currentStep}
        onStepSelect={isViewOnly ? setCurrentStep : undefined}
      />
      <Row justify="center">
        <Col xl={20} lg={20} md={22} xs={22}>
          {isViewOnly ? (
            <ApplicationInReviewStatus />
          ) : (
            <ApplicationNotSubmittedStatus />
          )}
          <LoadingWrapper loading={activeApplicationLoading}>
            {renderStep(currentStep)}
          </LoadingWrapper>
        </Col>
      </Row>
    </>
  );
};

export default Individual;
