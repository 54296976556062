import React, { useMemo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { useNavigate } from 'react-router-dom';
import { RoutePaths } from 'routes/routes';
import { StateModel } from 'redux/reducers';
import { FormikHelpers } from 'formik';
import { decodeJWTToken } from 'helpers/commonHelpers';
import { setTemporaryToken } from 'redux/actions/auth';
import { useDispatch, useSelector } from 'react-redux';
import { TwoFactorVerificationTypes } from 'enums/profile/auth';
import { StateModel as AuthStateModel } from 'redux/reducers/auth';
import { AccountRecoveryRequestSchema } from 'validations/profile/auth';
import { accountRecoveryRequestAdapter } from 'apiAdapters/accountRecoveryRequest/accountRecoveryRequestAdapter';
import { AccountRecoveryStatus, userAPI } from 'api/profile/userAPI';

// constants
import {
  ACCOUNT_RECOVERY_STATUS,
  UploadDocumentMethods,
  VerificationDocumentPhotoTypes,
  VerificationDocumentTypes,
} from 'enums/profile/accountRecovery';

// components
import { Text, Form, Divider, Link, Col, Row, Message } from '@ui';
import HeaderSteps from './HeaderSteps';
import SubmitButton from 'components/Buttons/SubmitButton';
import AuthContainer from 'components/Additional/AuthContainer';

import IntroStep from './IntroStep';
import SubmitFormStep from './SubmitFormStep';
import InformationStep from './InformationStep';
import IDVerificationStep from './IDVerificationStep';

export interface FormValuesModel {
  currentStep: number;
  email: string;
  phone: string;
  types: TwoFactorVerificationTypes[];
  idVerificationType: VerificationDocumentTypes | null;
  uploadDocumentMethod: UploadDocumentMethods | null;
  snapshots: Record<keyof typeof VerificationDocumentPhotoTypes, string> | null;

  // This variable is need for upload verification document method (upload only documents without selfie)
  // After all all documents has upload (depends on document requirements, whether need 1 side or 2),
  // we should navigate user to webcam page where user should make a selfie
  isUploadedIdDocumentPhotos: boolean;
}

const AccountRecovery = () => {
  const { t } = useTranslation(['auth', 'common']);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { temporaryToken, twoFactorVerificationData } = useSelector<
    StateModel,
    AuthStateModel
  >((state) => state.auth);
  const navigateToSignin = () => {
    navigate(RoutePaths.Auth_Root);
  };

  const handleFormSubmit = async (
    values: FormValuesModel,
    helpers: FormikHelpers<FormValuesModel>,
  ) => {
    try {
      if (values.currentStep < 2) {
        // After info step we should make a request and check the status for selected email
        // The status can be: inactive or verifying
        if (values.currentStep === 1) {
          if (twoFactorVerificationData) {
            const { status } = await userAPI.fetchAccountRecoveryStatus(
              temporaryToken as string,
            );
            switch (status) {
              case AccountRecoveryStatus.WrongData: {
                Message.error(t('unexpected_error', { ns: 'common' }));
                return;
              }
              case AccountRecoveryStatus.Veryfing: {
                Message.warning(
                  t(
                    'account_recovery.account_recovery_request_already_created',
                  ),
                );
                return;
              }
              case AccountRecoveryStatus.Ok: {
                handleSubmitBtnClick(values.currentStep, helpers);
              }
            }
          }
        } else {
          if (twoFactorVerificationData) {
            const decodedData = decodeJWTToken(temporaryToken as string);
            if (decodedData) {
              helpers.setFieldValue('email', decodedData?.metadata.email);
              handleSubmitBtnClick(values.currentStep, helpers);
            }
          }
        }
      } else {
        if (twoFactorVerificationData) {
          const generatedAccountRecoveryData =
            accountRecoveryRequestAdapter.requestAccountRecovery({
              ...values,
            });
          await userAPI.requestAccountRecovery(
            generatedAccountRecoveryData,
            temporaryToken as string,
          );
          helpers.resetForm({ values: { ...values, currentStep: 3 } });
          dispatch(setTemporaryToken(null));
        }
      }
    } catch (error: any) {
      if (error.response?.data?.code === ACCOUNT_RECOVERY_STATUS.UserNotFound) {
        helpers.setFieldError('email', t('response_error.invalid_email'));
      } else {
        throw error;
      }
    }
  };

  const renderFormContent = (currentStep: number) => {
    let result = null;

    switch (currentStep) {
      case 0:
        result = <IntroStep />;
        break;

      case 1:
        result = <InformationStep />;
        break;

      case 2:
        result = <IDVerificationStep />;
        break;

      case 3:
        result = <SubmitFormStep />;
        break;

      default:
        result = null;
    }

    return result;
  };

  const handleSubmitBtnClick = (
    currentStep: number,
    { setFieldValue }: FormikHelpers<FormValuesModel>,
  ) => {
    setFieldValue('currentStep', currentStep + 1);
  };

  const initialFormValues: FormValuesModel | undefined = useMemo(() => {
    if (twoFactorVerificationData) {
      return {
        currentStep: 0,
        email: '',
        phone: '',
        types: twoFactorVerificationData.options
          .filter((x) => x.enabled)
          .map((x) => x.code),

        // step 2 - id verification
        idVerificationType: null,
        uploadDocumentMethod: UploadDocumentMethods.Upload,
        snapshots: null,
        isUploadedIdDocumentPhotos: false,
      };
    }
  }, [twoFactorVerificationData]);

  const submitButtonGridSizes = { xl: 5, lg: 5, md: 5, sm: 8, xs: 24 };

  return (
    <>
      {!!initialFormValues && (
        <Form
          validationSchema={AccountRecoveryRequestSchema}
          onSubmit={handleFormSubmit}
          initialValues={initialFormValues}
          confirmExitWithoutSaving
          renderForm={(form) => (
            <StyledAuthContainer
              footerText={
                [0, 1, 3].includes(form.values.currentStep) ? (
                  <Link onClick={navigateToSignin}>
                    {t('account_recovery.go_back_to_signin')}
                  </Link>
                ) : null
              }
            >
              <div>
                <Title variant="h5" gutterBottom weight="semi-bold">
                  {t('account_recovery.title')}
                </Title>
                <HeaderSteps
                  activeStep={
                    form.values.currentStep === 0
                      ? -1
                      : form.values.currentStep - 1
                  }
                />
                <Divider />
              </div>

              <ContentWrapper>
                <FormContent>
                  {renderFormContent(form.values.currentStep)}
                </FormContent>

                {form.values.currentStep <= 1 && (
                  <div>
                    <Row justify="center">
                      <Col {...submitButtonGridSizes}>
                        <StyledButton type="primary">
                          {form.values.currentStep === 0
                            ? t('start', { ns: 'common' })
                            : t('next', { ns: 'common' })}
                        </StyledButton>
                      </Col>
                    </Row>
                  </div>
                )}
              </ContentWrapper>
            </StyledAuthContainer>
          )}
        />
      )}
    </>
  );
};

const FormContent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const ContentWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const StyledAuthContainer = styled(AuthContainer)`
  width: 900px;
  min-height: 620px;
`;

const Title = styled(Text)`
  display: block;
  text-align: center;
`;

const StyledButton = styled(SubmitButton)`
  width: 100%;
`;

export default AccountRecovery;
