import { ApprovalStatuses } from 'enums/approvalManagement/approvalManagement';
import {
  IApprovalGroupUser,
  IApprovalMatrixRecord,
} from 'typings/accounting/transaction';

export function userHasPendingApproval(
  userId: string,
  approvalsArray: IApprovalMatrixRecord[][],
): boolean {
  let result: IApprovalGroupUser | null = null;

  for (const approvalItem of approvalsArray) {
    for (const approvalGroup of approvalItem) {
      const foundMember = approvalGroup.members.find(
        (member) => member.userId === userId,
      );

      if (foundMember) {
        result = foundMember;
        break;
      }
    }
    if (result) break;
  }

  return result?.approvalAction === ApprovalStatuses.Pending;
}
